import React, { useState, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import Spinner from '@reusableComponents/Spinner/spinner';

const LoginRequiredRoute = ({ children, ...rest }) => {
	const location = useLocation();
	const { OrganizationStore, UserStore, ProgramStore } = useStores();
	const [isLoading, setIsLoading] = useState(true);
	useEffect(() => {
		async function fetchData() {
			await UserStore.fetchSession();
			if (UserStore.user) {
				const url = location.pathname.split('/');
				const programIndex = url.findIndex((path) => path.includes('programs'));
				const programId = programIndex ? url[programIndex + 1] : '';
				await OrganizationStore.fetchOrganizations();
				if (UserStore.user.selected_organization) {
					await OrganizationStore.selectOrganization(UserStore.user.selected_organization);
					const orgProgramId = OrganizationStore.programs[0]?.program_id;
					if (!UserStore.canViewOrganizationRoute() && orgProgramId) {
						await ProgramStore.updateProgramId(orgProgramId);
					}
				} else if (UserStore.user.organization_id) {
					await OrganizationStore.selectOrganization(UserStore.user.organization_id);
				} else if (programId) {
					const saveToSession = true;
					await OrganizationStore.fetchOrganizationIdByProgramId(programId, saveToSession);
				}
				UserStore.initializePendo();
			}
			setIsLoading(false);
		}
		fetchData();
	}, []);

	window.addEventListener('focus', UserStore.activateSessionCheck(), false);

	if (isLoading) {
		return <Spinner />;
	} else if (UserStore.errorCode || !UserStore.user) {
		return <Redirect
			to={{
				pathname: '/auth/login',
				search: location.search,
				state: { from: location },
			}}
		/>;
	}

	return (
		<Route
			render={(props) => React.cloneElement(children, props)} // necessary to pass props to direct children
			{...rest}
		/>
	);
};

LoginRequiredRoute.propTypes = {
	children: PropTypes.node,
};

export default observer(LoginRequiredRoute);
