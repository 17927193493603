import React from 'react';

const Quote = (props) => (
	<svg {...props} viewBox="0 0 17 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.768 16.9412C5.2736 16.9412 4.944 16.6024 4.944 16.0941V13.5529H1.648C0.7416 13.5529 0 12.7906 0 11.8588L0 1.69412C0 0.762353 0.7416 0 1.648 0L14.832 0C15.7384 0 16.48 0.762353 16.48 1.69412V11.8588C16.48 12.7906 15.7384 13.5529 14.832 13.5529H9.8056L6.7568 16.6871C6.592 16.8565 6.4272 16.9412 6.18 16.9412H5.768ZM6.592 11.8588V14.4847L9.1464 11.8588H14.832V1.69412H1.648V11.8588H6.592ZM11.7832 3.38824L10.6296 5.92941H12.36V9.31765H9.064V5.76L10.1352 3.38824H11.7832ZM6.8392 3.38824L5.6856 5.92941H7.416V9.31765H4.12V5.76L5.1912 3.38824H6.8392Z" fill="currentColor"/>
	</svg>

);

export default Quote;
