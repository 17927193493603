import React from 'react';

const TalkBubble = (props) => (
	<svg {...props} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.43341 0.0609665C1.31528 0.310762 0.462801 1.13152 0.10595 2.3012L0.0147552 2.59857L0.0028602 6.60323C-0.00506982 9.35099 0.0028602 10.6872 0.0345803 10.8656C0.232831 12.0512 1.02187 13.0107 2.11621 13.3913C2.42152 13.4984 2.46513 13.5024 3.38898 13.5142L4.34851 13.5301L5.13358 14.4738C5.56973 14.9972 5.93848 15.4175 5.9583 15.4135C5.97813 15.4056 6.34291 14.9853 6.77113 14.4738L7.54827 13.542L11.0573 13.5222L14.5663 13.5024L14.8756 13.3953C15.9224 13.0384 16.7193 12.1186 16.9453 11.0044C17.0009 10.7229 17.0048 10.2352 16.9969 6.64288C16.981 2.25362 16.9969 2.54703 16.7669 1.93642C16.5687 1.40907 16.0056 0.731053 15.5259 0.441607C15.2285 0.267147 14.8796 0.128372 14.5544 0.0609665C14.1698 -0.0222988 2.79819 -0.0183334 2.43341 0.0609665ZM14.3205 1.36942C14.6417 1.45269 14.8439 1.56767 15.0937 1.79764C15.3316 2.02761 15.4545 2.21397 15.5853 2.56289L15.6765 2.79682L15.6884 6.60323C15.6964 9.24393 15.6884 10.4889 15.6567 10.6674C15.5378 11.3652 15.058 11.9441 14.4355 12.1344C14.1897 12.2098 14.0549 12.2137 10.5458 12.2137L6.91387 12.2177L6.43807 12.7886C6.17638 13.1058 5.9583 13.3636 5.95434 13.3636C5.95038 13.3636 5.72833 13.1058 5.46268 12.7886L4.98291 12.2137H3.90047C2.933 12.2137 2.79026 12.2058 2.56822 12.1344C2.27085 12.0432 2.14397 11.9639 1.88624 11.7102C1.6642 11.4921 1.54128 11.2978 1.4144 10.9608L1.32321 10.7268L1.31131 6.9125C1.29942 2.75321 1.29942 2.77303 1.52542 2.30913C1.7435 1.86505 2.18362 1.50027 2.65545 1.37338C2.98058 1.28615 13.9835 1.28219 14.3205 1.36942Z" fill="currentColor"/>
		<path d="M3.97183 5.62387C3.66653 5.73489 3.44845 5.90142 3.28192 6.15915C2.82991 6.85302 3.07971 7.74912 3.82513 8.1139C4.03131 8.21699 4.10268 8.22888 4.41592 8.22888C4.8481 8.22888 5.06221 8.13769 5.37148 7.83238C6.00192 7.20194 5.81953 6.09967 5.01067 5.69128C4.71329 5.54457 4.28111 5.51285 3.97183 5.62387Z" fill="currentColor"/>
		<path d="M8.09544 5.60801C7.75842 5.71903 7.46501 5.97279 7.28262 6.31378C7.20332 6.46049 7.19142 6.54375 7.19142 6.9006C7.19142 7.26142 7.20332 7.34072 7.28262 7.48742C7.42536 7.75308 7.67119 7.99891 7.92098 8.11786C8.11527 8.21302 8.1906 8.22888 8.49987 8.22888C8.92017 8.22888 9.12238 8.15355 9.41183 7.88789C9.85987 7.47553 9.97486 6.82527 9.70127 6.2781C9.59025 6.05606 9.28495 5.77058 9.04308 5.66352C8.80915 5.55646 8.33334 5.52871 8.09544 5.60801Z" fill="currentColor"/>
		<path d="M12.1675 5.61597C11.5688 5.83404 11.2238 6.36139 11.2635 6.99975C11.2873 7.35264 11.4023 7.6064 11.6521 7.85223C11.9336 8.13375 12.1556 8.22494 12.5838 8.22494C12.8891 8.22891 12.9724 8.21305 13.1627 8.12582C13.4482 7.99101 13.694 7.74518 13.8289 7.4597C13.9161 7.27334 13.9319 7.18215 13.9319 6.90063C13.9319 6.4962 13.8209 6.21468 13.5553 5.94903C13.2143 5.60804 12.5957 5.46133 12.1675 5.61597Z" fill="currentColor"/>
	</svg>
);

export default TalkBubble;
