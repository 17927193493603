import React from 'react';
import colors from '@app/styles/_variables.scss';

const Star = ({
	bordered = false,
	color,
	...rest
}) => (
	bordered
		? (
			<svg {...rest} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M18.9301 6.45476L13.2627 5.6311L10.7293 0.494938C10.6601 0.354313 10.5462 0.240474 10.4056 0.171278C10.0529 -0.0028296 9.62434 0.14226 9.448 0.494938L6.91452 5.6311L1.24711 6.45476C1.09086 6.47708 0.948005 6.55074 0.83863 6.66235C0.706402 6.79826 0.633538 6.9811 0.636049 7.1707C0.63856 7.3603 0.716241 7.54115 0.852023 7.67351L4.95247 11.6713L3.98372 17.3164C3.961 17.4477 3.97553 17.5827 4.02567 17.7062C4.0758 17.8297 4.15953 17.9367 4.26735 18.015C4.37518 18.0933 4.5028 18.1398 4.63573 18.1493C4.76866 18.1588 4.90158 18.1308 5.01943 18.0686L10.0886 15.4034L15.1578 18.0686C15.2962 18.1423 15.4569 18.1668 15.611 18.14C15.9993 18.0731 16.2605 17.7048 16.1935 17.3164L15.2248 11.6713L19.3252 7.67351C19.4368 7.56414 19.5105 7.42128 19.5328 7.26503C19.5931 6.8744 19.3208 6.5128 18.9301 6.45476ZM13.4993 11.1088L14.3051 15.803L10.0886 13.5887L5.87211 15.8052L6.67792 11.111L3.2672 7.78512L7.98149 7.09985L10.0886 2.82976L12.1958 7.09985L16.9101 7.78512L13.4993 11.1088Z" fill={color || colors.grey6} />
			</svg>
		) : (
			<svg {...rest} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M16.7364 5.89625L11.7774 5.17554L9.56061 0.681404C9.50007 0.558357 9.40046 0.458747 9.27741 0.398201C8.96882 0.245857 8.59382 0.37281 8.43952 0.681404L6.22272 5.17554L1.26374 5.89625C1.12702 5.91578 1.00202 5.98023 0.906317 6.07789C0.790617 6.19681 0.726862 6.35679 0.729059 6.5227C0.731256 6.6886 0.799227 6.84684 0.918036 6.96265L4.50593 10.4607L3.65827 15.4002C3.63839 15.5151 3.65111 15.6332 3.69497 15.7413C3.73884 15.8493 3.8121 15.9429 3.90645 16.0114C4.0008 16.08 4.11246 16.1207 4.22878 16.129C4.34509 16.1373 4.4614 16.1128 4.56452 16.0584L9.00007 13.7263L13.4356 16.0584C13.5567 16.1228 13.6973 16.1443 13.8321 16.1209C14.1719 16.0623 14.4005 15.74 14.3419 15.4002L13.4942 10.4607L17.0821 6.96265C17.1798 6.86695 17.2442 6.74195 17.2637 6.60523C17.3165 6.26343 17.0782 5.94703 16.7364 5.89625Z" fill={color || colors.yellow4} />
			</svg>
		)
);

export default Star;
