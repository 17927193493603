import colors from '@app/styles/_variables.scss';
import { rootStore } from '@stores';
import translator from '@app/i18n';

// Find the width of text by appending it to the DOM and removing it
const getTextWidth = (text, fontSize = 10) => {
	const textElement = document.createElement('span');
	textElement.style.fontSize = fontSize + 'px';
	textElement.style.height = 'auto';
	textElement.style.width = 'auto';
	textElement.style.position = 'absolute';
	textElement.style.whiteSpace = 'no-wrap';
	textElement.innerHTML = text;

	document.body.appendChild(textElement);
	const width = Math.ceil(textElement.clientWidth);
	document.body.removeChild(textElement);

	return width;
};

export const winLossColors = {
	win: colors.green4,
	loss: colors.red4,
	green4: colors.green4,
	red3: colors.red3,
	default: colors.grey12,
	disabled: colors.grey4,
	projectedWin: colors.green4,
	projectedLoss: colors.red3,
	positiveTwoRating: colors.green4,
	positiveOneRating: colors.green4,
	zeroRating: colors.grey4,
	negativeOneRating: colors.red3,
	negativeTwoRating: colors.red4,
	lostToCompetitor: colors.red4,
	lostToNoDecision: colors.red3,
	lostUnknown: colors.red2,
	grey6: colors.grey6,
	grey8: colors.grey8,
	unknown: colors.red1,
	newBusiness: colors.blue4,
	existingBusiness: colors.blue7,
	unmapped: colors.grey10,
};

const graphColors = ['#55B445', '#27BA9F', '#2AA0CA', '#4972C1', '#9252B9', '#BE4E9F'];

const camelCasing = {
	'lost to competitor': 'lostToCompetitor',
	'lost to no decision': 'lostToNoDecision',
	'unknown': 'unknown',
	'rep survey': 'repSurvey',
	'rep interview': 'repInterview',
	'buyer survey': 'buyerSurvey',
	'buyer interview': 'buyerInterview',
};

const constants = {
	'repSurvey': 'rep survey',
	'repInterview': 'rep interview',
	'buyerSurvey': 'buyer survey',
	'buyerInterview': 'buyer interview',
	'lostToCompetitor': 'lost to competitor',
	'lostToNoDecision': 'lost to no decision',
	'lostUnknown': 'lost - unknown',
	'jobFail': 'Job Failure.',
};

// removes meta-data field percent sign to be able to be formatted
const removeSign = (value) => {
	if (!value || typeof value !== 'string') return value;
	else if (value.includes('%')) return value.replace('%', '');
	else if (value.includes('$')) return value.replace('$', '');
	return value;
};

// Can be used to help generate test IDs or anything else
const hyphenatePhrase = (value) => {
	return value.toLowerCase().replaceAll(' ', '-');
};

// Checks whether or not any participant info should be displayed on a deal
const shouldDisplayParticipantInfo = (participant, participantDealFields) => {
	for (const field in participant) {
		switch (field) {
			case 'primary_email':
				if (participant[field] && participantDealFields.email?.is_displayed === true) {
					return true;
				}
				break;
			case 'phone_number':
				if (participant[field] && participantDealFields.phone?.is_displayed === true) {
					return true;
				}
				break;
			case 'first_name':
			case 'last_name':
			case 'title':
				if (participant[field] && participantDealFields[field]?.is_displayed === true) {
					return true;
				}
				break;
			case 'full_name':
			case 'participant_id':
			default:
				break;
		}
	}
	return false;
};

const interval = {
	DAILY: translator.t('program.configuration.automations.daily'),
	WEEKLY: translator.t('program.configuration.automations.weekly'),
	BIWEEKLY: translator.t('program.configuration.automations.biWeekly'),
};
const dayName = {
	SU: translator.t('program.configuration.automations.sunday'),
	MO: translator.t('program.configuration.automations.monday'),
	TU: translator.t('program.configuration.automations.tuesday'),
	WE: translator.t('program.configuration.automations.wednesday'),
	TH: translator.t('program.configuration.automations.thursday'),
	FR: translator.t('program.configuration.automations.friday'),
	SA: translator.t('program.configuration.automations.saturday'),
};

const hoursOfDay = {
	1: '1:00',
	2: '2:00',
	3: '3:00',
	4: '4:00',
	5: '5:00',
	6: '6:00',
	7: '7:00',
	8: '8:00',
	9: '9:00',
	10: '10:00',
	11: '11:00',
	12: '12:00',
};

const ratingsMap = {
	[-2]: '--',
	[-1]: '-',
	1: '+',
	2: '++',
};

const isJsonString = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

const channelToSVGMap = {
	'buyer interview': 'talk bubble',
	'buyer survey': 'check bubble',
	'rep interview': 'dots partial circle',
	'rep survey': 'check partial circle',
};

export const displayFullName = function displayFullName(firstName, lastName) {
	return `${firstName || ''} ${lastName || ''}`.trim();
};

/**
 * Pulls the correct translation of the competitor
 * @param {uuid|string} competitorId
 * @param {Map<String, String>} _competitorsMap ID to competitor name map.
 * 		This is to override the default mapping from the program store.
 */
export function translateCompetitor(competitorId, _competitorsMap = null) {
	const competitorsMap = _competitorsMap || rootStore?.ProgramStore?.competitorsMap;
	return (
		competitorsMap?.[competitorId]
		|| translator.t([
			`program.global.competitorTypes.${competitorId}`,
			'program.global.competitorTypes.removed',
		])
	);
}

/**
 * Converts a color string to an RGB string. Returns null if hex code
 * is formatted incorrectly.
 * @param {string} color - can be in any of the following forms:
 * ['r,g,b', '#FFF', 'FFF', '#FFFFFF', 'FFFFFF', colorName (from _variables.scss)]
 */
export function colorToRgb(color) {
	const rgbRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\s*,\s*(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\s*,\s*(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
	if (rgbRegex.test(color)) return color;

	const hex = colors[color] || color;
	let modified_hex = hex?.replace(/[#\s]/g, '');

	if (!/^([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(modified_hex)) {
		return null;
	}

	if (modified_hex.length === 3) {
		modified_hex = modified_hex.split('').map(char => char + char).join('');
	}
	const r = parseInt(modified_hex.substring(0, 2), 16);
	const g = parseInt(modified_hex.substring(2, 4), 16);
	const b = parseInt(modified_hex.substring(4, 6), 16);

	return `${r},${g},${b}`;
}

/**
 * Capitalizes the first letter of each word in input string
 * and makes sure the rest of the letters are lower case
 * @param {string} input
 */
export function formatInTitleCase(input) {
	const wordsArray = input.split(' ');

	const capitalizedArray = wordsArray.map(word => {
		if (word.trim() === '') {
			return '';
		}
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});

	const capitalizedString = capitalizedArray.join(' ');
	return capitalizedString;
}

export function mixColors(colorsArray) {
	const rgbColors = colorsArray.reduce(
		(validColors, color) => {
			// filter out non-string values
			if (typeof color === 'string') {
				const convertedColor = colorToRgb(color);

				// filter out strings that failed the color conversion (should return null if it fails)
				if (typeof convertedColor === 'string') {
					const parsedColor = convertedColor
						.split(',')
						.map(num => parseInt(num, 10));

					// Validate that all parsed values are within the 0-255 range
					if (parsedColor.length === 3 && parsedColor.every(num => num >= 0 && num <= 255)) {
						validColors.push(parsedColor);
					}
				}
			}
			return validColors;
		},
		[],
	);

	// if no remaining colors, return null
	if (rgbColors.length === 0) return null;

	// Sum up all the colors
	const totalColor = rgbColors.reduce(
		(accum, rgb) => {
			accum.red += rgb[0];
			accum.green += rgb[1];
			accum.blue += rgb[2];
			return accum;
		},
		{ red: 0, green: 0, blue: 0 },
	);

	// Calculate the average color
	const averageColor = {
		red: Math.round(totalColor.red / rgbColors.length),
		green: Math.round(totalColor.green / rgbColors.length),
		blue: Math.round(totalColor.blue / rgbColors.length),
	};

	// Return the average color as a string
	return `${averageColor.red}, ${averageColor.green}, ${averageColor.blue}`;
}

// allows you to cache images to get immediate rendering where needed
export const cacheImages = async (srcArray) => {
	const promises = await srcArray.map((src) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = src;
			img.onload = resolve();
			img.onerror = reject();
		});
	});
	await Promise.all(promises);
};

/**
 * Clips sentence at the word limit and appends '...' to it.
 * @param {string} sentence Sentence/phrase/quote to clip
 * @param {number} wordLimit Number of words to allow in sentence
 * @returns Clipped sentence with '...' appended or original sentence
 * if it's shorter than word limit.
 */
export function clipSentence(sentence, wordLimit) {
	const words = sentence.split(' ');
	if (words.length > wordLimit) {
		return words.slice(0, wordLimit).join(' ') + '...';
	}
	return sentence;
}

/**
 * Shuffle the order of your array if you wish to. Implements the
 * Fisher-Yates (Knuth) algorithm for shuffling.
 * @param {Array} arr The array you wish to shuffle.
 * @returns The shuffled array.
 */
export function shuffleArray(arr) {
	for (let i = arr.length - 1; i > 0; i--) {
		// Generate a random index between 0 and i
		const randomIndex = Math.floor(Math.random() * (i + 1));

		// Swap elements at i and randomIndex
		[arr[i], arr[randomIndex]] = [arr[randomIndex], arr[i]];
	}
	return arr;
}

export default {
	cacheImages,
	getTextWidth,
	winLossColors,
	camelCasing,
	constants,
	isJsonString,
	channelToSVGMap,
	removeSign,
	hyphenatePhrase,
	shouldDisplayParticipantInfo,
	interval,
	dayName,
	hoursOfDay,
	ratingsMap,
	graphColors,
};
