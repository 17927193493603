import { types } from 'mobx-state-tree';

const { model, string, integer, union, optional, maybeNull } = types;

const CompetitorModel = model('CompetitorModel', {
	competitor_id: maybeNull(string),
	organization_entity: optional(model({
		domain: maybeNull(string),
		headcount: maybeNull(integer),
		industry: maybeNull(string),
		name: maybeNull(string),
		organization_entity_id: maybeNull(string),
		organization_id: maybeNull(string),
		region: maybeNull(string),
		revenue: maybeNull(union(string, integer)),
		segment: maybeNull(string),
	}), {}),
});

export default CompetitorModel;
