import React from 'react';

const Carat = (props) => (
	<svg viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg"  {...props}>
		<rect x="4.58578" y="11.2426" width="8" height="1" transform="rotate(-45 4.58578 10.2426)" fill="currentColor"/>
		<rect x="6" y="11.6569" width="8" height="1" transform="rotate(-135 6 11.6569)" fill="currentColor"/>
	</svg>
);

export default Carat;
