// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bbv77n11vykzqhtrfilz{align-items:center;background-color:#f4f5f6;display:flex;height:100%;justify-content:center}.Bbv77n11vykzqhtrfilz .qCfxbwo2hIyvpEJlpaFa{margin-top:-100px;max-width:520px;border:1px solid #dde6e9;border-radius:8px;padding:32px;box-shadow:0px 6px 16px 0px rgba(0,0,0,.0784313725)}.Bbv77n11vykzqhtrfilz .qCfxbwo2hIyvpEJlpaFa .relTrY3hMBjttqPlCpS6{font-weight:600}.Bbv77n11vykzqhtrfilz .qCfxbwo2hIyvpEJlpaFa .CJ7hEJabd6EFjFL_KMKk{margin:8px 0 16px 0;font-size:16px;color:#565f66}.Bbv77n11vykzqhtrfilz .qCfxbwo2hIyvpEJlpaFa .VLSbsJMuFZfW_QUBpuWH{color:#14a6f8;cursor:pointer;font-size:14px}`, "",{"version":3,"sources":["webpack://./frontend/js/errorHandling/errorBoundary.scss","webpack://./node_modules/@clozd/clozd-ui/styles/_variables.scss"],"names":[],"mappings":"AAEA,sBACC,kBAAA,CACA,wBCEQ,CDDR,YAAA,CACA,WAAA,CACA,sBAAA,CAEA,4CACC,iBAAA,CACA,eAAA,CACA,wBAAA,CACA,iBAAA,CACA,YAAA,CACA,mDAAA,CAGA,kEACC,eAAA,CAGD,kEACC,mBAAA,CACA,cAAA,CACA,aCXO,CDcR,kEACC,aCPM,CDQN,cAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Bbv77n11vykzqhtrfilz`,
	"message-container": `qCfxbwo2hIyvpEJlpaFa`,
	"oops": `relTrY3hMBjttqPlCpS6`,
	"message": `CJ7hEJabd6EFjFL_KMKk`,
	"go-back": `VLSbsJMuFZfW_QUBpuWH`
};
export default ___CSS_LOADER_EXPORT___;
