import React from 'react';

const HamburgerMenu = (props) => (
	<svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect y="2" width="20" height="2" fill="currentColor"/>
		<rect y="9" width="20" height="2" fill="currentColor"/>
		<rect y="16" width="20" height="2" fill="currentColor"/>
	</svg>
);

export default HamburgerMenu;
