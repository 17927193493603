import React from 'react';

const Folder = (props) => (
	<svg {...props} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.09434 4.5L1.50943 10.5V3H14.3396C14.3396 2.60218 14.1806 2.22064 13.8975 1.93934C13.6144 1.65804 13.2305 1.5 12.8302 1.5H7.54717L6.03774 0H1.50943C1.10911 0 0.725177 0.158035 0.442103 0.43934C0.159029 0.720644 0 1.10218 0 1.5V10.5C0 10.8978 0.159029 11.2794 0.442103 11.5607C0.725177 11.842 1.10911 12 1.50943 12H12.8302C13.5094 12 14.1132 11.55 14.2641 10.875L16 4.5H3.09434ZM12.8302 10.5H3.01887L4.22641 6H14.0377L12.8302 10.5Z" fill={props.color || 'currentColor'}/>

	</svg>

);

export default Folder;
