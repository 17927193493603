import React from 'react';
import PropTypes from 'prop-types';

import ArrowRight from './ArrowRight';
import BackwardsFive from './BackwardsFive';
import Bell from './Bell';
import Bookmark from './Bookmark';
import BoxCheck from './BoxCheck';
import Calendar from './Calendar';
import Carat from './Carat';
import Chart from './Chart';
import ChartAlternate from './ChartAlternate';
import ChatBubbles from './ChatBubbles';
import Check from './Check';
import CheckBubble from './CheckBubble';
import Checkmark from './Checkmark';
import CheckPartialCircle from './CheckPartialCircle';
import CircleClose from './CircleClose';
import CircleInfo from './CircleInfo';
import CircleInfoFilled from './CircleInfoFilled';
import CirclePlus from './CirclePlus';
import CircleTrash from './CircleTrash';
import Clipboard from './Clipboard';
import CloudUpload from './CloudUpload';
import Close from './Close';
import ClozdCircle from './ClozdCircle';
import Deal from './Deal';
import DeconstructedLogoLeft from './DeconstructedLogoLeft';
import DeconstructedLogoRight from './DeconstructedLogoRight';
import Dollar from './Dollar';
import DotsBubble from './DotsBubble';
import DotsPartialCircle from './DotsPartialCircle';
import Download from './Download';
import Drag from './Drag';
import ErrorSVG from './Error';
import ExclamationCircle from './ExclamationCircle';
import Expand from './Expand';
import Export from './Export';
import EyeInvisible from './EyeInvisible';
import EyeVisible from './EyeVisible';
import File from './File';
import FileWithLines from './FileWithLines';
import Filter from './Filter';
import Flag from './Flag';
import Folder from './Folder';
import ForwardFiveS from './ForwardFiveS';
import Funnel from './Funnel';
import GDD from './GDD';
import Gear from './Gear';
import GoogleIcon from './GoogleIcon';
import HamburgerMenu from './HamburgerMenu';
import Hash from './Hash';
import HorizontalBarChart from './HorizontalBarChart';
import House from './House';
import Info from './Info';
import Link from './Link';
import List from './List';
import Lock from './Lock';
import Logo from './Logo';
import MagnifyingGlass from './MagnifyingGlass';
import Merge from './Merge';
import Pencil from './Pencil';
import PencilUnderline from './PencilUnderline';
import People from './People';
import Plus from './Plus';
import Podium from './Podium';
import Program from './Program';
import Programs from './Programs';
import Quote from './Quote';
import RefreshArrows from './RefreshArrows';
import ReportsSplash from './ReportsSplash';
import Scatterplot from './Scatterplot';
import Scissor from './Scissor';
import Share from './Share';
import ShareAlternate from './ShareAlternate';
import SideBySide from './SideBySide';
import SmallPlus from './SmallPlus';
import SpeechBubble from './SpeechBubble';
import Squares from './Squares';
import Star from './Star';
import Summary from './Summary';
import Summaries from './Summaries';
import SwitchArrows from './SwitchArrows';
import Sync from './Sync';
import Table from './Table';
import Tag from './Tag';
import Tags from './Tags';
import TalkBubble from './TalkBubble';
import ThreeDots from './ThreeDots';
import ThreeDotsVertical from './ThreeDotsVertical';
import Thumb from './Thumb';
import ThumbDown from './ThumbDown';
import ThumbRating from './ThumbRating';
import ThumbRatingLite from './ThumbRatingLite';
import ThumbUp from './ThumbUp';
import ThumbsDown from './ThumbsDown';
import ThumbsUpLite from './ThumbsUpLite';
import ThumbsUp from './ThumbsUp';
import Trash from './Trash';
import Trophy from './Trophy';
import Vector from './Vector';
import VS from './VS';
import Warning from './Warning';
import WarningFilled from './WarningFilled';
import WinRates from './WinRates';
import PaperAirplane from './PaperAirplane';
import ReportScheduleSplash from './ReportScheduleSplash';
import MagGlassPages from './MagGlassPages';

const SVG = (props) => {
	const components = {
		'arrow right': ArrowRight,
		'backwards five': BackwardsFive,
		'bell': Bell,
		'bookmark': Bookmark,
		'box check': BoxCheck,
		'buyer interview': TalkBubble,
		'buyer survey': CheckBubble,
		'calendar': Calendar,
		'carat': Carat,
		'chart': Chart,
		'chart alternate': ChartAlternate,
		'chat bubbles': ChatBubbles,
		'check bubble': CheckBubble,
		'checkmark': Checkmark,
		'check partial circle': CheckPartialCircle,
		'circle close': CircleClose,
		'circle info': CircleInfo,
		'circle info filled': CircleInfoFilled,
		'circle plus': CirclePlus,
		'circle trash': CircleTrash,
		'clipboard': Clipboard,
		'close': Close,
		'cloud upload': CloudUpload,
		'clozd circle': ClozdCircle,
		'deal': Deal,
		'deconstructed logo left': DeconstructedLogoLeft,
		'deconstructed logo right': DeconstructedLogoRight,
		'dollar': Dollar,
		'dots bubble': DotsBubble,
		'dots partial circle': DotsPartialCircle,
		'download': Download,
		'drag': Drag,
		'error': ErrorSVG,
		'exclamation circle': ExclamationCircle,
		'expand': Expand,
		'export': Export,
		'eye visible': EyeVisible,
		'eye invisible': EyeInvisible,
		'file': File,
		'file with lines': FileWithLines,
		'filter': Filter,
		'flag': Flag,
		'folder': Folder,
		'forward five': ForwardFiveS,
		'funnel': Funnel,
		'gdd': GDD,
		'gear': Gear,
		'google icon': GoogleIcon,
		'check': Check,
		'hamburger menu': HamburgerMenu,
		'hash': Hash,
		'horizontal bar chart': HorizontalBarChart,
		'house': House,
		'info': Info,
		'link': Link,
		'list': List,
		'lock': Lock,
		'logo': Logo,
		'magnifying glass': MagnifyingGlass,
		'merge': Merge,
		'paper airplane': PaperAirplane,
		'pencil': Pencil,
		'pencil underline': PencilUnderline,
		'people': People,
		'plus': Plus,
		'podium': Podium,
		'program': Program,
		'programs': Programs,
		'quote': Quote,
		'refresh arrows': RefreshArrows,
		'reports splash': ReportsSplash,
		'reportSchedule splash': ReportScheduleSplash,
		'rep interview': DotsBubble,
		'rep survey': BoxCheck,
		'scatterplot': Scatterplot,
		'scissor': Scissor,
		'share': Share,
		'share alternate': ShareAlternate,
		'side by side': SideBySide,
		'small plus': SmallPlus,
		'speech bubble': SpeechBubble,
		'squares': Squares,
		'star': Star,
		'summary': Summary,
		'summaries': Summaries,
		'switchArrows': SwitchArrows,
		'sync': Sync,
		'table': Table,
		'tag': Tag,
		'tags': Tags,
		'talk bubble': TalkBubble,
		'threeDots': ThreeDots,
		'threeDotsVertical': ThreeDotsVertical,
		'thumb': Thumb,
		'thumb down': ThumbDown,
		'thumb rating': ThumbRating,
		'thumb rating lite': ThumbRatingLite,
		'thumb up': ThumbUp,
		'thumbs up lite': ThumbsUpLite,
		'thumbs up': ThumbsUp,
		'thumbs down': ThumbsDown,
		'trash': Trash,
		'trophy': Trophy,
		'vector': Vector,
		'vs': VS,
		'warning': Warning,
		'warningFilled': WarningFilled,
		'win rates': WinRates,
		'mag glass pages': MagGlassPages,
	};

	if (components[props.name]) {
		const TagName = components[props.name];
		return <TagName {...props} />;
	}
	return null;
};

SVG.propTypes = {
	name: PropTypes.string,
};

export default SVG;
