import React from 'react';

const CircleInfo = ({ width = 17, height = 17, ...props }) => (
	<svg width={width} height={height} {...props} viewBox="0 0 17 17" version="1.0" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="8.5" cy="8.5" r="6" stroke="currentColor"/>
		<path d="M7.82605 7.626H9.17605V12H7.82605V7.626ZM7.71805 6.249C7.71805 6.033 7.79305 5.85 7.94305 5.7C8.09905 5.544 8.28505 5.466 8.50105 5.466C8.71705 5.466 8.90005 5.544 9.05005 5.7C9.20605 5.85 9.28405 6.033 9.28405 6.249C9.28405 6.465 9.20605 6.651 9.05005 6.807C8.90005 6.957 8.71705 7.032 8.50105 7.032C8.28505 7.032 8.09905 6.957 7.94305 6.807C7.79305 6.651 7.71805 6.465 7.71805 6.249Z" fill="currentColor"/>
	</svg>
);

export default CircleInfo;
