import React from 'react';

const DeconstructedLogoRight = (props) => (
	<svg {...props} width="299" height="446" viewBox="0 0 299 446" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.5">
			<path d="M458.585 60.5321L458.585 0.604599C343.071 13.9016 251.509 96.2944 236.733 200.242L303.329 200.242C317.093 129.021 379.506 72.8577 458.652 60.5321L458.585 60.5321Z" fill="currentColor" />
			<path d="M221.946 337.048L221.946 277.12C106.432 290.417 14.8706 372.81 0.0939789 476.757L66.6901 476.757C80.4546 405.536 142.868 349.373 222.014 337.048L221.946 337.048Z" fill="currentColor" />
			<path d="M237.645 277.122L237.645 337.246C270.41 329.783 296.184 306.597 304.48 277.122H237.645Z" fill="currentColor" />
			<path d="M237.645 217.779V277.904C270.41 270.441 296.184 247.255 304.48 217.779H237.645Z" fill="currentColor" />
		</g>
	</svg>

);

export default DeconstructedLogoRight;
