
import React from 'react';

const VS = (props) => (
	<svg {...props} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="0.5" width="15" height="11" rx="1.5" fill="transparent" stroke="currentColor"/>
		<path d="M3.20981 3.336H4.09781L5.76181 7.944H5.77781L7.50581 3.336H8.34581L6.11381 9H5.39381L3.20981 3.336ZM9.19288 7.816C9.33154 8.024 9.50754 8.17867 9.72088 8.28C9.93954 8.376 10.1635 8.424 10.3929 8.424C10.5209 8.424 10.6515 8.40533 10.7849 8.368C10.9182 8.32533 11.0382 8.264 11.1449 8.184C11.2569 8.104 11.3475 8.00533 11.4169 7.888C11.4862 7.77067 11.5209 7.63467 11.5209 7.48C11.5209 7.26133 11.4515 7.096 11.3129 6.984C11.1742 6.86667 11.0009 6.77067 10.7929 6.696C10.5902 6.616 10.3662 6.54133 10.1209 6.472C9.88088 6.39733 9.65688 6.296 9.44888 6.168C9.24621 6.04 9.07554 5.86933 8.93688 5.656C8.79821 5.43733 8.72888 5.144 8.72888 4.776C8.72888 4.61067 8.76354 4.43467 8.83288 4.248C8.90754 4.06133 9.02221 3.89067 9.17688 3.736C9.33154 3.58133 9.52888 3.45333 9.76888 3.352C10.0142 3.24533 10.3075 3.192 10.6489 3.192C10.9582 3.192 11.2542 3.23467 11.5369 3.32C11.8195 3.40533 12.0675 3.57867 12.2809 3.84L11.6569 4.408C11.5609 4.25867 11.4249 4.13867 11.2489 4.048C11.0729 3.95733 10.8729 3.912 10.6489 3.912C10.4355 3.912 10.2569 3.94133 10.1129 4C9.97421 4.05333 9.86221 4.12533 9.77688 4.216C9.69154 4.30133 9.63021 4.39467 9.59288 4.496C9.56088 4.59733 9.54488 4.69067 9.54488 4.776C9.54488 5.016 9.61421 5.2 9.75288 5.328C9.89154 5.456 10.0622 5.56 10.2649 5.64C10.4729 5.72 10.6969 5.792 10.9369 5.856C11.1822 5.92 11.4062 6.01067 11.6089 6.128C11.8169 6.24 11.9902 6.39467 12.1289 6.592C12.2675 6.784 12.3369 7.05067 12.3369 7.392C12.3369 7.664 12.2835 7.90933 12.1769 8.128C12.0755 8.34667 11.9369 8.53067 11.7609 8.68C11.5849 8.82933 11.3769 8.944 11.1369 9.024C10.8969 9.104 10.6409 9.144 10.3689 9.144C10.0062 9.144 9.66221 9.08 9.33688 8.952C9.01154 8.824 8.75288 8.62667 8.56087 8.36L9.19288 7.816Z" fill="currentColor"/>
	</svg>
);

export default VS;
