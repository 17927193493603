import React from 'react';

const BackwardsFive = (props) => (

<svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M17.5195 9.988C17.5135 5.57 13.9395 2 9.52152 2C6.96152 2 4.68152 3.204 3.21752 5.076L2.25752 4.326C2.15152 4.244 1.99752 4.32 1.99952 4.452L2.01552 7.792C2.01552 7.896 2.11352 7.972 2.21352 7.946L5.45952 7.156C5.48878 7.14887 5.51544 7.13362 5.53642 7.11201C5.5574 7.0904 5.57185 7.06331 5.57812 7.03385C5.58439 7.00439 5.58222 6.97376 5.57185 6.94548C5.56148 6.91721 5.54335 6.89243 5.51952 6.874L4.47752 6.06C4.63752 5.856 4.81152 5.66 4.99752 5.474C5.58026 4.88924 6.27106 4.42327 7.03152 4.102C7.81952 3.768 8.65752 3.6 9.52152 3.6C10.3855 3.6 11.2235 3.768 12.0115 4.102C12.7735 4.424 13.4575 4.886 14.0455 5.474C14.6335 6.062 15.0955 6.746 15.4175 7.508C15.7515 8.298 15.9195 9.136 15.9195 10C15.9195 10.864 15.7515 11.702 15.4175 12.49C15.0955 13.252 14.6335 13.936 14.0455 14.524C13.4575 15.112 12.7735 15.574 12.0115 15.896C11.2235 16.23 10.3855 16.398 9.52152 16.398C8.65752 16.398 7.81952 16.23 7.03152 15.896C6.27106 15.5747 5.58026 15.1088 4.99752 14.524C4.84752 14.374 4.70752 14.218 4.57352 14.056C4.54716 14.0235 4.50904 14.0028 4.46746 13.9983C4.42589 13.9938 4.38422 14.0059 4.35152 14.032L3.34152 14.82C3.27152 14.876 3.25952 14.978 3.31552 15.048C4.78352 16.85 7.01752 18 9.52152 18C13.9435 18 17.5255 14.412 17.5195 9.988Z" fill="currentColor"/>
	<path d="M9.5016 13.0847C9.05084 13.0847 8.65039 13.0032 8.30025 12.8402C7.95011 12.6772 7.67341 12.4528 7.47017 12.1671C7.26894 11.8813 7.1643 11.5533 7.15625 11.1831H8.60511C8.61719 11.4105 8.70975 11.5936 8.88281 11.7324C9.05587 11.8693 9.26213 11.9377 9.5016 11.9377C9.68874 11.9377 9.85476 11.8964 9.99964 11.8139C10.1445 11.7314 10.2582 11.6157 10.3407 11.4668C10.4232 11.3159 10.4635 11.1428 10.4615 10.9476C10.4635 10.7484 10.4222 10.5743 10.3377 10.4254C10.2552 10.2765 10.1405 10.1608 9.99361 10.0783C9.84872 9.99379 9.6817 9.95153 9.49254 9.95153C9.31345 9.94951 9.14341 9.98876 8.98242 10.0692C8.82345 10.1497 8.70271 10.2594 8.62021 10.3983L7.30114 10.1507L7.56978 6.81836H11.536V8.02273H8.7983L8.65643 9.48065H8.69265C8.79528 9.3096 8.96029 9.16874 9.18768 9.05806C9.41708 8.94537 9.67768 8.88903 9.96946 8.88903C10.3397 8.88903 10.6697 8.97556 10.9595 9.14862C11.2513 9.31966 11.4807 9.55711 11.6477 9.86097C11.8168 10.1648 11.9013 10.513 11.9013 10.9054C11.9013 11.33 11.8007 11.7063 11.5994 12.0343C11.4002 12.3623 11.1205 12.6199 10.7603 12.807C10.4021 12.9921 9.98254 13.0847 9.5016 13.0847Z" fill="currentColor"/>
</svg>

);

export default BackwardsFive;
