// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bbv77n11vykzqhtrfilz{align-items:center;background-color:#f0f1f2;display:flex;height:100%;justify-content:center}.Bbv77n11vykzqhtrfilz .qCfxbwo2hIyvpEJlpaFa{margin-top:-100px;width:450px}.Bbv77n11vykzqhtrfilz .qCfxbwo2hIyvpEJlpaFa .relTrY3hMBjttqPlCpS6{color:#1f2528;font-size:34px;font-weight:600}.Bbv77n11vykzqhtrfilz .qCfxbwo2hIyvpEJlpaFa .CJ7hEJabd6EFjFL_KMKk{color:#1f2528;font-size:20px;margin:20px 0 35px 0}.Bbv77n11vykzqhtrfilz .qCfxbwo2hIyvpEJlpaFa .VLSbsJMuFZfW_QUBpuWH{color:#14a6f8;cursor:pointer;font-size:16px}`, "",{"version":3,"sources":["webpack://./frontend/js/errorHandling/errorBoundary.scss","webpack://./node_modules/@clozd/clozd-ui/styles/_variables.scss"],"names":[],"mappings":"AAEA,sBACC,kBAAA,CACA,wBCGQ,CDFR,YAAA,CACA,WAAA,CACA,sBAAA,CAEA,4CACC,iBAAA,CACA,WAAA,CAEA,kEACC,aCEO,CDDP,cAAA,CACA,eAAA,CAGD,kEACC,aCJO,CDKP,cAAA,CACA,oBAAA,CAGD,kEACC,aCJM,CDKN,cAAA,CACA,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Bbv77n11vykzqhtrfilz`,
	"message-container": `qCfxbwo2hIyvpEJlpaFa`,
	"oops": `relTrY3hMBjttqPlCpS6`,
	"message": `CJ7hEJabd6EFjFL_KMKk`,
	"go-back": `VLSbsJMuFZfW_QUBpuWH`
};
export default ___CSS_LOADER_EXPORT___;
