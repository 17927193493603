import axios from 'axios';
import { rootStore } from '../stores';


const request = axios.create({
	baseURL: '/api',
});

request.interceptors.response.use((response) => response, (error) => {
	if (error?.response?.status === 401) {
		// Log out if any API calls return 401 because of expired session/token or disabled user
		const errorCode = error?.response?.data?.errorCode ?? 'UNKNOWN001';
		rootStore.UserStore.logOutBySystem(errorCode);
	}
	return Promise.reject(error);
});

export default request;
