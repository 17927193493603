import React from 'react';
import PropTypes from 'prop-types';

export default function Info(props) {
	return (
		<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<circle cx="8" cy="8" r="7.5" stroke="#6E6E6E"/>
			<path transform="translate(6.1, 2.75)" d="M3.015 4.53C3.015 4.95 2.88 5.675 2.61 6.705C2.34 7.725 2.205 8.395 2.205 8.715C2.205 9.025 2.25 9.18 2.34 9.18C2.4 9.18 2.63 9.085 3.03 8.895L3.21 8.805L3.465 9.33C3.375 9.41 3.255 9.51 3.105 9.63C2.955 9.75 2.675 9.915 2.265 10.125C1.855 10.325 1.475 10.425 1.125 10.425C0.775 10.425 0.5 10.32 0.3 10.11C0.1 9.89 0 9.6 0 9.24C0 8.87 0.125 8.22 0.375 7.29C0.625 6.35 0.75 5.74 0.75 5.46C0.75 5.04 0.62 4.645 0.36 4.275L0.225 4.095L0.24 3.9C0.77 3.75 1.63 3.675 2.82 3.675C2.95 3.825 3.015 4.11 3.015 4.53ZM1.305 2.04C1.105 1.84 1.005 1.575 1.005 1.245C1.005 0.915 1.14 0.625 1.41 0.375C1.69 0.125 2.01 0 2.37 0C2.73 0 3.01 0.0999999 3.21 0.3C3.41 0.5 3.51 0.765 3.51 1.095C3.51 1.415 3.365 1.7 3.075 1.95C2.795 2.2 2.48 2.325 2.13 2.325C1.78 2.325 1.505 2.23 1.305 2.04Z" fill="#6E6E6E"/>
		</svg>
	);
}

Info.defaultProps = {
	width: 16,
	height: 16,
};

Info.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
