import React from 'react';

const Checkmark = (props) => (
	<svg {...props} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M11.3578 0.6875H10.4216C10.2904 0.6875 10.1658 0.747768 10.0855 0.850893L4.56358 7.84598L1.91581 4.49107C1.87576 4.44022 1.82471 4.3991 1.76648 4.3708C1.70826 4.3425 1.64439 4.32776 1.57965 4.32768H0.643492C0.55376 4.32768 0.504207 4.4308 0.559117 4.50045L4.22742 9.14777C4.39885 9.36473 4.72831 9.36473 4.90108 9.14777L11.4422 0.858929C11.4971 0.790625 11.4475 0.6875 11.3578 0.6875Z" fill="currentColor"/>
	</svg>

);

export default Checkmark;
