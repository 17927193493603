import ReactGA from 'react-ga4';


// track the page and record pageview on GA
const trackCurrentPage = (path) => {
	ReactGA.set({ page: path });
	ReactGA.send({ hitType: 'pageview', page: path });
};

// form submissions, button clicks, etc.
// Behavior -> Overview
const recordEvent = (category, action, label) => {
	if (window.location.hostname.includes('app.clozd.com')) {
		ReactGA.event({
			category,
			action,
			label,
		});
	}
};

export default {
	trackCurrentPage,
	recordEvent,
};
