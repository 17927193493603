export const isExpressionValid = (expression) => {
	const { leftOperand, rightOperand, operator } = expression || {};

	if (leftOperand && operator) {
		// if expression has a valid rightOperand or is one of the IS NULL/IS NOT NULL operators
		if (
			rightOperand &&
			rightOperand instanceof Array &&
			rightOperand.length > 0
		) {
			return true;
		} else if (rightOperand && !(rightOperand instanceof Array)) {
			return true;
		} else if (['isNull', 'notNull', 'isCurrentUser'].includes(operator)) {
			return true;
		} else if (rightOperand === 0) {
			return true;
		} else if (rightOperand === false) {
			return true;
		}
	}
	return false;
};

export const filteredRolesByProgram = (user_roles) => {
	const unfilteredPrograms = [];
	const roleFilters = [];

	// first find all the programs that do not have any restricted role access
	user_roles.forEach(ur => {
		ur.role.role_permissions.forEach(rp => {
			if (!rp.configuration?.filters && rp.resource_type === 'program' && rp.sub_resource_type === '*') {
				unfilteredPrograms.push(rp.resource_id);
			}
		});
	});

	// now get role filters for all programs except the ones the user has unfiltered access to
	let isUnfilteredAccess = false;

	user_role_loop:
	for (const ur of user_roles) {
		for (const { configuration, resource_id, resource_type, sub_resource_type } of ur.role.role_permissions) {
			// if its a global admin, no need to apply role filters
			if (['*', 'program'].includes(resource_type) && resource_id === '*' && sub_resource_type === '*') {
				isUnfilteredAccess = true;
				break user_role_loop;
			}
			if (!unfilteredPrograms.includes(resource_id) && configuration?.filters) {
				roleFilters.push({ configuration, resource_id });
			}
		}
	}

	// if user is global admin, there are no restrictions
	return isUnfilteredAccess ? [] : roleFilters;
};

export const isUUID = (str) => {
	const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
	return pattern.test(str);
};

/**
 * Create a readable string from a filter expression
 * @param {Object} expression The expression object found inside of the array of expressions of a filter
 * @param {String} expression.rightOperand The right operand of the expression object
 * @param {String} expression.operator The operator of a filter expression
 * @param {String} expression.type The type of filter being used, the type is used to find the filter field in the Program Store
 * @returns {String} The readable filter string
 */
// export const getReadableFilter = (expression) => {
// 	// Check that the expression is valid before moving on
// 	if (!isExpressionValid(expression)) {
// 		return null;
// 	}

// 	// Grab the filter fields from the Program Store
// 	const ProgramStore = rootStore.ProgramStore;

// 	const filterFields = (ProgramStore.filterFields).toJSON();

// 	// Destructure the values needed
// 	const { rightOperand, operator, type } = expression || {};

// 	// Find the Filter Field object that matches the expression's type
// 	const key = filterFields.find(filter => filter.type === type);

// 	// Main body of logic to determine how the rightOperand should be displayed
// 	let value;
// 	if (Array.isArray(rightOperand)) {
// 		// If the rightOperand is an array of values
// 		// loop through and return array of strings
// 		value = rightOperand.map(rightValue => {
// 			if (typeof rightValue === 'number') {
// 				// Format number to comma separated string
// 				return rightValue.toLocaleString();
// 			}

// 			// Check if the rightValue matches one of the rightOperand options for this filter
// 			const matchedOption = key.rightOperandOptions.find(option => option.value === rightValue);

// 			// No match, return rightValue
// 			if (!matchedOption) {
// 				return rightValue;
// 			}

// 			// If the option has a translation key, use what is in the translation file
// 			if (matchedOption.translationKey) {
// 				return i18next.t(`program.global.${matchedOption.translationKey}`);
// 			}

// 			// If option has a title, return title
// 			if (matchedOption.title) {
// 				return matchedOption.title;
// 			}

// 			// If option has a label, return label
// 			if (matchedOption.label) {
// 				return matchedOption.label;
// 			}

// 			// Catch all
// 			return rightValue;
// 		}).join(', ');
// 	} else {
// 		// If rightOperand is not and array, just a single value
// 		if (typeof rightOperand === 'number') {
// 			// If number, return comma formatted number
// 			value = rightOperand.toLocaleString();
// 		} else {
// 			// Find rightOperand option that matches
// 			const matchedOption = key.rightOperandOptions.find(option => option.value === rightOperand);

// 			if (!matchedOption) {
// 				// No match, use rightOperand
// 				value = rightOperand;
// 			} else {
// 				if (matchedOption.translationKey) {
// 					// Use translation file value
// 					value = i18next.t(`program.global.${matchedOption.translationKey}`);
// 				} else if (matchedOption.title) {
// 					value = matchedOption.title;
// 				} else if (matchedOption.label) {
// 					value = matchedOption.label;
// 				} else {
// 					// Catch all
// 					value = rightOperand;
// 				}
// 			}
// 		}
// 	}

// 	// String should be of format `<leftOperand> <operator> "<...rightOperand(s)>"`
// 	return `${key.name || i18next.t(`program.filter.types.${key.type}`)} ${i18next.t(`program.filter.operators.${operator}`)}${value ? ` "${value}"` : ''}`;
// };
