import React from 'react';

const Plus = (props) => (
	<svg {...props} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="5.5" width="3" height="14" fill="currentColor"/>
		<rect width="3" height="14" transform="matrix(0 -1 1 0 0 8.5)" fill="currentColor"/>
	</svg>


);

export default Plus;
