import React from 'react';

const CircleTrash = (props) => (
	<svg {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.5 9.9H9.9V13.5H8.1V9.9H4.5V8.1H8.1V4.5H9.9V8.1H13.5V9.9ZM9 0C7.8181 0 6.64778 0.232792 5.55585 0.685084C4.46392 1.13738 3.47177 1.80031 2.63604 2.63604C0.948211 4.32387 0 6.61305 0 9C0 11.3869 0.948211 13.6761 2.63604 15.364C3.47177 16.1997 4.46392 16.8626 5.55585 17.3149C6.64778 17.7672 7.8181 18 9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 0 9 0Z" fill="currentColor"/>
		<rect x="8" y="4" width="2" height="10" fill="currentColor"/>
		<rect x="4" y="10" width="2" height="10" transform="rotate(-90 4 10)" fill="currentColor"/>
		<path d="M7.125 3V3.66667H4V5H4.625V13.6667C4.625 14.0203 4.7567 14.3594 4.99112 14.6095C5.22554 14.8595 5.54348 15 5.875 15H12.125C12.4565 15 12.7745 14.8595 13.0089 14.6095C13.2433 14.3594 13.375 14.0203 13.375 13.6667V5H14V3.66667H10.875V3H7.125ZM5.875 5H12.125V13.6667H5.875V5ZM7.125 6.33333V12.3333H8.375V6.33333H7.125ZM9.625 6.33333V12.3333H10.875V6.33333H9.625Z" fill="white"/>
	</svg>
);

export default CircleTrash;
