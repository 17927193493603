import React from 'react';
import PropTypes from 'prop-types';

export default function Dollar(props) {
	return (
		<svg  viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg" {...props} >
			<path
				d="M0.666626 8H1.99996C1.99996 8.72 2.91329 9.33333 3.99996 9.33333C5.08663 9.33333 5.99996 8.72 5.99996 8C5.99996 7.26667 5.30663 7 3.83996 6.64667C2.42663 6.29333 0.666626 5.85333 0.666626 4C0.666626 2.80667 1.64663 1.79333 2.99996 1.45333V0H4.99996V1.45333C6.35329 1.79333 7.33329 2.80667 7.33329 4H5.99996C5.99996 3.28 5.08663 2.66667 3.99996 2.66667C2.91329 2.66667 1.99996 3.28 1.99996 4C1.99996 4.73333 2.69329 5 4.15996 5.35333C5.57329 5.70667 7.33329 6.14667 7.33329 8C7.33329 9.19333 6.35329 10.2067 4.99996 10.5467V12H2.99996V10.5467C1.64663 10.2067 0.666626 9.19333 0.666626 8Z"
				fill="currentColor"
			/>
		</svg>
	);
}

Dollar.defaultProps = {
	width: 8,
	height: 12,
};

Dollar.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
