import React from 'react';

const ThreeDots = (props) => (
<svg  {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6667 4.16667C10.6667 4.5645 10.8247 4.94603 11.106 5.22733C11.3873 5.50864 11.7688 5.66667 12.1667 5.66667C12.5645 5.66667 12.946 5.50864 13.2273 5.22733C13.5086 4.94603 13.6667 4.5645 13.6667 4.16667C13.6667 3.76885 13.5086 3.38732 13.2273 3.10601C12.946 2.82471 12.5645 2.66667 12.1667 2.66667C11.7688 2.66667 11.3873 2.82471 11.106 3.10601C10.8247 3.38732 10.6667 3.76885 10.6667 4.16667ZM10.6667 11.6667C10.6667 12.0645 10.8247 12.446 11.106 12.7273C11.3873 13.0086 11.7688 13.1667 12.1667 13.1667C12.5645 13.1667 12.946 13.0086 13.2273 12.7273C13.5086 12.446 13.6667 12.0645 13.6667 11.6667C13.6667 11.2688 13.5086 10.8873 13.2273 10.606C12.946 10.3247 12.5645 10.1667 12.1667 10.1667C11.7688 10.1667 11.3873 10.3247 11.106 10.606C10.8247 10.8873 10.6667 11.2688 10.6667 11.6667ZM10.6667 19.1667C10.6667 19.5645 10.8247 19.946 11.106 20.2273C11.3873 20.5086 11.7688 20.6667 12.1667 20.6667C12.5645 20.6667 12.946 20.5086 13.2273 20.2273C13.5086 19.946 13.6667 19.5645 13.6667 19.1667C13.6667 18.7688 13.5086 18.3873 13.2273 18.106C12.946 17.8247 12.5645 17.6667 12.1667 17.6667C11.7688 17.6667 11.3873 17.8247 11.106 18.106C10.8247 18.3873 10.6667 18.7688 10.6667 19.1667Z" fill="currentColor"/>
</svg>

);

export default ThreeDots;

