import React from 'react';

const PencilUnderline = (props) => props.filled
	? (
		<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.75 13.0625H2.25C1.97344 13.0625 1.75 13.2859 1.75 13.5625V14.125C1.75 14.1937 1.80625 14.25 1.875 14.25H14.125C14.1938 14.25 14.25 14.1937 14.25 14.125V13.5625C14.25 13.2859 14.0266 13.0625 13.75 13.0625ZM4.02656 11.75C4.05781 11.75 4.08906 11.7468 4.12031 11.7422L6.74844 11.2812C6.77969 11.275 6.80938 11.2609 6.83125 11.2375L14.3436 3.72515C14.3581 3.71069 14.3696 3.69352 14.3774 3.67462C14.3852 3.65572 14.3893 3.63545 14.3893 3.61499C14.3893 3.59453 14.3852 3.57426 14.3774 3.55536C14.3696 3.53646 14.3581 3.51929 14.3436 3.50483L11.7467 0.906396C11.717 0.876709 11.678 0.861084 11.6358 0.861084C11.5936 0.861084 11.5545 0.876709 11.5248 0.906396L4.0125 8.41872C3.98906 8.44216 3.975 8.47029 3.96875 8.50154L3.50781 11.1297C3.49261 11.2134 3.49804 11.2995 3.52364 11.3806C3.54923 11.4618 3.59421 11.5355 3.65469 11.5953C3.75781 11.6953 3.8875 11.75 4.02656 11.75Z" fill="currentColor"/>
		</svg>
	) : (
		<svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2.59392 9.21303C2.6207 9.21303 2.64749 9.21035 2.67427 9.20633L4.92695 8.81124C4.95374 8.80589 4.97919 8.79383 4.99794 8.77375L10.6752 3.09651C10.6876 3.08412 10.6974 3.06941 10.7042 3.0532C10.7109 3.037 10.7143 3.01963 10.7143 3.00209C10.7143 2.98455 10.7109 2.96718 10.7042 2.95098C10.6974 2.93478 10.6876 2.92006 10.6752 2.90767L8.44928 0.680441C8.42383 0.654994 8.39035 0.641602 8.35419 0.641602C8.31803 0.641602 8.28454 0.654994 8.2591 0.680441L2.58186 6.35767C2.56177 6.37776 2.54972 6.40187 2.54436 6.42866L2.14927 8.68133C2.13625 8.75308 2.1409 8.82692 2.16284 8.89647C2.18477 8.96601 2.22333 9.02916 2.27517 9.08044C2.36356 9.16616 2.47472 9.21303 2.59392 9.21303ZM3.4966 6.87732L8.35419 2.02107L9.33588 3.00276L4.47829 7.85901L3.28767 8.06928L3.4966 6.87732ZM10.9283 10.338H1.07115C0.834096 10.338 0.642578 10.5295 0.642578 10.7666V11.2487C0.642578 11.3077 0.690792 11.3559 0.749721 11.3559H11.2497C11.3087 11.3559 11.3569 11.3077 11.3569 11.2487V10.7666C11.3569 10.5295 11.1653 10.338 10.9283 10.338Z" fill="currentColor"/>
		</svg>
	);

export default PencilUnderline;
