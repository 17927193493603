import React from 'react';

const Calendar = (props) => (
	<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="1.5" width="15" height="14" rx="1.5" stroke="currentColor" strokeMiterlimit="4.81765"/>
		<path d="M0.5 5.5H15.5" stroke="currentColor"/>
		<rect x="11" width="1" height="4" rx="0.5" fill="currentColor"/>
		<rect x="4" width="1" height="4" rx="0.5" fill="currentColor"/>
	</svg>


);

export default Calendar;
