import React from 'react';

const Chart = (props) => (
	<svg {...props} viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path d="M4.98514 10.5963C5.04742 10.6586 5.14988 10.6586 5.21215 10.5963L7.25724 8.55123L8.98693 10.2829C9.04921 10.3452 9.15166 10.3452 9.21394 10.2829L13.7601 5.7327C13.8224 5.67043 13.8224 5.56797 13.7601 5.5057L13.0209 4.76641C12.9907 4.7365 12.9499 4.71972 12.9074 4.71972C12.8648 4.71972 12.8241 4.7365 12.7938 4.76641L9.10144 8.46284L7.37175 6.73114C7.34154 6.70123 7.30076 6.68445 7.25825 6.68445C7.21574 6.68445 7.17495 6.70123 7.14474 6.73114L4.24586 9.63003C4.21595 9.66023 4.19917 9.70102 4.19917 9.74353C4.19917 9.78604 4.21595 9.82683 4.24586 9.85703L4.98514 10.5963Z" fill="currentColor" />
		<path d="M16.8743 1.92977H9.72252V0.644052C9.72252 0.555659 9.6502 0.483337 9.5618 0.483337H8.4368C8.34841 0.483337 8.27609 0.555659 8.27609 0.644052V1.92977H1.1243C0.768722 1.92977 0.481445 2.21704 0.481445 2.57262V13.019C0.481445 13.3746 0.768722 13.6619 1.1243 13.6619H8.28412V14.3048L4.97341 16.4764C4.95581 16.4878 4.94065 16.5026 4.92879 16.52C4.91693 16.5373 4.90862 16.5568 4.90432 16.5773C4.90003 16.5978 4.89984 16.619 4.90377 16.6396C4.90769 16.6602 4.91566 16.6799 4.9272 16.6974L5.53591 17.6456V17.6476C5.58412 17.722 5.68457 17.742 5.7589 17.6938L8.9993 15.5684L12.2397 17.6938C12.314 17.742 12.4145 17.722 12.4627 17.6476V17.6456L13.0714 16.6974C13.0943 16.6619 13.1023 16.6188 13.0936 16.5774C13.085 16.5361 13.0604 16.4998 13.0252 16.4764L9.72252 14.3108V13.6619H16.8743C17.2299 13.6619 17.5172 13.3746 17.5172 13.019V2.57262C17.5172 2.21704 17.2299 1.92977 16.8743 1.92977ZM16.0707 12.2155H1.92787V3.37619H16.0707V12.2155Z" fill="currentColor" />
	</svg>
);

export default Chart;
