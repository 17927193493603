import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Card from '@reusableComponents/Card/card';
import RollbarErrorTracking from './rollbarErrorTracking';
import styles from './errorBoundary.scss';
import { v4 as uuid } from 'uuid';
import request from '../api/request';
import { Title, Button } from '@clozd/clozd-ui';

const cx = classNames.bind(styles);

// React current doesn't allow for errorBoundary components to be functional components
// Error boundaries also cannot catch compile errors so it will still show a white screen.
// It also can't catch event handlers, asynchronous code, and server side rendering - it's not a wizard unfortunately
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			errorId: '',
		};
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			if (this.props.errorComponent) {
				return this.props.errorComponent;
			}
			return (
				<div className={cx('container')}>
					<Card className={cx('message-container')}>
						<Title className={cx('oops')} size={3}>{this.props.t('errorBoundary.oops')}</Title>
						<div className={cx('message')}>
							<div>{this.props.t('errorBoundary.message')}</div>
							<div>{this.props.t('errorBoundary.errorId', { errorId: this.state.errorId })}</div>
						</div>
						<Button
							data-testid="error-boundary-back-button"
							type="link"
							onClick={() => this.setState({ hasError: false })}
							icon="arrow-left"
							size="large"
						>
							{this.props.t('errorBoundary.goBack')}
						</Button>
					</Card>
				</div>
			);
		}

		return this.props.children;
	}

	componentDidCatch(error, errorInfo) {
		const errorId = uuid();
		switch (this.props.logLevel) {
			case 'info':
				console.info(error); // eslint-disable-line
				break;
			case 'warn':
				console.warn(error); // eslint-disable-line
				break;
			case 'error':
			default:
				RollbarErrorTracking.logErrorInfo(error);
				this.sendErrorToServer(errorId, error, errorInfo);
				break;
		}
		this.setState({ errorId });
	}

	sendErrorToServer(errorId, error, errorInfo) {
		const errorPayload = {
			componentStack: errorInfo?.componentStack,
			errorId,
			errorMessage: error?.message,
			errorStack: error?.stack,
			timestamp: new Date().toISOString(),
			url: window.location.href,
		};

		request.post('/app/log-error', errorPayload);
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
	errorComponent: PropTypes.node,
	location: PropTypes.object,
	logLevel: PropTypes.oneOf(['info', 'warn', 'error']),
	t: PropTypes.func,
};

// withRouter allows us to access the previous location path
export default withTranslation()(ErrorBoundary);
