import React from 'react';

const SwitchArrows = props => (
	<svg {...props} width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.23263 4.3682C8.4279 4.17294 8.4279 3.85636 8.23263 3.6611L5.05065 0.479115C4.85539 0.283852 4.53881 0.283852 4.34355 0.479115C4.14829 0.674377 4.14829 0.990959 4.34355 1.18622L7.17197 4.01465L4.34355 6.84308C4.14829 7.03834 4.14829 7.35492 4.34355 7.55018C4.53881 7.74544 4.85539 7.74544 5.05065 7.55018L8.23263 4.3682ZM7.02441 4.51465H7.87908V3.51465H7.02441V4.51465Z" fill="currentColor" />
		<path d="M7.02443 4.01491C5.45754 3.87247 1.67804 4.68896 1.04176 9.14292C0.899312 10.14 1.04176 12.5616 2.75109 14.2709" stroke="currentColor" strokeLinecap="round" />
		<path d="M5.81619 15.6269C5.62093 15.8222 5.62093 16.1388 5.81619 16.334L8.99817 19.516C9.19344 19.7113 9.51002 19.7113 9.70528 19.516C9.90054 19.3207 9.90054 19.0042 9.70528 18.8089L6.87685 15.9805L9.70528 13.152C9.90054 12.9568 9.90054 12.6402 9.70528 12.4449C9.51002 12.2497 9.19344 12.2497 8.99817 12.4449L5.81619 15.6269ZM7.02441 15.4805L6.16975 15.4805L6.16975 16.4805L7.02441 16.4805L7.02441 15.4805Z" fill="currentColor" />
		<path d="M7.0244 15.9802C8.59129 16.1226 12.3708 15.3062 13.0071 10.8522C13.1495 9.85509 13.0071 7.43353 11.2977 5.7242" stroke="currentColor" strokeLinecap="round" />
	</svg>
);

export default SwitchArrows;
