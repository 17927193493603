import Rollbar from 'rollbar';

const getSubdomain = () => {
	const hostname = window.location.hostname;
	if (hostname === 'clozd.com' || hostname.endsWith('.clozd.com')) {
		const subdomain = hostname.split('.').slice(0, -2).join('.');
		return subdomain || '';  // if no subdomain, return 'main' or any default
	}
	return null;
};

const subdomain = getSubdomain();
const isEnabled = Boolean(subdomain);

export const RollbarErrorTracking = (() => {
	const RollbarObj = new Rollbar({
		accessToken: ROLLBAR_ACCESS_TOKEN,
		captureUncaught: true,
		captureUnhandledRejections: true,
		payload: {
			subdomain,
		},
		enabled: isEnabled,
	});

	const logErrorInfo = (error) => {
		RollbarObj.info(error);
	};

	return { logErrorInfo };
})();

export default RollbarErrorTracking;
