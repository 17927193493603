import { types, flow, getEnv } from 'mobx-state-tree';
import request from '@app/js/api/request';
import _get from 'lodash/get';
import { userHasPermission } from '@utils/authorization';
import { message } from 'antd';
import pollJob from '../utils/pollJob';
import i18next from 'i18next';

const { optional, maybeNull, array, model, string, number, boolean, frozen, enumeration, map } = types;

// Model
export const RoleModel = model('RoleModel', {
	role_id: optional(string, ''),
	name: optional(string, ''),
	is_system_role: optional(boolean, false),
});

export const OrgProgramsModel = model('OrgProgramsModel', {
	program_id: optional(string, ''),
	name: optional(string, ''),
	current_status: optional(string, ''),
	initial_status: optional(string, ''),
	isEdited: optional(boolean, false), // track whether to save the program
	created_at: optional(string, ''),
});

export const CustomEmailDomainModel = model('CustomEmailDomainModel', {
	domain: optional(string, ''),
	domain_id: optional(number, 0),
	created_at: optional(string, ''),
	verified: optional(boolean, false),
	DKIMPendingHost: maybeNull(string),
	DKIMPendingTextValue: maybeNull(string),
	ReturnPathDomain: maybeNull(string),
	ReturnPathDomainCNAMEValue: maybeNull(string),
});

export const OrganizationStoreModel = model('OrganizationStore', {
	organizationId: maybeNull(string),
	errorCode: optional(string, ''),
	roles: array(RoleModel),
	filteredRoles: array(RoleModel),
	organizations: array(model({
		attributes: maybeNull(model({
			client_id: maybeNull(string),
			client_secret: maybeNull(string),
			email_domain: maybeNull(string),
			issuer_url: maybeNull(string),
			idp_entry_point: maybeNull(string),
			idp_public_key: maybeNull(string),
			idp_email_map: maybeNull(string),
			idp_first_name_map: maybeNull(string),
			idp_last_name_map: maybeNull(string),
			sso: maybeNull(enumeration('sso', ['google', 'okta', 'saml'])),
			user_generated_accounts: maybeNull(boolean),
			allowed_domains: optional(array(string), ['']),
			test_email_domains: array(string),
			custom_email_domains: array(CustomEmailDomainModel),
		})),
		consultancy_id: optional(string, ''),
		created_at: optional(string, ''),
		deleted_at: maybeNull(string),
		logo: maybeNull(string),
		name: optional(string, ''),
		organization_id: optional(string, ''),
		status: optional(string, ''),
		subdomain: maybeNull(string),
		type: optional(enumeration('type', ['corporate', 'demo', 'test', 'trial']), 'corporate'),
		external_id: maybeNull(string),
		updated_at: optional(string, ''),
		default_role_id: maybeNull(string),
		software_package: maybeNull(enumeration('SoftwarePackage', ['legacy', 'essentials', 'professional', 'advanced'])),
		org_features: maybeNull(array(string)),
		org_programs: maybeNull(array(OrgProgramsModel)),
		interview_package: maybeNull(enumeration('InterviewPackage', ['multiple', 'premium', 'scale', 'premium_async', 'none'])), // 'multiple' means the org has 2 or more interview packages selected
		password_expiration_window: maybeNull(number),
	})),
	organizationsCount: maybeNull(number),
	programs: array(model({
		attributes: frozen(),
		consultancy_id: optional(string, ''),
		created_at: optional(string, ''),
		deleted_at: maybeNull(string),
		name: optional(string, ''),
		objectives: frozen(),
		organization_id: optional(string, ''),
		program_id: optional(string, ''),
		status: optional(string, ''),
		updated_at: optional(string, ''),
	})),
	loadingPrograms: optional(boolean, false),
	default_features_per_package: map(array(string)),
})
	.views((self) => ({
		get baseURL() {
			return `/organizations/${self.organizationId}`;
		},
		get user() {
			return getEnv(self).user || {};
		},
		get selectedOrganization() {
			return self.organizations.find(org => org.organization_id === self.organizationId) ?? {};
		},
		get customDomains() {
			if (self.selectedOrganization.type === 'test') return self.selectedOrganization.attributes.allowed_domains;

			return self.selectedOrganization.attributes.custom_email_domains.map(d => d.domain);
		},
		get consultancyId() {
			return self.selectedOrganization?.consultancy_id;
		},
		get organizationIds() {
			return self.organizations.map(({ organization_id }) => organization_id);
		},
		get orgInterviewPackage() {
			return self.selectedOrganization?.interview_package;
		},
		programNameById(programId) {
			return self.programs.find(p => p.program_id === programId)?.name;
		},
		// Use this view to determine if a particular organization has access to a feature
		// This is how you implement front-end gating of UI etc.
		orgHasFeature(name) {
			return self.selectedOrganization?.org_features?.includes(name) || false;
		},
		get hasSSO() {
			return !!self.selectedOrganization?.attributes?.sso;
		},
	}))
	.actions((self) => ({
		resetError() {
			self.errorCode = '';
		},
		setDefaultRoleId(defaultRoleId) {
			self.selectedOrganization.default_role_id = defaultRoleId;
		},
		setErrorCode(error) {
			self.errorCode = _get(error, 'response.data.errorCode', '');
		},
		fetchOrganizations: flow(function* fetchOrganizations(tableOptions = null) {
			const params = (
				typeof tableOptions === 'object' &&
				!Array.isArray(tableOptions) &&
				tableOptions !== null
			) ? ({
					page: tableOptions?.pagination?.current,
					pageSize: tableOptions?.pagination?.pageSize,
					sortField: tableOptions?.sorter?.field,
					sortOrder: tableOptions?.sorter?.order,
					searchTerm: tableOptions?.searchTerm,
					activeClientFilter: tableOptions?.activeClientFilter,
				}) : {};
			try {
				const response = yield request.get(
					'/organizations',
					{ params },
				);

				self.organizations = response.data.data.organizations;
				self.organizationsCount = response.data.data.count;
				self.default_features_per_package = response.data.data.defaultFeaturesPerPackageObject;
			} catch (error) {
				self.setErrorCode(error);
			}
		}),
		fetchOrganizationIdByProgramId: flow(function* fetchOrganizationIdForProgram(programId, saveToSession) {
			try {
				const { data } = yield request.get(`/app/organization_id/${programId}`, {});
				const { organization_id } = data.data;
				self.selectOrganization(organization_id, saveToSession);
			} catch (error) {
				self.setErrorCode(error);
			}
		}),
		fetchRoles: flow(function* fetchRoles() {
			try {
				const { data } = yield request.get(`organizations/${self.organizationId}/roles`, {
					params: {
						page: 'null',
						pageSize: 'null',
						fields: 'role_id,name,is_system_role',
					},
				});
				self.roles = data.data.data.rows;
			} catch (err) {
				console.error(err);
			}
		}),
		selectOrganization: flow(function* selectOrganization(organizationId, saveToSession) {
			try {
				self.organizationId = self.organizationIds.includes(organizationId) ? organizationId : self.user.organization_id;
				if (saveToSession) {
					yield request.post(`${self.baseURL}/select`);
				}
				yield self.fetchRoles();
				yield self.fetchPrograms();
			} catch (error) {
				self.setErrorCode(error);
			}
		}),
		fetchPrograms: flow(function* fetchPrograms() {
			try {
				self.loadingPrograms = true;
				const response = yield request.get(`${self.baseURL}/programs`);
				self.programs = response.data.data;
			} catch (error) {
				self.setErrorCode(error);
			}
			self.loadingPrograms = false;
		}),
		updateOrganization: flow(function* updateOrganization(org) {
			try {
				yield request.put(`/organizations/${self.organizationId}`, { ...org, ...org.attributes });
			} catch (error) {
				self.setErrorCode(error);
			}
		}),
		replaceOrganization(org) {
			const index = self.organizations.findIndex(_org => _org.organization_id === org.organization_id);
			if (index >= 0) {
				self.organizations[index] = org;
			}
		},
		checkUserAccess(operation, resourceType, resourceId, subResourceType, subResourceId) {
			return userHasPermission({
				scopeType: 'organization',
				scopeId: self.organization.organizationId,
				resourceType,
				resourceId,
				subResourceType,
				subResourceId,
				operation,
			});
		},
		exportUsers: flow(function* exportUsers(filename) {
			try {
				const job = yield request.get(`${self.baseURL}/users/export`);
				message.info(i18next.t('organization.exportStarted'));

				const job_id = job.data;
				yield pollJob(job_id);
				window.location.replace(`/api/jobs/${job_id}/export?filename=${filename}`);
				message.success(i18next.t('organization.exportSucceeded'));
			} catch (err) {
				console.error(err);
				message.error(i18next.t('errors.EXPORT001'));
				throw err;
			}
		}),
	}));
