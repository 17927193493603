import React from 'react';

const ForwardFiveS = (props) => (
	<svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M16.302 5.076C14.838 3.204 12.558 2 9.99801 2C5.58001 2 2.00601 5.57 2.00001 9.988C1.99401 14.412 5.57601 18 9.99801 18C12.502 18 14.736 16.85 16.202 15.048C16.258 14.978 16.246 14.876 16.176 14.82L15.166 14.032C15.098 13.978 15 13.99 14.944 14.056C14.812 14.218 14.67 14.374 14.52 14.524C13.9373 15.1088 13.2465 15.5747 12.486 15.896C11.7 16.232 10.862 16.4 9.99801 16.4C9.13401 16.4 8.29601 16.232 7.50801 15.898C6.74601 15.576 6.06201 15.114 5.47401 14.526C4.88601 13.938 4.42401 13.254 4.10201 12.492C3.76801 11.702 3.60001 10.864 3.60001 10C3.60001 9.136 3.76801 8.298 4.10201 7.51C4.42401 6.748 4.88601 6.064 5.47401 5.476C6.06201 4.888 6.74601 4.426 7.50801 4.104C8.29601 3.77 9.13401 3.602 9.99801 3.602C10.862 3.602 11.7 3.77 12.488 4.104C13.2485 4.42527 13.9393 4.89124 14.522 5.476C14.708 5.662 14.882 5.858 15.042 6.062L14 6.876C13.9762 6.89443 13.958 6.91921 13.9477 6.94748C13.9373 6.97576 13.9351 7.00639 13.9414 7.03585C13.9477 7.06531 13.9621 7.0924 13.9831 7.11401C14.0041 7.13562 14.0307 7.15087 14.06 7.158L17.304 7.95C17.404 7.974 17.502 7.898 17.502 7.796L17.518 4.456C17.518 4.322 17.364 4.246 17.26 4.33L16.302 5.076Z" fill={props.color || 'currentColor'}/>
		<path d="M10.0016 13.0847C9.55084 13.0847 9.15039 13.0032 8.80025 12.8402C8.45011 12.6772 8.17341 12.4528 7.97017 12.1671C7.76894 11.8813 7.6643 11.5533 7.65625 11.1831H9.10511C9.11719 11.4105 9.20975 11.5936 9.38281 11.7324C9.55587 11.8693 9.76213 11.9377 10.0016 11.9377C10.1887 11.9377 10.3548 11.8964 10.4996 11.8139C10.6445 11.7314 10.7582 11.6157 10.8407 11.4668C10.9232 11.3159 10.9635 11.1428 10.9615 10.9476C10.9635 10.7484 10.9222 10.5743 10.8377 10.4254C10.7552 10.2765 10.6405 10.1608 10.4936 10.0783C10.3487 9.99379 10.1817 9.95153 9.99254 9.95153C9.81345 9.94951 9.64341 9.98876 9.48242 10.0692C9.32345 10.1497 9.20271 10.2594 9.12021 10.3983L7.80114 10.1507L8.06978 6.81836H12.036V8.02273H9.2983L9.15643 9.48065H9.19265C9.29528 9.3096 9.46029 9.16874 9.68768 9.05806C9.91708 8.94537 10.1777 8.88903 10.4695 8.88903C10.8397 8.88903 11.1697 8.97556 11.4595 9.14862C11.7513 9.31966 11.9807 9.55711 12.1477 9.86097C12.3168 10.1648 12.4013 10.513 12.4013 10.9054C12.4013 11.33 12.3007 11.7063 12.0994 12.0343C11.9002 12.3623 11.6205 12.6199 11.2603 12.807C10.9021 12.9921 10.4825 13.0847 10.0016 13.0847Z" fill={props.color || 'currentColor'}/>

	</svg>

);

export default ForwardFiveS;
