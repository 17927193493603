import React from 'react';

const Close = (props) => (
	<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.7778 3.38598L12.614 2.22217L8.00001 6.83614L3.38604 2.22217L2.22223 3.38598L6.8362 7.99995L2.22223 12.6139L3.38604 13.7777L8.00001 9.16376L12.614 13.7777L13.7778 12.6139L9.16382 7.99995L13.7778 3.38598Z" fill="currentColor"/>
	</svg>

);

export default Close;
