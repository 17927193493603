import React from 'react';

const Summary = (props) => (
	<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.4465 7L8 0.693889L14.5535 7H14H13.5V7.5V14.5H2.5V7.5V7H2H1.4465Z" fill="transparent" stroke="currentColor"/>
	</svg>

);

export default Summary;

