import request from '@app/js/api/request';
import utils from '@utils/utils';

const completedStatuses = ['complete', 'Completed'];
const failedStatuses = ['failed', 'Failed'];

export const pollJob = async (
	job_id,
	callback,
	{
		interval = 1000,
		maxTimeout = 60000,
		customIntervals = null,
		fromJobscheduler = false,
	},
) => {
	let timeouts = customIntervals;
	if (!timeouts) {
		if (!maxTimeout && interval && interval < maxTimeout) throw new Error('Must provide valid interval and maxTimeout');

		timeouts = Array(Math.floor(maxTimeout / interval)).fill(null).map((v, i) => interval * (i + 1));
	}

	for (let i = 0; i < timeouts.length; i++) {
		const response = await request.get(`/jobs/${job_id}?fromJobscheduler=${fromJobscheduler}`);
		if (completedStatuses.includes(response?.data?.data?.status)) return response?.data;
		if (failedStatuses.includes(response?.data?.data?.status)) {
			const error = new Error(utils.constants.jobFail);
			error.errorCode = response?.data?.data?.attributes?.errorCode || response?.data?.errorCode;
			error.data = response?.data?.data?.attributes?.data || response?.data?.data?.attributes;
			throw error;
		}
		if (callback) {
			callback(response.data);
		}
		await new Promise((resolve) => setTimeout(() => resolve(), timeouts[i]));
	}
	const timeoutErrorCode = 'JOB002';
	const timeoutError = new Error(timeoutErrorCode);
	timeoutError.errorCode = timeoutErrorCode;
	throw timeoutError;
};


const defautPoller = async (job_id, fromJobscheduler = false, timeoutMultiplier = null) => {
	let customIntervals = [2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 15000, 30000, 60000];

	if (timeoutMultiplier) customIntervals = customIntervals.map((t) => t * timeoutMultiplier);

	return await pollJob(job_id, null, {
		customIntervals,
		fromJobscheduler,
	});
};


export default defautPoller;
