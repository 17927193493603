import React from 'react';

const ClozdCircle = (props) => (
	<svg viewBox="0 0 375.83 375.83" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path fill="currentColor" d="M214.14,99.93c15.49-29.93,24.79-61.17,29.42-86.99.45-2.53-1.08-4.97-3.54-5.69-31.14-9.13-67.13-9.63-98.8-1.49-3.99,1.02-5.06,6.16-1.84,8.73,26.03,20.77,50.52,51.98,65.81,85.29,1.73,3.77,7.04,3.84,8.95.15Z"/>
		<path fill="currentColor" d="M161.69,275.91c-15.49,29.93-24.79,61.17-29.42,86.99-.45,2.53,1.08,4.97,3.54,5.69,31.14,9.13,67.13,9.63,98.8,1.49,3.99-1.02,5.06-6.16,1.84-8.73-26.03-20.77-50.52-51.98-65.81-85.29-1.73-3.77-7.04-3.84-8.95-.15Z"/>
		<path fill="currentColor" d="M99.93,161.69c-29.93-15.49-61.17-24.79-86.99-29.42-2.53-.45-4.97,1.08-5.69,3.54-9.13,31.14-9.63,67.13-1.49,98.8,1.02,3.99,6.16,5.06,8.73,1.84,20.77-26.03,51.98-50.52,85.29-65.81,3.77-1.73,3.84-7.04.15-8.95Z"/>
		<path fill="currentColor" d="M275.91,214.14c29.93,15.49,61.17,24.79,86.99,29.42,2.53.45,4.97-1.08,5.69-3.54,9.13-31.14,9.63-67.13,1.49-98.8-1.02-3.99-6.16-5.06-8.73-1.84-20.77,26.03-51.98,50.52-85.29,65.81-3.77,1.73-3.84,7.04-.15,8.95Z"/>
		<path fill="currentColor" d="M144.24,107.15c-10.22-32.12-25.72-60.78-40.71-82.32-1.47-2.11-4.27-2.75-6.53-1.52-28.48,15.56-54.28,40.66-70.91,68.81-2.09,3.54.78,7.93,4.87,7.47,33.09-3.72,72.48,1.03,106.85,13.77,3.89,1.44,7.69-2.27,6.43-6.22Z"/>
		<path fill="currentColor" d="M231.59,268.68c10.22,32.12,25.72,60.78,40.71,82.32,1.47,2.11,4.27,2.75,6.53,1.52,28.48-15.56,54.28-40.66,70.91-68.81,2.09-3.54-.78-7.93-4.87-7.47-33.09,3.72-72.48-1.03-106.85-13.77-3.89-1.44-7.69,2.27-6.43,6.22Z"/>
		<path fill="currentColor" d="M107.15,231.59c-32.12,10.22-60.78,25.72-82.32,40.71-2.11,1.47-2.75,4.27-1.52,6.53,15.56,28.48,40.66,54.28,68.81,70.91,3.54,2.09,7.93-.78,7.47-4.87-3.72-33.09,1.03-72.48,13.77-106.85,1.44-3.89-2.27-7.69-6.22-6.43Z"/>
		<path fill="currentColor" d="M268.68,144.24c32.12-10.22,60.78-25.72,82.32-40.71,2.11-1.47,2.75-4.27,1.52-6.53-15.56-28.48-40.66-54.28-68.81-70.91-3.54-2.09-7.93.78-7.47,4.87,3.72,33.09-1.03,72.48-13.77,106.85-1.44,3.89,2.27,7.69,6.22,6.43Z"/>
	</svg>
);

export default ClozdCircle;
