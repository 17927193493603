import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import googleAnalytics from '@utils/googleAnalytics';

export default function RouteChangeTracker() {
	const { pathname } = useLocation();
	useEffect(() => {
		googleAnalytics.trackCurrentPage(pathname);
	}, [pathname]);

	return <></>;
}
