import { types, getEnv, flow, getParent, getSnapshot, onSnapshot } from 'mobx-state-tree';
import { values } from 'mobx';
import React from 'react';
import { Dropdown, Menu, message } from 'antd';
import { Link } from 'react-router-dom';
import SVG from '@reusableComponents/SVG/svg';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import request from '@app/js/api/request';
import i18next from 'i18next';
import pollJob from '../utils/pollJob';
import googleAnalytics from '@utils/googleAnalytics';
import Filters, { DealCountsModel } from '@baseModels/filters.baseModel';
import DriverRatingModel from '@baseModels/driverRating.baseModel';
import CompetitorDecisionsModel from '@baseModels/competitorDecisions.baseModel';
import { isExpressionValid } from '@utils/filters';
import integrationSources from '@integrationSources/integrationSources.json';
import CompetitorBaseModel from '@baseModels/competitor.baseModel';
import { TagModel, CategoryModel } from '@baseModels/tag.baseModel';
import FlagBaseModel from '@baseModels/flag.baseModel';
import { RoleModel } from '@stores/organization.store';
import { rootStore } from '@stores';
import { startPerformance, stopPerformance } from '@utils/performance';
import FilterExpression from '@baseModels/filterExpression.baseModel';
import validator from 'validator';

const {
	model,
	string,
	maybeNull,
	maybe,
	array,
	optional,
	boolean,
	integer,
	map,
	number,
	enumeration,
	union,
} = types;

export const OrgEntity = model('OrgEntityStore', {
	domain: maybeNull(string),
	headcount: maybeNull(integer),
	industry: maybeNull(string),
	name: maybeNull(string),
	organization_entity_id: maybeNull(string),
	organization_id: maybeNull(string),
	region: maybeNull(string),
	revenue: maybeNull(union(string, integer)),
	segment: maybeNull(string),
});

// Driver Model
export const DriverModel = model('DriverStore', {
	driver_id: string,
	name: string,
	description: maybeNull(string),
	parent_id: maybeNull(string),
	global_driver_id: maybeNull(string),
	is_custom_driver: maybeNull(boolean),
	children: maybe(array(string)),
})
	.views((self) => ({
		get parent() { // This is the driver category to which the decision driver belongs
			const driverMap = getParent(self);
			const parent = driverMap.get(self.parent_id);
			return parent || {};
		},
		get display_name() {
			if (self.parent.name) {
				return `${self.parent.name}: ${self.name}`;
			}
			return self.name;
		},
	}));

// Global Driver Model
export const GlobalDriverModel = model('GlobalDriverStore', {
	global_driver_id: string,
	name: string,
	description: maybeNull(string),
	parent_id: maybeNull(string),
})
	.views((self) => ({
		get parent() { // This is the driver category to which the decision driver belongs
			const driverMap = getParent(self);
			const parent = driverMap.get(self.parent_id);
			return parent || {};
		},
		get display_name() {
			if (self.parent.name) {
				return `${self.parent.name}: ${self.name}`;
			}
			return self.name;
		},
	}));

export const SavedFilterModel = model('savedFilterModel', {
	isUserSubscribed: optional(boolean, false),
	isUserDefault: optional(boolean, false),
	isEdited: optional(boolean, false),
	filter_id: optional(string, ''),
	type: optional(string, 'personal'),
	role_id: maybeNull(string),
	configuration: optional(model('savedFilterModel', {
		name: optional(string, ''),
		filters: optional(Filters, {
			logicalOperator: 'and',
			expressions: [],
		}),
	}), {}),
});

// used for filtering field metadata (operators allowed to use, etc...)
export const FilterFieldsModel = model('FilterFieldsModel', {
	leftOperand: optional(string, ''),
	name: maybeNull(string, ''),
	operators: array(string),
	rightOperandOptions: array(model({
		label: optional(maybeNull(string), ''),
		title: optional(maybeNull(string), ''),
		value: optional(maybeNull(union(string, integer)), ''),
		translationKey: optional(maybeNull(string), ''),
	})),
	picklist_type: maybeNull(string),
	rightOperandType: optional(string, ''),
	type: optional(string, ''),
});

export const CompetitorModel = model('CompetitorModel', {
	competitor_id: string,
	organization_entity: OrgEntity,
	is_valid: boolean,
	created_at: maybeNull(string),
	updated_at: maybeNull(string),
});

export const ProgramQuickFilterModel = model('ProgramQuickFilterModel', {
	quick_filter_id: string,
	metadata_type: string,
	order: number,
});


const BusinessTypeBucketModel = model('BusinessTypeBucketModel', {
	label: optional(string, ''),
	win_label: optional(string, ''),
	loss_label: optional(string, ''),
	outcome_type_values: optional(array(string), []),
})
	.actions(self => ({
		setLabel(label) {
			self.label = label;
		},
		setWinLabel(win_label) {
			self.win_label = win_label;
		},
		setLossLabel(loss_label) {
			self.loss_label = loss_label;
		},
	}));

export const DivideBusinessSettingsModel = model('DivideBusinessSettingsModel', {
	new_business: optional(BusinessTypeBucketModel, {}),
	existing_business: optional(BusinessTypeBucketModel, {}),
})
	.views(self => ({
		get isEnabled() {
			return self.new_business?.outcome_type_values?.length > 0 || self.existing_business?.outcome_type_values?.length > 0;
		},
		get allMappedValues() {
			return [].concat(self.existing_business.outcome_type_values || [])
				.concat(self.new_business.outcome_type_values || []);
		},
		get divisions() {
			return {
				new_win: {
					outcome_type_values: self.new_business?.outcome_type_values.length > 0 ? self.new_business?.outcome_type_values : null,
					label: self.new_business?.win_label || i18next.t('program.configuration.divideBusiness.new_business.win_label'),
					general_outcome: 'win',
					internal_name: 'new_win',
					business_type: 'new_business',
				},
				new_loss: {
					outcome_type_values: self.new_business?.outcome_type_values.length > 0 ? self.new_business?.outcome_type_values : null,
					label: self.new_business?.loss_label || i18next.t('program.configuration.divideBusiness.new_business.loss_label'),
					general_outcome: 'loss',
					internal_name: 'new_loss',
					business_type: 'new_business',
				},
				existing_win: {
					outcome_type_values: self.existing_business?.outcome_type_values.length > 0 ? self.existing_business?.outcome_type_values : null,
					label: self.existing_business?.win_label || i18next.t('program.configuration.divideBusiness.existing_business.win_label'),
					general_outcome: 'win',
					internal_name: 'existing_win',
					business_type: 'existing_business',
				},
				existing_loss: {
					outcome_type_values: self.existing_business?.outcome_type_values.length > 0 ? self.existing_business?.outcome_type_values : null,
					label: self.existing_business?.loss_label || i18next.t('program.configuration.divideBusiness.existing_business.loss_label'),
					general_outcome: 'loss',
					internal_name: 'existing_loss',
					business_type: 'existing_business',
				},
				win: {
					outcome_type_values: self.allMappedValues,
					label: i18next.t('program.global.win'),
					general_outcome: 'win',
					internal_name: 'win',
					business_type: null,
				},
				loss: {
					outcome_type_values: self.allMappedValues,
					label: i18next.t('program.global.loss'),
					general_outcome: 'loss',
					internal_name: 'loss',
					business_type: null,
				},
				new_business: {
					outcome_type_values: self.new_business?.outcome_type_values.length > 0 ? self.new_business?.outcome_type_values : null,
					label: self.new_business?.label || i18next.t('program.configuration.divideBusiness.new_business.label'),
					general_outcome: null,
					internal_name: 'new_business',
					business_type: 'new_business',
				},
				existing_business: {
					outcome_type_values: self.existing_business?.outcome_type_values.length > 0 ? self.existing_business?.outcome_type_values : null,
					label: self.existing_business?.label || i18next.t('program.configuration.divideBusiness.existing_business.label'),
					general_outcome: null,
					internal_name: 'existing_business',
					business_type: 'existing_business',
				},
			};
		},
	}))
	.actions(self => ({
		getDivisionForDeal({ outcome, outcome_type }) {
			for (const divisionKey in self.divisions) {
				if (outcome === self.divisions[divisionKey].general_outcome && self.divisions[divisionKey].outcome_type_values?.includes(outcome_type)) {
					return self.divisions[divisionKey];
				}
			}
			return null;
		},
		getFiltersForDivisions(...divisions) {
			if (!self.isEnabled) {
				return null;
			}
			const allOutcomeTypes = divisions.flatMap(d => self.divisions[d].outcome_type_values).filter(value => value !== null);
			const allOutcomes = divisions.map(d => self.divisions[d].general_outcome).filter(value => value !== null);
			return {
				logicalOperator: 'and',
				expressions: [
					// filter by outcome
					...(allOutcomes.length > 0 ? [{
						name: '',
						type: 'clozd_outcome',
						leftOperand: 'deal.outcome',
						operator: 'in',
						rightOperand: allOutcomes,
					}] : []),
					// filter by outcome type
					...(allOutcomeTypes.length > 0 ? [{
						name: '',
						type: 'clozd_outcome_type',
						leftOperand: 'deal.outcome_type',
						operator: 'in',
						rightOperand: allOutcomeTypes,
					}] : []),
				],
			};
		},
	}));

const AutoPublishSettingsModel = model('AutoPublishSettingsModel', {
	enabled: optional(boolean, false),
	usersToNotify: optional(array(string), []),
});

// Program Model
export const ProgramStoreModel = model('ProgramStore', {
	programId: maybeNull(string),
	isLoading: optional(boolean, true),
	amountOptions: array(model({
		count: maybeNull(number),
		rightOperand: maybeNull(array(number)),
	})),
	attributes: optional(
		model('Attributes', {
			link_sharing: optional(boolean, false),
			send_interview_notifications: optional(boolean, false),
			deal_mappings: map(
				model('DealMappings', {
					maps_to: optional(string, ''),
				}),
				{},
			),
			notification_additional_metadata: array(
				model('InterviewFeedbackMetadata', {
					metadata_definition_id: maybeNull(string),
				}).actions((self) => ({
					setMetadataDefinitionId(metadata_definition_id) {
						self.metadata_definition_id = metadata_definition_id;
					},
				})),
			),
			displayName: optional(string, ''),
			divide_business_settings: optional(DivideBusinessSettingsModel, {}),
			auto_publish_settings: optional(model({
				premium: optional(AutoPublishSettingsModel, {}),
				scale: optional(AutoPublishSettingsModel, {}),
				premium_async: optional(AutoPublishSettingsModel, {}),
			}), {}),
		})
			.views((self) => ({
				get copyBusinessSettings() {
					return DivideBusinessSettingsModel.create(getSnapshot(self.divide_business_settings));
				},
			}))
			.actions((self) => ({
				addInterviewFeedbackMetadata() {
					self.notification_additional_metadata.push({});
				},
				removeInterviewFeedbackMetadata(index) {
					self.notification_additional_metadata.splice(index, 1);
				},
				setDivideBusinessSettings(settings) {
					self.divide_business_settings = DivideBusinessSettingsModel.create(settings);
				},
			})),
		{},
	),

	dealCounts: optional(DealCountsModel, {}), // accounts for filters
	totalDealCounts: optional(DealCountsModel, {}), // total in program, independent of current filters

	drivers: map(DriverModel),
	globalDrivers: array(GlobalDriverModel),
	isLoadingDrivers: optional(boolean, true),

	_driverRatings: map(DriverRatingModel),
	isLoadingDriverRatings: optional(boolean, false),

	competitors: array(CompetitorModel),
	isLoadingCompetitors: optional(boolean, false),

	_competitorDecisions: optional(CompetitorDecisionsModel, {}),
	isLoadingCompetitorDecisions: optional(boolean, false),
	currentCompetitor: optional(CompetitorBaseModel, {}),

	contentTagCategories: map(CategoryModel),
	contentTagTags: map(TagModel),

	flags: array(FlagBaseModel),

	filters: optional(Filters, {}), // filters that have been applied after 'apply filter' button has been clicked
	temporaryFilters: optional(Filters, {}), // used to store filters before they're applied, what is visually seen
	filterFields: array(FilterFieldsModel),
	isLoadingFilterFields: optional(boolean, false),
	isLoadingSavedFilters: optional(boolean, false),
	importIdToView: maybeNull(string),
	importActionToView: maybeNull(string),
	importNameViewing: maybeNull(string),
	savedFilters: array(SavedFilterModel),
	quickFilters: array(ProgramQuickFilterModel),
	filterApplied: optional(SavedFilterModel, {}),
	quickSavedFilterApplied: optional(SavedFilterModel, {}),
	metadata: array(
		model({
			can_update_display_order: boolean,
			can_update_is_required: boolean,
			display_name: maybeNull(string),
			display_order: integer,
			is_clozd_field: boolean,
			is_filterable: boolean,
			is_displayed: boolean,
			is_required: boolean,
			options: maybeNull(
				model({
					picklist_type: maybeNull(string),
					possible_values: maybeNull(array(string)),
					can_update_picklist: maybeNull(boolean),
					send_interview_notifications: maybeNull(boolean),
				}),
			),
			metadata_definition_id: string,
			name: string,
			sub_type: string,
			type: string,
			association: optional(enumeration('associations', ['deal', 'deal_participant']), 'deal'),
			hide_field_roles: array(string),
		}),
	),
	isLoadingMetadata: optional(boolean, false),
	shouldShowSearchDrawer: optional(boolean, false),
	showFilters: optional(boolean, false),
	showSideMenu: optional(boolean, true),
	showFooter: true,
	showConfigMenu: optional(boolean, false),
	showAlertBar: optional(boolean, false),
	// this keeps track of dealIds for the "next" & "previous" buttons on a deal page
	dealsTableIds: array(string),
	integratedSources: array(
		model('IntegratedSourceModel', {
			integration_id: optional(string, ''),
			source: optional(string, ''),
			is_valid: optional(boolean, false),
		}),
	),
	automations: array(
		model('AutomationModel', {
			automation_id: optional(string, ''),
			automation_type: optional(string, ''),
			automation_subtype: optional(string, ''),
			integration_id: maybeNull(string),
			workflow_id: maybeNull(string),
			name: optional(string, ''),
			status: optional(string, ''),
			created_at: optional(string, ''),
			updated_at: optional(string, ''),
		}),
	),
	filterId: maybeNull(string),
	roles: array(RoleModel),
	_dividedBusinessView: optional(enumeration(['all_business', 'new_business', 'existing_business']), 'all_business'),
	shouldShowAskClozdDrawer: optional(boolean, false),
	isLoadingAskClozd: optional(boolean, false),
	isResettingAskClozd: optional(boolean, false),
	loadingAskClozdPct: optional(number, 0),
	showAskClozdErrorState: optional(boolean, false),
	currentAskClozdThread: maybeNull(string),
})
	.views((self) => ({
		get organization() {
			return getEnv(self).OrganizationStore;
		},
		get organizationId() {
			return self.organization.organizationId;
		},
		get userStore() {
			return rootStore.UserStore;
		},
		get baseURL() {
			return `${self.organization.baseURL}/programs/${self.programId}`;
		},
		get competitorsMap() {
			const competitorsMap = {};
			self.competitors.forEach((c) => {
				competitorsMap[c.competitor_id] = c.organization_entity.name;
			});
			return competitorsMap;
		},
		get currentProgramDisplayName() {
			return self.program ? self.program.name : i18next.t('program.global.selectAProgram');
		},
		competitorOrgEntity(competitor_id) {
			return self.competitors.find((c) => c.competitor_id === competitor_id)?.organization_entity;
		},
		getDriver(driver_id) {
			const driver = self.drivers.get(driver_id);
			return driver || {};
		},
		get driversList() {
			return Array.from(self.drivers.values());
		},
		get shouldShowFilters() {
			return self.showFilters && _get(self.temporaryFilters, 'expressions.length', 0) > 0;
		},
		get hasFiltersApplied() {
			return _isEqual(getSnapshot(self.temporaryFilters), getSnapshot(self.filters));
		},
		get hasSavedFiltersApplied() {
			return _isEqual(self?.filterApplied?.configuration?.filters, self.filters);
		},
		get hasSavedFilters() {
			return self.savedFilters.length > 0;
		},
		get hasValidFilters() {
			return self.validateFilters(self.temporaryFilters);
		},
		validateFilters(expression) {
			// If the expression is a nested expression, call this function recursively
			// Expression is nested if it has both a logicalOperator and an array of expressions
			if (expression && expression.expressions && expression.expressions.length > 0 && expression.logicalOperator) {
				return expression.expressions.reduce((isValid, exp) => {
					return isValid && self.validateFilters(exp);
				}, true);
				// If the expression has a leftOperand and operator
			}
			return isExpressionValid(expression);
		},
		get IsApplyFiltersDisabled() {
			return self.hasFiltersApplied || !self.hasValidFilters;
		},
		// Filters out incomplete filters. This feeds into self.filterString which should be used on all api requests with filters
		get cleanedFilters() {
			return self.filters.cleaned;
		},
		get filtersString() {
			if (self.cleanedFilters.expressions.length > 0) {
				return JSON.stringify(self.cleanedFilters);
			}
			return '';
		},
		get emptyFilter() {
			return {
				filter_id: '',
				type: '',
				role_id: null,
				isUserDefault: false,
				isUserSubcribed: false,
				configuration: {
					name: 'New Filter',
					filters: {
						logicalOperator: 'and',
						expressions: [],
					},
				},
			};
		},
		get emptyFilterExpression() {
			return {
				logicalOperator: 'and',
				expressions: [{}],
			};
		},
		get emptyExpression() {
			return {
				leftOperand: undefined,
				name: '',
				operator: undefined,
				rightOperand: null,
				type: undefined,
			};
		},
		get defaultFilter() {
			const defaultFilter = self.savedFilters.find((savedFilter) => savedFilter?.isUserDefault);
			return defaultFilter ?? self.emptyFilter;
		},
		getQueryString(queryParam) {
			const query = new URLSearchParams(window.location.search);
			return query.get(queryParam) || '';
		},
		get driverRatings() {
			return Object.fromEntries(self._driverRatings);
		},
		getDriverRating(driver_id) {
			return self.driverRatings[driver_id] || {};
		},
		getFilterField(type) {
			return self.filterFields.find((field) => field.type === type);
		},
		get competitorDecisions() {
			const competitorDecisions = self._competitorDecisions;
			const competitors = _cloneDeep(self.competitors);
			const competitorDecisionsFinal = {};
			competitorDecisionsFinal.amount = competitorDecisions.amount;
			competitorDecisionsFinal.deals = competitorDecisions.deals;
			competitorDecisionsFinal.competitors = competitors;
			competitors.forEach((competitor) => {
				let competitorLabel = competitor.organization_entity.name;
				switch (competitorLabel) {
					case null:
					case '':
					case 'none':
						competitorLabel = i18next.t('program.global.competitorTypes.none');
						break;
					case 'other':
						competitorLabel = i18next.t('program.global.competitorTypes.other');
						break;
					case 'unknown':
						competitorLabel = i18next.t('program.global.competitorTypes.unknown');
						break;
					default:
						break;
				}

				competitor.name = competitorLabel;
				// some consumers are expecting label and id instead of name and competitor_id
				competitor.label = competitorLabel;
				competitor.id = competitor.competitor_id;

				const competitorDeals = competitorDecisions.competitors.find(
					(c) => c.competitor_id === competitor.competitor_id,
				);
				if (competitorDeals) {
					competitor.win = competitorDeals.win;
					competitor.loss = competitorDeals.loss;
					competitor.winRate = {
						deals: competitorDeals.win.deals / competitorDeals.deals,
						amount: competitorDeals.win.amount / competitorDeals.amount,
					};
					competitor.total = {
						deals: competitor.win.deals + competitor.loss.deals,
						amount: competitor.win.amount + competitor.loss.amount,
					};
					competitor.deals = competitor.total.deals;
					competitor.amount = competitor.total.amount;
					competitor.new_business = competitorDeals.new_business;
					competitor.existing_business = competitorDeals.existing_business;
					competitor.unmapped_deals = competitorDeals.unmapped_deals;
				} else {
					const noDeals = {
						deals: 0,
						amount: 0,
					};
					competitor.win = noDeals;
					competitor.loss = noDeals;
					competitor.winRate = noDeals;
					competitor.total = noDeals;
				}
			});
			competitorDecisionsFinal.competitors.sort((a, b) => b.deals - a.deals);
			return competitorDecisionsFinal;
		},
		get competitorDecisionsWithDeals() {
			return self.validCompetitorDecisions.filter((comp) => comp.deals > 0);
		},
		get validCompetitorDecisions() {
			return self.competitorDecisions.competitors.filter((comp) => comp.is_valid);
		},
		get program() {
			return _find(self.organization.programs, ['program_id', self.programId]) || '';
		},
		get programName() {
			return self.program?.name ?? '';
		},
		get sharedFilterId() {
			return self.getQueryString('filter-id');
		},
		get hasCRMIntegrated() {
			const avalibleCRMs = self.CRMs;
			for (let i = 0; i < self.integratedSources.length; i++) {
				if (avalibleCRMs.has(self.integratedSources[i].source) && self.integratedSources[i].is_valid) {
					return true;
				}
			}
			return false;
		},
		get firstValidCRM() {
			const avalibleCRMs = self.CRMs;
			for (let i = 0; i < self.integratedSources.length; i++) {
				if (avalibleCRMs.has(self.integratedSources[i].source) && self.integratedSources[i].is_valid) {
					if (self.automations.find((a) => a.integration_id === self.integratedSources[i].integration_id && a.status === 'active')) {
						return { ...self.integratedSources[i], ...integrationSources[self.integratedSources[i].source] };
					}
				}
			}
			return undefined;
		},
		get CRMs() {
			return new Set(
				Object.values(integrationSources)
					.filter((source) => source.is_crm)
					.map((source) => source.name),
			);
		},
		getMetadataByName(name) {
			return self.metadata.find((m) => m.name === name);
		},
		getFilteredMetadata(properties) {
			return self.metadata.filter((m) => {
				for (const property in properties) {
					const value = properties[property];
					if (m[property] !== value) {
						return false;
					}
				}
				return true;
			});
		},
		get displayedDealMetadata() {
			return self.metadata.filter((m) => m.association === 'deal' && m.display_order > 0).sort((a, b) => a.display_order - b.display_order);
		},
		get nonParticipantEmailFields() {
			return self.metadata.filter((field) => field.name !== 'clozd_participant_email' && field.sub_type === 'email');
		},
		get programCrumb() {
			// Add Programs list if company has multiple programs or is an admin.
			if (self.organization.programs?.length > 1) {
				return {
					component: (
						<Dropdown
							overlay={
								<Menu
									items={self.organization.programs.map((p) => ({
										key: p.program_id,
										label: p.status === 'disabled' && !self.userStore.isConsultant ? (
											<span
												onClick={() => message.error(i18next.t('organization.programs.unableToViewDisabled'))}
												style={{ display: 'block', width: '100%', color: '#90959B' }}
											>
												{p.name}
											</span>
										) : (
											<Link to={`/programs/${p.program_id}`} style={p.status === 'disabled' ? { color: '#90959B' } : {}}>{p.name}</Link>
										),
									}))}
								/>
							}
							trigger={['click']}
						>
							<div className="program-toggle">
								{self.currentProgramDisplayName}
								<SVG name="carat" />
							</div>
						</Dropdown>
					),
					order: 2,
				};
			}

			return {
				to: `/programs/${self.programId}`,
				text: self.currentProgramDisplayName,
				order: 2,
			};
		},
		categoryList(excludeSystemTags = false) {
			let categoryList = Array.from(self.contentTagCategories.values());
			if (excludeSystemTags) {
				categoryList = categoryList.filter(category => !category.is_system_tag);
			}

			categoryList.sort((a, b) => {
				if (a.is_system_tag) {
					return -1;
				}
				if (b.is_system_tag) {
					return 1;
				}
				return 0;
			});
			return categoryList;
		},
		get tagList() {
			const tagList = Array.from(self.contentTagTags.values());
			tagList.sort((a, b) => {
				if (a.is_system_tag) {
					return -1;
				}
				if (b.is_system_tag) {
					return 1;
				}
				return 0;
			});
			return tagList;
		},
		getTagByCompetitorId(competitorId) {
			const tagValues = values(self.contentTagTags);
			return tagValues.find((tag) => tag.competitor_id === competitorId);
		},
		get customQuickFilterOrder() {
			const fields = [];
			self.quickFilters.forEach(q => {
				const field = self.filterFields.find(f => q.metadata_type === f.type);
				if (field) {
					fields.push({
						type: field.type,
						order: q.order,
					});
				}
			});
			return fields;
		},
		get divideBusiness() {
			return self.divideBusinessSettings.isEnabled;
		},
		get divideBusinessSettings() {
			return self.attributes.divide_business_settings || null;
		},
		get dividedBusinessView() {
			return self.divideBusiness ? self._dividedBusinessView : null;
		},
		get reportTileLabelOverrides() {
			return {
				'whyWeWin': self.divideBusiness && i18next.t('program.configuration.divideBusiness.bucketDrivers', { bucket: self.divideBusinessSettings.divisions.new_win.label }),
				'whyWeLose': self.divideBusiness && i18next.t('program.configuration.divideBusiness.bucketDrivers', { bucket: self.divideBusinessSettings.divisions.new_loss.label }),
				'whyClientsRenew': self.divideBusiness && i18next.t('program.configuration.divideBusiness.bucketDrivers', { bucket: self.divideBusinessSettings.divisions.existing_win.label }),
				'whyClientsChurn': self.divideBusiness && i18next.t('program.configuration.divideBusiness.bucketDrivers', { bucket: self.divideBusinessSettings.divisions.existing_loss.label }),
			};
		},
		get askClozdFF() {
			return self.userStore.userHasOrgFeature('ask_clozd') && self.userStore.hasFeatureFlag('ask_clozd', ['consultancy', 'user']) && window.screen.width > 600;
		},
		get askClozdMinInterviews() {
			return 1;
		},
		get askClozdUsable() {
			return self.askClozdFF && self.totalDealCounts.interview_count >= self.askClozdMinInterviews;
		},
		getMetadataFieldNames(metadataFieldIds) {
			if (Array.isArray(metadataFieldIds)) {
				return self.metadata
					.filter(m => metadataFieldIds.includes(m.metadata_definition_id))
					.map(field => field.display_name || field.name);
			}
			return [];
		},
		getFlag(flagId) {
			return self.flags.find(f => f.flag_id === flagId);
		},
		hasProgramAccess(subResourceType = '*', subResourceId = '*', operation = 'view') {
			return self.userStore?.checkUserOrganizationAccess?.(operation, 'program', self.programId, subResourceType, subResourceId);
		},
		get filterActionKey() {
			return 'filter_change';
		},
	}))
	.volatile(() => ({
		disposer: null,
		loading: null,
		initAskClozdPoller: null,
	}))
	.actions((self) => ({
		afterCreate() {
			self._dividedBusinessView = localStorage.getItem('divided-business-view') || 'all_business';
			const disposer = onSnapshot(self.filters, () => {
				// onSnapshot should be used sparingly and avoided if possible, they should always be cleaned up to prevent memory leaks which is being done in beforeDestroy
				self.resetProgramData();
			});
			self.disposer = disposer;
		},
		beforeDestroy() {
			if (self.disposer) {
				self.disposer();
			}
			self.clearInitAskClozdPoller();
		},
		copyURLToClipboard: flow(function* copyURLToClipboard() {
			self.setIsCopyingURLToClipBoard(true);
			let url = window.location.href;
			try {
				// if we are sharing filters, create shared filter in filter table
				if (self.filtersString && !(url.includes(self.filterId) && self.filterId)) {
					const {
						data: {
							data: { filter_id },
						},
					} = yield request.post(`${self.baseURL}/shared-filters`, {
						filters: self.filters,
						isDefault: false,
						isSubscribed: false,
						role_id: null,
					});
					self.filterId = `?filter-id=${filter_id}`;
					url += self.filterId;
					window.history.pushState('', '', url);
				}
				navigator.clipboard.writeText(url);
				message.success(i18next.t('program.filter.urlCopiedToClipboard'));
			} catch (err) {
				message.error(
					self.t(`errors.${_get(err, 'response.data.errorCode', 'UNKNOWN001')}`, {
						error: _get(err, 'response.data.message', self.t('errors.UNKNOWN001')),
					}),
				);
				throw err;
			} finally {
				self.setIsCopyingURLToClipBoard(false);
			}
		}),
		applySharedFilter: flow(function* applySharedFilter(sharedFilterId) {
			try {
				const {
					data: {
						data: {
							configuration: { filters },
						},
					},
				} = yield request.get(`${self.baseURL}/shared-filters/${sharedFilterId}`);
				self.temporaryFilters = _cloneDeep(filters);
				self.applyFilters();
				self.setShowAlertBar(true);
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		setIsCopyingURLToClipBoard(isCopyingURLToClipBoard) {
			self.isCopyingURLToClipBoard = isCopyingURLToClipBoard;
		},
		setShowAlertBar(showAlertBar) {
			self.showAlertBar = showAlertBar;
		},
		resetProgramMetadata: flow(function* resetProgramMetadata() {
			// refetch program definition
			try {
				// Saving the Promise to a volatile property so that other stores have the ability to "await" these network requests
				self.loading = self.resetProgramStructure() 						// 1st fetch non-filterable program data
					.then(() => Promise.resolve(self.resetFilters(true, true)))		// 2nd reset filters
					.then(() => Promise.resolve(self.resetProgramData(false)));						// 3rd fetch filterable program data

				yield self.loading;
			} finally {
				self.isLoading = false;
			}
		}),
		resetProgramStructure: flow(function* resetProgramStructure() {
			const requiredProgramResources = [
				self.fetchDrivers(),
				self.fetchCompetitors(),
				self.fetchMetadata(),
				self.fetchFilterFields(),
				self.fetchSavedFilters(false),
				self.fetchAttributes(),
				self.fetchIntegratedSources(),
				self.fetchFlags(),
				self.fetchProgramRoles(),
				self.fetchQuickFilters(),
				self.fetchTotalDealCounts(), // no filters
			];

			// automations can only be queried by admins. Any failed queries prohibit access to the program
			if (self.userStore?.checkUserOrganizationAccess?.('modify', 'program', self.programId, '*', '*')) {
				requiredProgramResources.push(self.fetchAutomations());
			}

			yield Promise.all(requiredProgramResources);

			try {
				// fetchDealAmounts throws an AUTH005 whenever amount is a hidden field. We shouldn't prevent program load.
				const optionalProgramResources = [self.fetchDealAmounts()];
				yield Promise.all(optionalProgramResources);
			} catch (error) {
				// do nothing because they're optional
			}
			self.setDealsTableIds([]);
		}),
		resetProgramData: flow(function* resetProgramData(changeIsLoading = true) {
			try {
				yield Promise.all([
					self.fetchProgramDriverRatings(),
					self.fetchProgramCompetitorDecisions(),
					self.fetchDealCountsFiltered(), // with filters
					self.fetchContentTags(),
				]);
			} finally {
				if (changeIsLoading) {
					self.isLoading = false;
				}
				stopPerformance(self.filterActionKey, { programId: self.programId });
			}
		}),
		updateProgramId: flow(function* updateProgramId(programId) {
			const actionKey = 'program_load';
			startPerformance(actionKey);

			self.isLoading = true;
			self.programId = programId;
			self.importIdToView = null; // the import ID is used to help filter from the import page since import ID isn't supported with the actually filter functionality. This makes sure the ID and banner doesn't persist across programs.
			self.currentAskClozdThread = null;
			yield self.organization.fetchOrganizationIdByProgramId(programId);
			yield self.resetProgramMetadata();

			stopPerformance(actionKey, { programId });
		}),
		setImportVariables(importId, action, name) {
			self.importIdToView = importId;
			self.importActionToView = action;
			self.importNameViewing = name;
		},
		fetchAttributes: flow(function* fetchAttributes() {
			const {
				data: {
					data: { attributes },
				},
			} = yield request.get(`${self.baseURL}/attributes`);
			self.attributes = attributes ?? {};
		}),
		updateIsLoadingDrivers(isLoadingDrivers) {
			self.isLoadingDrivers = isLoadingDrivers;
		},
		updateDrivers(drivers) {
			self.drivers = drivers;
		},
		updateGlobalDrivers(globalDrivers) {
			self.globalDrivers = globalDrivers;
		},
		fetchDealAmounts: flow(function* fetchDealAmounts() {
			const {
				data: {
					data: { dealSizes },
				},
			} = yield request.get(`${self.baseURL}/deal-amounts`);
			self.amountOptions = dealSizes;
		}),
		fetchDealCounts: flow(function* fetchDealCounts(filters = null) {
			const {
				data: { data },
			} = yield request.get(`${self.baseURL}/deal-counts`, {
				params: {
					...(filters && { filters }),
				},
			});
			return data;
		}),
		fetchDealCountsFiltered: flow(function* fetchDealCountsFiltered() {
			const data = yield self.fetchDealCounts(self.filtersString);
			self.dealCounts = data;
		}),
		fetchTotalDealCounts: flow(function* fetchTotalDealCounts() {
			const data = yield self.fetchDealCounts();
			self.totalDealCounts = data;
		}),
		fetchDrivers: flow(function* fetchDrivers(shouldShowLoading = true) {
			try {
				if (shouldShowLoading) {
					self.updateIsLoadingDrivers(true);
				}
				const driversResponse = yield request.get(`${self.baseURL}/drivers`);
				const driversMap = {};
				if (driversResponse.data.data.values) {
					driversResponse.data.data.values.forEach((driver) => {
						driversMap[driver.driver_id] = {
							...(driversMap[driver.driver_id] || {}),
							...driver,
						};
						if (driver.parent_id) {
							if (driversMap[driver.parent_id]) {
								if (driversMap[driver.parent_id].children) {
									driversMap[driver.parent_id].children.push(driver.driver_id);
								} else {
									driversMap[driver.parent_id].children = [driver.driver_id];
								}
							} else {
								driversMap[driver.parent_id] = {
									children: [driver.driver_id],
								};
							}
						}
					});
				}
				self.updateDrivers(driversMap);

				const globalDriversResponse = yield request.get(`${self.baseURL}/drivers/global-drivers?include_none=true`);

				self.updateGlobalDrivers(globalDriversResponse.data.globalDrivers);

				self.updateIsLoadingDrivers(false);
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		updateIsLoadingCompetitors(isLoadingCompetitors) {
			self.isLoadingCompetitors = isLoadingCompetitors;
		},
		updateCompetitors(competitors) {
			self.competitors = competitors;
		},
		fetchCompetitors: flow(function* fetchCompetitors(shouldLoadCompetitors = true) {
			try {
				if (shouldLoadCompetitors) {
					self.updateIsLoadingCompetitors(true);
				}
				const competitorsResponse = yield request.get(`${self.baseURL}/competitors`);
				if (competitorsResponse.data.data.values) {
					self.updateCompetitors(competitorsResponse.data.data.values);
				}
				self.updateIsLoadingCompetitors(false);
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		setCurrentCompetitor(competitor) {
			self.currentCompetitor = competitor;
		},
		mapDealsUpload: flow(function* mapDealsUpload(file, mappings, importObj) {
			try {
				const importJob = yield request.post(`${self.baseURL}/mapDealsUpload`, { file, mappings, importObj });
				const completed = yield pollJob(importJob.data);
				return completed.data.attributes.summary;
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		updateIsLoadingMetadata(isLoadingMetadata) {
			self.isLoadingMetadata = isLoadingMetadata;
		},
		setMetadata(metadata) {
			self.metadata = metadata;
		},
		fetchProgramRoles: flow(function* fetchProgramRoles() {
			try {
				const res = yield request.get(`${self.baseURL}/roles`);
				self.roles = res.data.data;
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		fetchMetadata: flow(function* fetchMetadata() {
			try {
				self.updateIsLoadingMetadata(true);
				const metadataResponse = yield request.get(`${self.baseURL}/metadata?include_hide_field_roles=true`);
				if (metadataResponse.data.data) {
					metadataResponse.data.data.forEach((field) => {
						if (field.is_clozd_field && !field.display_name) {
							// clozd metadata field
							// add display names to clozd metadata
							field.display_name = i18next.t(`program.filter.types.${field.name}`);
						}
					});
					self.setMetadata(metadataResponse.data.data);
				}
				self.updateIsLoadingMetadata(false);
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		insertMetadata: flow(function* insertMetadata(metadataDefinition) {
			try {
				const response = yield request.post(`${self.baseURL}/metadata`, { metadata_definition: metadataDefinition });
				const newMetadata = response.data.data;
				self.metadata = [...self.metadata, newMetadata];
				return newMetadata;
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		updateMetadata: flow(function* updateMetadata(metadataDefinition) {
			try {
				const response = yield request.put(`${self.baseURL}/metadata`, { metadata_definition: metadataDefinition });
				const updatedMetadata = response.data.data;
				for (let i = 0; i < self.metadata.length; i++) {
					if (metadataDefinition.metadata_definition_id === self.metadata[i].metadata_definition_id) {
						self.metadata[i] = updatedMetadata;
					}
				}
				return updatedMetadata;
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		deleteMetadata: flow(function* deleteMetadata(metadataDefinitionId) {
			try {
				const response = yield request.delete(`${self.baseURL}/metadata/${metadataDefinitionId}`);
				return response;
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		// filterable actions
		fetchDeals: flow(function* fetchDeals(
			tableOptions,
			outcome,
			shouldIncludeDealsWithoutFeedback = false,
			competitorId,
			includeNullAmounts = true,
			excludeUnpublishedResponses = false,
			includeFlags = false,
			includeFavorite,
			attributeFilter = '',
			dividedOutcomes,
		) {
			const queryParams = {
				filters: self.filtersString,
				competitorId,
				outcome,
				dividedOutcomes,
				importId: self.importIdToView,
				importAction: self.importActionToView,
			};
			const { data } = yield request.get(`${self.baseURL}/deals`, {
				params: {
					...queryParams,
					page: tableOptions.pagination.current,
					pageSize: tableOptions.pagination.pageSize,
					sortField: tableOptions.sorter.field,
					sortOrder: tableOptions.sorter.order,
					searchTerm: tableOptions.searchTerm,
					shouldIncludeDealsWithoutFeedback,
					// by default we will include deals w/ null amounts
					includeNullAmounts,
					excludeUnpublishedResponses,
					include_flags: includeFlags,
					include_favorite: includeFavorite,
					attributeFilter,
				},
			});
			// create array of deal_ids for "next" & "previous" buttons on deal page
			if (data.data.rows.length) {
				self.setDealsTableIds(data.data.rows.map((d) => d.deal_id));
			}
			return data.data; // includes properties rows (with the deals) and count
		}),
		exportDeals: flow(function* exportDeals(selectedDeals, tableOptions = {}, attributeFilter = '', exportType, filename, globalFilterOverrides = null) {
			try {
				const job = yield request.get(`${self.baseURL}/deals/export`, {
					params: {
						filters: globalFilterOverrides ? JSON.stringify(globalFilterOverrides) : self.filtersString,
						selectedDeals: selectedDeals,
						sortField: tableOptions?.sorter?.field,
						sortOrder: tableOptions?.sorter?.order,
						searchTerm: tableOptions?.searchTerm,
						attributeFilter,
						exportType,
					},
				});
				message.info(i18next.t('organization.exportStarted'));

				const job_id = job.data;
				yield pollJob(job_id);
				window.location.replace(`/api/jobs/${job_id}/export?filename=${filename}`);
				message.success(i18next.t('organization.exportSucceeded'));
			} catch (err) {
				console.error(err);
				message.error(i18next.t('errors.EXPORT001'));
				throw err;
			}
		}),
		exportDriverData: flow(function* exportDriverData(outcomeFilter, ratingFilter = null, singleDriver = null, driverCategory = null) {
			try {
				const job = yield request.get(`${self.baseURL}/drivers/export`, {
					params: {
						filters: self.filtersString,
						outcomeFilter,
						ratingFilter,
						singleDriver,
						driverCategory,
					},
				});
				message.info(i18next.t('organization.exportStarted'));
				googleAnalytics.recordEvent('CRUD', 'Clicked Export Decision Driver Data Button', 'Decision Drivers');

				const job_id = job.data;
				yield pollJob(job_id);
				window.location.replace(`/api/jobs/${job_id}/export?filename=${self.programName}-driver-data.csv`);
				message.success(i18next.t('organization.exportSucceeded'));
			} catch (err) {
				message.error(i18next.t('errors.EXPORT001'));
				throw err;
			}
		}),
		exportTaggedQuotes: flow(function* exportTaggedQuotes(selectedTags) {
			try {
				const job = yield request.get(`${self.baseURL}/tags/export`, {
					params: {
						filters: self.filtersString,
						selectedTags: selectedTags,
					},
				});
				message.info(i18next.t('organization.exportStarted'));
				googleAnalytics.recordEvent('CRUD', 'Clicked Export Tagged Quotes Button', 'Tags');

				const job_id = job.data;
				yield pollJob(job_id);
				window.location.replace(`/api/jobs/${job_id}/export?filename=${self.programName}-tagged-quotes.csv`);
				message.success(i18next.t('organization.exportSucceeded'));
			} catch (err) {
				message.error(i18next.t('errors.EXPORT001'));
				throw err;
			}
		}),
		deleteDeal: flow(function* deleteDeal(dealId) {
			try {
				yield request.delete(`${self.baseURL}/deals/${dealId}`);
				self.resetProgramData(); // refresh data to be accurate
				const dealCount = typeof dealId === 'object' ? dealId.length : 1;
				message.success(i18next.t('program.global.deleteSuccess', { count: dealCount }));
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		fetchParticipants: flow(function* fetchParticipants(tableOptions, shouldUseFilters = true) {
			try {
				const queryParams = {};
				if (shouldUseFilters) {
					queryParams.filters = self.filtersString;
				}
				const { data } = yield request.get(
					`${self.baseURL}/participants`,
					{
						params: {
							...queryParams,
							page: tableOptions.pagination.current,
							pageSize: tableOptions.pagination.pageSize,
							sortField: tableOptions.sorter.field,
							sortOrder: tableOptions.sorter.order,
							searchTerm: tableOptions.searchTerm,
							shouldUseFilters,
						},
					},
				);
				return data.data;
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		fetchProgramDriverRatings: flow(function* fetchProgramDriverRatings() {
			try {
				self.isLoadingDriverRatings = true;
				self._driverRatings = {};
				const response = yield self.fetchDriverRatings({ filters: self.filtersString });
				self._driverRatings = response.data.data.values;
				self.isLoadingDriverRatings = false;
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		fetchDriverRatings: flow(function* fetchDriverRatings(queryParams = {}) {
			try {
				return yield request.get(`${self.baseURL}/driver-ratings`, { params: queryParams });
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		fetchDriverQuotes: flow(function* fetchDriverQuotes(queryParams = {}) {
			try {
				return yield request.get(`${self.baseURL}/drivers/quotes`, { params: queryParams });
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		fetchProgramCompetitorDecisions: flow(function* fetchProgramCompetitorDecisions() {
			try {
				self.isLoadingCompetitorDecisions = true;
				self._competitorDecisions = {};
				const filters = _cloneDeep(self.filters.cleaned);
				let dividedBusinessFilter;
				if (self.divideBusiness) {
					if (self.dividedBusinessView === 'new_business') {
						dividedBusinessFilter = self.divideBusinessSettings.getFiltersForDivisions('new_business');
					}
					if (self.dividedBusinessView === 'existing_business') {
						dividedBusinessFilter = self.divideBusinessSettings.getFiltersForDivisions('existing_business');
					}
					if (dividedBusinessFilter) {
						filters.expressions.push(dividedBusinessFilter);
					}
				}
				const { data } = yield self.fetchCompetitorDecisions({ filters: JSON.stringify(filters) });
				self._competitorDecisions = data.data.values;
				self.isLoadingCompetitorDecisions = false;
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		fetchCompetitorDecisions: flow(function* fetchCompetitorDecisions(queryParams = {}) {
			try {
				return yield request.get(`${self.baseURL}/competitor-decisions`, { params: queryParams });
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		fetchWinRateData: flow(function* fetchWinRateData(queryParams = {}) {
			try {
				return yield request.get(`${self.baseURL}/win-rates`, { params: queryParams });
			} catch (err) {
				console.error(err);
				throw err;
			}
		}),
		fetchFeedbackTotalData: flow(function* fetchFeedbackTotalData(queryParams = {}) {
			return yield request.get(`${self.baseURL}/feedback-counts`, {
				params: queryParams,
			});
		}),
		fetchFeedbackOutcomes: flow(function* fetchFeedbackOutcomes(queryParams = {}) {
			return yield request.get(`${self.baseURL}/feedback-outcomes`, {
				params: queryParams,
			});
		}),
		updateIsLoadingFilterFields(isLoadingFilterFields) {
			self.isLoadingFilterFields = isLoadingFilterFields;
		},
		updateFilterFields(filterFields) {
			self.filterFields = filterFields;
		},
		fetchFilterFields: flow(function* fetchFilterFields() {
			try {
				self.updateIsLoadingFilterFields(true);
				const filterFieldsResponse = yield request.get(`${self.baseURL}/filters`);
				self.updateFilterFields(filterFieldsResponse.data.data);
				self.updateIsLoadingFilterFields(false);
			} catch (err) {
				console.error(err);
			}
		}),
		addFilter(index) {
			const expression = FilterExpression.create();
			self.temporaryFilters.expressions.splice(index + 1, 0, expression);
		},
		setFilterExpressionType(index, type, leftOperand) {
			if (self.temporaryFilters.expressions[index]) {
				self.temporaryFilters.expressions[index] = { type, leftOperand };
			}
		},
		updateFilterExpression(index, expression) {
			self.temporaryFilters.expressions[index] = _cloneDeep(expression);
		},
		removeFilter(index, addEmptyFilter = true) {
			self.temporaryFilters.expressions.splice(index, 1);
			if (self.temporaryFilters?.expressions?.length === 0 && addEmptyFilter) {
				self.changeTemporaryFilters(self.emptyFilterExpression);
			}
		},
		resetFilters(useDefaultFilters, shouldApplySharedFilter = false) {
			// defaultFilters will first check to see if any defaults are available. If not, it uses empty filters
			const filter = useDefaultFilters ? self.defaultFilter : self.emptyFilter;
			if (self.sharedFilterId && shouldApplySharedFilter) {
				self.applySharedFilter(self.sharedFilterId);
				self.setShowAlertBar(true);
				return;
			}
			self.setFilterApplied(filter);
			self.setQuickSavedFilterApplied(filter);
			self.changeFilters(filter.configuration.filters);
			if (self.showAlertBar) {
				self.setShowAlertBar(false);
			}
			self.removeFilterIdFromQuery();
		},
		changeFilters(filters) {
			startPerformance(self.filterActionKey);
			const clonedFilters = _cloneDeep(filters);
			if (!_isEqual(getSnapshot(self.filters), clonedFilters)) {
				self.temporaryFilters = clonedFilters;
				self.filters = clonedFilters;
			}
		},
		changeTemporaryFilters(filters) {
			const clonedFilters = _cloneDeep(filters);
			self.temporaryFilters = clonedFilters;
		},
		setIsEditedFilter(id) {
			const filter = self.savedFilters.find((savedFilter) => savedFilter.filter_id === id);
			if (filter) {
				filter.isEdited = true;
			}
		},
		applyFilters() {
			startPerformance(self.filterActionKey);
			if (self.showAlertBar) {
				self.setShowAlertBar(false);
			}
			self.removeFilterIdFromQuery();
			self.filters = _cloneDeep(self.temporaryFilters);
		},
		removeFilterIdFromQuery() {
			let url = window.location.href;
			if (url.includes('?filter-id')) {
				const index = url.indexOf('?filter-id');
				url = url.slice(0, index);
				window.history.pushState('', '', url);
			}
		},
		setFilterApplied(filterToApply) {
			self.filterApplied = _cloneDeep(filterToApply);
		},
		setQuickSavedFilterApplied(quickSavedFilterToApply) {
			self.quickSavedFilterApplied = _cloneDeep(quickSavedFilterToApply);
		},
		recordFiltersInGA(pageName) {
			const action = 'Filter Applied - ';
			// FIX FOR NESTED FILTERS
			googleAnalytics.recordEvent('Filters', action, pageName);
		},
		fetchSavedFilters: flow(function* fetchSavedFilters(force = false) {
			try {
				if (self.isLoadingSavedFilters && !force) {
					return;
				}
				self.isLoadingSavedFilters = true;
				const { data } = yield request.get(`${self.baseURL}/saved-filters`);
				self.savedFilters = data.data;
			} catch (err) {
				console.error(err);
			} finally {
				self.isLoadingSavedFilters = false;
			}
		}),
		fetchQuickFilters: flow(function* fetchQuickFilters() {
			try {
				const { data } = yield request.get(`${self.baseURL}/quick-filters`);
				self.quickFilters = data.data;
			} catch (e) {
				console.error(e);
			}
		}),
		setShowFilters(showFilters) {
			self.showFilters = showFilters;

			if (self?.temporaryFilters?.expressions.length === 0) {
				self.addFilter(0);
			}

			if (!showFilters) {
				self.temporaryFilters = _cloneDeep(self.filters);
			}
		},
		setIsLoadingSavedFilters(isLoadingSavedFilters) {
			self.isLoadingSavedFilters = isLoadingSavedFilters;
		},
		setShouldShowSearchDrawer(shouldShowSearchDrawer) {
			self.shouldShowSearchDrawer = shouldShowSearchDrawer;
		},
		// Adds deals that are commonly mapped to the program attributes
		setDealMappings: flow(function* setDealMapping(deal_mappings) {
			yield request.put(`${self.baseURL}/attributes`, {
				deal_mappings: {
					...Object.fromEntries([...self.attributes.deal_mappings]),
					...deal_mappings,
				},
			});
			self.fetchAttributes();
		}),
		// Remove a mapping from the program mapping attributes.
		removeDealMapping(mapping) {
			self.attributes.deal_mappings.delete(mapping);
		},
		// Enables or disables auto-publishing
		setAutoPublishSettings: flow(function* setAutoPublishSettings(auto_publish_settings) {
			yield request.put(`${self.baseURL}/attributes`, {
				auto_publish_settings: {
					...self.attributes.auto_publish_settings,
					...auto_publish_settings,
				},
			});
			self.fetchAttributes();
		}),
		// Enables or disables the sharing of links
		setLinkSharing: flow(function* setLinkSharing(link_sharing) {
			if (!self.attributes.link_sharing === link_sharing) {
				yield request.put(`${self.baseURL}/attributes`, { link_sharing });
				message.success(
					link_sharing
						? i18next.t('program.configuration.linkSharingTurnedOn')
						: i18next.t('program.configuration.linkSharingTurnedOff'),
				);
				self.fetchAttributes();
			}
		}),
		// Enables or disables the sharing of links
		setInterviewNotifications: flow(function* setInterviewNotifications(send_interview_notifications) {
			if (!self.attributes.send_interview_notifications === send_interview_notifications) {
				try {
					yield request.put(`${self.baseURL}/attributes`, { send_interview_notifications });
					message.success(
						send_interview_notifications
							? i18next.t('program.configuration.interviewNotificationsTurnedOn')
							: i18next.t('program.configuration.interviewNotificationsTurnedOff'),
					);
				} catch (e) {
					message.error(e.response?.data.message || i18next.t('errors.UNKNOWN001'));
				}
				self.fetchAttributes();
			}
		}),
		// Adds Interview Feedback Metadata
		setNotificationAdditionalMetadata: flow(function* setNotificationAdditionalMetadata() {
			try {
				yield request.put(`${self.baseURL}/attributes`, { notification_additional_metadata: self.attributes.notification_additional_metadata });
				message.success(i18next.t('program.configuration.notificationAdditionalMetadataSuccess'));
			} catch (e) {
				message.error(e.response?.data.message || i18next.t('errors.UNKNOWN001'));
			}
			self.fetchAttributes();
		}),
		setDivideBusinessSettings: flow(function* setDivideBusinessSettings(divide_business_settings) {
			try {
				self.attributes.setDivideBusinessSettings(getSnapshot(divide_business_settings));
				yield request.put(`${self.baseURL}/attributes`, { divide_business_settings: divide_business_settings });
				message.success(i18next.t('program.configuration.divideBusinessSuccess'));
			} catch (e) {
				const errorCode = _get(e, 'response.data.errorCode', 'UNKNOWN001');
				message.error(i18next.t(`errors.${errorCode}`));
			}
			self.fetchAttributes();
		}),
		setDividedBusinessView(view) {
			self._dividedBusinessView = view;
			localStorage.setItem('divided-business-view', view);
			self.fetchProgramCompetitorDecisions();
		},
		setShowSideMenu(showSideMenu) {
			self.showSideMenu = showSideMenu;
		},
		setShowFooter(showFooter) {
			self.showFooter = showFooter;
		},
		setShowConfigMenu(show) {
			self.showConfigMenu = show;
		},
		setDealsTableIds(dealsTableIds) {
			self.dealsTableIds = dealsTableIds;
		},
		setIntegratedSources(integratedSources) {
			self.integratedSources = integratedSources;
		},
		setShouldShowAskClozdDrawer(shouldShowAskClozdDrawer) {
			self.shouldShowAskClozdDrawer = shouldShowAskClozdDrawer;
		},
		fetchIntegratedSources: flow(function* fetchIntegratedSources() {
			const results = yield request.get(`${self.baseURL}/integrations`);
			self.setIntegratedSources(results.data.data);
		}),
		fetchFeedbackTotals: flow(function* fetchFeedbackTotals() {
			return yield self.fetchFeedbackTotalData({
				filters: self.filtersString,
			});
		}),
		setContentTagCategories(categories) {
			self.contentTagCategories = categories;
		},
		setContentTagTags(tags) {
			self.contentTagTags = tags;
		},
		processContentTags(tags) {
			if (!tags || !Array.isArray(tags)) {
				return;
			}
			const categoryMap = {};
			const tagMap = {};
			const autoTaggingEnabled = self.userStore.hasFeatureFlag('auto_tagging');
			tags.forEach(tag => {
				// don't show competitor tags if ff isn't enabled
				if (!autoTaggingEnabled && tag.is_system_tag) {
					return;
				}

				if (tag.parent_id) {
					tagMap[tag.tag_id] = tag;
				} else {
					categoryMap[tag.tag_id] = tag;
					categoryMap[tag.tag_id].tags = [];
				}
			});

			for (const tag_id in tagMap) {
				const tag = tagMap[tag_id];
				categoryMap?.[tag.parent_id]?.tags.push(tag);
			}

			self.setContentTagCategories(categoryMap);
			self.setContentTagTags(tagMap);
		},
		fetchContentTags: flow(function* fetchContentTags(queryParams = {}) {
			const tagsResponse = yield request.get(`${self.baseURL}/tags`, {
				params: {
					filters: self.filtersString,
					...queryParams,
				},
			});
			self.processContentTags(tagsResponse.data.data.tags);
		}),
		createCategory: flow(function* createCategory(name, color) {
			yield request.post(`${self.baseURL}/tags`, {
				name,
				color,
			});
			yield self.fetchContentTags();
		}),
		updateCategory: flow(function* updateCategory(name, color, tag_id) {
			yield request.put(`${self.baseURL}/tags/${tag_id}`, {
				name,
				color,
			});
			yield self.fetchContentTags();
		}),
		convertTagKeywords(keywords, tagName) {
			let keywordList = [];
			if (typeof keywords === 'string') {
				keywordList = keywords?.split(/\s*,\s*/);
			}
			const uniqueKeywords = new Set(keywordList);
			uniqueKeywords.add(tagName);
			return [...uniqueKeywords].filter(value => !!value);
		},
		createTag: flow(function* createTag(name, parent_id, tag_type, autotag_keywords, shouldRetroTag) {
			const cleaned_keywords = tag_type === 'auto' ? self.convertTagKeywords(autotag_keywords, name) : [];
			const response = yield request.post(`${self.baseURL}/tags`, {
				name: name,
				parent_id: parent_id,
				tag_type: tag_type,
				autotag_keywords: cleaned_keywords,
				shouldRetroTag,
			});
			yield self.fetchContentTags();
			return response.data.data;
		}),
		updateTag: flow(function* updateTag(name, parent_id, tag_id, tag_type, autotag_keywords, shouldRetroTag) {
			const cleaned_keywords = tag_type === 'auto' ? self.convertTagKeywords(autotag_keywords, name) : [];
			const response = yield request.put(`${self.baseURL}/tags/${tag_id}`, {
				name: name,
				parent_id: parent_id,
				tag_type: tag_type,
				autotag_keywords: cleaned_keywords,
				shouldRetroTag,
			});
			yield self.fetchContentTags();
			return response.data.data;
		}),
		deleteTag: flow(function* deleteTag(tag_id) {
			yield request.delete(`${self.baseURL}/tags/${tag_id}`);
			yield self.fetchContentTags();
		}),
		setFlags(flags) {
			self.flags = flags;
		},
		fetchFlags: flow(function* fetchFlags() {
			const response = yield request.get(`${self.baseURL}/flags`);
			self.setFlags(response.data.data.flags);
		}),
		addFavorite: flow(function* addFavorite(dealId) {
			return yield request.put(`${self.baseURL}/deals/${dealId}/favorite`);
		}),
		removeFavorite: flow(function* removeFavorite(dealId) {
			return yield request.delete(`${self.baseURL}/deals/${dealId}/favorite`);
		}),
		fetchFavoriters: flow(function* fetchFavoriters(dealId) {
			return yield request.get(`${self.baseURL}/deals/${dealId}/favorite/users`);
		}),
		setAutomations(automations) {
			self.automations = automations;
		},
		fetchAutomations: flow(function* fetchAutomations() {
			const { data } = yield request.get(`${self.baseURL}/automations`);
			self.setAutomations(data?.data);
		}),
		// Antd Form validators
		emailValidator(_, value) {
			if (value && !validator.isEmail(value)) {
				return Promise.reject(new Error(i18next.t('program.configuration.sequences.invalidEmailFormat')));
			}
			return Promise.resolve();
		},
		phoneValidator(_, value) {
			if (value && (!validator.isMobilePhone(value))) {
				return Promise.reject(new Error(i18next.t('program.configuration.sequences.invalidPhoneNumberFormat')));
			}
			return Promise.resolve();
		},
		initializeAskClozd: flow(function* initializeAskClozd({ new_assistant = false, new_thread = false } = {}) {
			try {
				if (new_assistant) {
					self.setIsResettingAskClozd(true);
				}
				self.setIsLoadingAskClozd(true);
				yield request.post(`${self.baseURL}/ask-clozd/initialize?new_assistant=${new_assistant}&new_thread=${new_thread}`);
				self.activateInitAskClozdPoll(new_assistant === true);
			} catch (error) {
				self.initAskClozdCleanUp();
			}
		}),
		initAskClozdCleanUp({ success = false } = {}) {
			if (self.initAskClozdPoller) {
				self.clearInitAskClozdPoller();
			}
			self.setLoadingAskClozdPct(0);
			self.setIsLoadingAskClozd(false);
			self.setIsResettingAskClozd(false);
			if (success === true) {
				self.setShowAskClozdErrorState(false);
			} else {
				self.setCurrentAskClozdThread(null);
				self.setShowAskClozdErrorState(true);
			}
		},
		activateInitAskClozdPoll(new_assistant = false) {
			self.clearInitAskClozdPoller();
			self.initAskClozdPoller = setInterval(() => {
				self.pollInitAskClozd(new_assistant === true);
			}, 1000);
		},
		pollInitAskClozd: flow(function* pollInitAskClozd(new_assistant = false) {
			try {
				const res = yield request.get(`${self.baseURL}/ask-clozd/initialize-status`);
				const { status, pct, thread_id } = res.data.data.askClozdInitStatus;
				self.setLoadingAskClozdPct(pct);
				self.setCurrentAskClozdThread(thread_id);
				if (['complete'].includes(status)) {
					yield new Promise(resolve => setTimeout(resolve, 2000)); // delay for animation to complete
					self.initAskClozdCleanUp({ success: true });
				} else if (['created', 'in_progress'].includes(status)) {
					if (new_assistant) {
						self.setIsResettingAskClozd(true);
					}
					self.setIsLoadingAskClozd(true);
				} else if (['failed'].includes(status)) {
					self.initAskClozdCleanUp();
				}
			} catch (error) {
				self.initAskClozdCleanUp();
			}
		}),
		clearInitAskClozdPoller() {
			clearInterval(self.initAskClozdPoller);
		},
		setIsLoadingAskClozd(isLoadingAskClozd) {
			self.isLoadingAskClozd = isLoadingAskClozd;
		},
		setIsResettingAskClozd(isResettingAskClozd) {
			self.isResettingAskClozd = isResettingAskClozd;
		},
		setLoadingAskClozdPct(loadingAskClozdPct) {
			self.loadingAskClozdPct = loadingAskClozdPct;
		},
		setShowAskClozdErrorState(showAskClozdErrorState) {
			self.showAskClozdErrorState = showAskClozdErrorState;
		},
		setCurrentAskClozdThread(currentAskClozdThread) {
			self.currentAskClozdThread = currentAskClozdThread;
		},
		awaitJobCompletion: flow(function* awaitJobCompletion(jobId, fromJobscheduler, callback) {
			const completedJobData = yield pollJob(jobId, fromJobscheduler);
			callback(completedJobData);
		}),
	}));
