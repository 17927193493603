import { types } from 'mobx-state-tree';
const {
	model,
	string,
	maybeNull,
	optional,
	number,
	array,
} = types;

import DealAmount from './dealAmount.baseModel';

const CompetitorDecisionsModel = model({
	amount: maybeNull(number),
	competitors: array(model({
		competitor_id: maybeNull(string),
		deals: maybeNull(number),
		amount: maybeNull(number),

		win: optional(DealAmount, {}),
		loss: optional(DealAmount, {}),
		new_business: optional(model({
			win: optional(DealAmount, {}),
			loss: optional(DealAmount, {}),
		}), {}),
		existing_business: optional(model({
			win: optional(DealAmount, {}),
			loss: optional(DealAmount, {}),
		}), {}),
		unmapped_deals: optional(DealAmount, {}),
		decisions: optional(model({
			'won': optional(DealAmount, {}),
			'lost - unknown': optional(DealAmount, {}),
			'lost to competitor': optional(DealAmount, {}),
			'lost to no decision': optional(DealAmount, {}),
		}), {}),
	})),
	deals: maybeNull(number),
});

export default CompetitorDecisionsModel;
