

import React from 'react';

const EyeVisible = (props) => (
	<svg {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M19.6028 9.42546C17.4867 4.96787 14.2881 2.72456 10.0001 2.72456C5.70995 2.72456 2.51352 4.96787 0.39745 9.42769C0.312574 9.60741 0.268555 9.8037 0.268555 10.0025C0.268555 10.2012 0.312574 10.3975 0.39745 10.5772C2.51352 15.0348 5.71218 17.2781 10.0001 17.2781C14.2903 17.2781 17.4867 15.0348 19.6028 10.575C19.7747 10.2134 19.7747 9.79376 19.6028 9.42546V9.42546ZM10.0001 15.671C6.39968 15.671 3.76352 13.8451 1.90415 10.0013C3.76352 6.1576 6.39968 4.33171 10.0001 4.33171C13.6006 4.33171 16.2367 6.1576 18.0961 10.0013C16.239 13.8451 13.6028 15.671 10.0001 15.671ZM9.91084 6.07278C7.7412 6.07278 5.98227 7.83171 5.98227 10.0013C5.98227 12.171 7.7412 13.9299 9.91084 13.9299C12.0805 13.9299 13.8394 12.171 13.8394 10.0013C13.8394 7.83171 12.0805 6.07278 9.91084 6.07278ZM9.91084 12.5013C8.52915 12.5013 7.41084 11.383 7.41084 10.0013C7.41084 8.61965 8.52915 7.50135 9.91084 7.50135C11.2925 7.50135 12.4108 8.61965 12.4108 10.0013C12.4108 11.383 11.2925 12.5013 9.91084 12.5013Z" fill="currentColor" />
	</svg>
);

export default EyeVisible;

