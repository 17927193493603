import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './card.scss';

const cx = classNames.bind(styles);

function Card({ children, className, id }) {
	return (
		<div id={id} className={cx('card-container', className)}>
			{children}
		</div>
	);
}

Card.propTypes = {
	children: propTypes.node,
	className: propTypes.string,
	id: propTypes.string,
};

export default Card;
