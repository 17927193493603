import React from 'react';

const ChatBubbles = (props) => {
	return (
		<svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.95336 5.57812C7.59242 5.57812 7.31273 5.85781 7.31273 6.20312C7.31273 6.54843 7.59242 6.82812 7.95336 6.82812C8.28304 6.82812 8.56273 6.54843 8.56273 6.20312C8.56273 5.85781 8.28304 5.57812 7.95336 5.57812ZM3.57836 5.57812C3.21742 5.57812 2.93773 5.85781 2.93773 6.20312C2.93773 6.54843 3.21742 6.82812 3.57836 6.82812C3.90804 6.82812 4.18773 6.54843 4.18773 6.20312C4.18773 5.85781 3.90804 5.57812 3.57836 5.57812Z" fill="currentColor"/>
			<path d="M12.9685 4.3894C12.2456 3.39431 11.2032 2.67732 10.0154 2.35815V2.35971C9.74823 2.06284 9.44667 1.7894 9.10917 1.54565C6.55135 -0.313724 2.96073 0.253464 1.09354 2.81128C-0.411146 4.8894 -0.347084 7.68471 1.18729 9.67065L1.19979 11.7425C1.19979 11.7925 1.2076 11.8425 1.22323 11.8894C1.24288 11.952 1.27469 12.0101 1.31683 12.0604C1.35896 12.1108 1.41061 12.1523 1.4688 12.1826C1.527 12.2129 1.5906 12.2315 1.65597 12.2372C1.72135 12.2429 1.78721 12.2357 1.84979 12.216L3.82792 11.5925C4.35135 11.7785 4.89198 11.8847 5.42948 11.9144L5.42167 11.9207C6.81386 12.9347 8.63885 13.2394 10.3123 12.6863L12.2982 13.3332C12.3482 13.3488 12.3998 13.3582 12.4529 13.3582C12.7295 13.3582 12.9529 13.1347 12.9529 12.8582V10.7644C14.3295 8.89565 14.3654 6.31284 12.9685 4.3894ZM4.04667 10.4832L3.85917 10.405L2.31229 10.8894L2.29667 9.2644L2.17167 9.12378C0.849791 7.51128 0.762292 5.18784 1.99979 3.48315C3.50604 1.41753 6.39354 0.961276 8.45292 2.4519C10.5185 3.95346 10.9764 6.83628 9.48417 8.8894C8.2326 10.6066 5.99198 11.241 4.04667 10.4832ZM11.9373 10.2175L11.8123 10.3738L11.8279 11.9988L10.2967 11.4832L10.1092 11.5613C9.23417 11.8863 8.30135 11.9128 7.43729 11.6707L7.43417 11.6691C8.62172 11.3049 9.65906 10.5653 10.3904 9.56128C11.5842 7.91596 11.7779 5.84878 11.0842 4.08628L11.0935 4.09253C11.4529 4.35034 11.7826 4.67221 12.0623 5.06128C13.1967 6.61753 13.1326 8.73628 11.9373 10.2175Z" fill="currentColor"/>
			<path d="M5.76586 5.57812C5.40492 5.57812 5.12523 5.85781 5.12523 6.20312C5.12523 6.54843 5.40492 6.82812 5.76586 6.82812C6.09554 6.82812 6.37523 6.54843 6.37523 6.20312C6.37523 5.85781 6.09554 5.57812 5.76586 5.57812Z" fill="currentColor"/>
		</svg>
	);
};

export default ChatBubbles;
