import { rootStore } from '@stores';

export const _deepCopy = (obj) => {
	return JSON.parse(JSON.stringify(obj));
};

export const userHasPermission = (minPermission) => {
	const { scopeType, scopeId, resourceType, resourceId, subResourceType = '*', subResourceId = '*', operation } = minPermission;
	const serverConfig = rootStore?.AppStore?.parsedServerConfig;
	const rbacConfig = serverConfig?.rbacConfig;
	if (!rbacConfig) return false;

	const { OrganizationStore, UserStore } = rootStore;
	const relevantIds = {};
	if (scopeType !== '*' && scopeId !== '*') {
		relevantIds[scopeType] = scopeId;
	}
	if (resourceType !== '*' && resourceId !== '*') {
		relevantIds[resourceType] = resourceId;
	}
	if (subResourceType && subResourceType !== '*' && subResourceId && subResourceId !== '*') {
		relevantIds[subResourceType] = subResourceId;
	}
	if (relevantIds.organization && OrganizationStore.consultancyId) {
		relevantIds.consultancy = OrganizationStore.consultancyId;
	}

	// VALIDATE USER PERMISSIONS BASED ON PROVIDED MINIMUM METHOD PERMISSIONS
	// Assembling possible valid scopes, resources and operations base on minimum method permissions
	const {
		validScopesTypes,
		validResourceTypes,
		validSubResourceTypes,
		validOperations,
	} = rbacConfig;

	let validScopeIds = {};
	if (scopeType === 'organization') {
		validScopeIds = {
			organization: [relevantIds.organization],
			consultancy: [relevantIds.consultancy],
			'*': ['*'],
		};
	} else if (scopeType === 'consultancy') {
		validScopeIds = {
			consultancy: [relevantIds.consultancy],
			'*': ['*'],
		};
	} else if (scopeType === '*') {
		validScopeIds = {
			'*': ['*'],
		};
	}

	// validate the relevant consultancy permission if the scope is org level
	const minPermissions = [minPermission];
	if (scopeType === 'organization') {
		minPermissions.push({
			scopeType: 'consultancy',
			scopeId: relevantIds.consultancy,
			resourceType: 'organization',
			resourceId: relevantIds.organization,
			subResourceType: '*',
			subResourceId: '*',
			operation: operation,
		});
	}


	// GET ALL USER PERMISSIONS
	if (!UserStore.user?.permissions) return false;
	const _userPermissions = JSON.parse(JSON.stringify(UserStore.user.permissions)); // convert nested observable map to object

	return minPermissions.some((_minPermission) => {
		const {
			scopeType: _scopeType,
			scopeId: _scopeId,
			resourceType: _resourceType,
			resourceId: _resourceId,
			subResourceType: _subResourceType = '*',
			subResourceId: _subResourceId = '*',
			operation: _operation,
		} = _minPermission;

		// *** SCOPE_TYPE ***
		function checkScopeType(userPermissions) {
			for (let scopesTypeIndex = 0; scopesTypeIndex < validScopesTypes[_scopeType].length; scopesTypeIndex++) {
				const scopeTypeToCheck = validScopesTypes[_scopeType][scopesTypeIndex];
				const scopeTypePermissions = userPermissions[scopeTypeToCheck];
				if (scopeTypePermissions && checkScopeId(scopeTypeToCheck, scopeTypePermissions)) return true;
			}
			return false;
		}

		// *** SCOPE_ID ***
		function checkScopeId(scopeTypeToCheck, scopeTypePermissions) {
			if (_scopeId === null) {
				for (const scopeIdToCheck in scopeTypePermissions) {
					const scopeIdPermissions = scopeTypePermissions[scopeIdToCheck];
					if (checkResourceType(scopeIdPermissions)) return true;
				}
			} else {
				for (let scopeIdIndex = 0; scopeIdIndex < validScopeIds[scopeTypeToCheck].length; scopeIdIndex++) {
					const scopeIdToCheck = validScopeIds[scopeTypeToCheck][scopeIdIndex];
					const scopeIdPermissions = scopeTypePermissions[scopeIdToCheck];
					if (scopeIdPermissions && checkResourceType(scopeIdPermissions)) return true;
				}
			}
			return false;
		}

		// *** RESOURCE_TYPE ***
		function checkResourceType(scopeIdPermissions) {
			if (_resourceId === null || _resourceType === null) {
				for (const resourceTypeToCheck in scopeIdPermissions) {
					const resourceTypePermissions = scopeIdPermissions[resourceTypeToCheck];
					if (checkResourceId(resourceTypeToCheck, resourceTypePermissions)) return true;
				}
			} else {
				for (let resourceTypeIndex = 0; resourceTypeIndex < validResourceTypes[_scopeType][_resourceType].length; resourceTypeIndex++) {
					const resourceTypeToCheck = validResourceTypes[_scopeType][_resourceType][resourceTypeIndex];
					const resourceTypePermissions = scopeIdPermissions[resourceTypeToCheck];
					if (resourceTypePermissions && checkResourceId(resourceTypeToCheck, resourceTypePermissions)) return true;
				}
			}
			return false;
		}

		// *** RESOURCE_ID ***
		function checkResourceId(resourceTypeToCheck, resourceTypePermissions) {
			// null is for instances when any resourceId will be accepted
			if (_resourceId === null) {
				for (const resourceIdToCheck in resourceTypePermissions) {
					const resourceIdPermissions = resourceTypePermissions[resourceIdToCheck];
					if (checkSubResourceType(resourceIdPermissions)) return true;
				}
			} else {
				const validResourceIds = [relevantIds[resourceTypeToCheck], '*'];
				for (let resourceIdIndex = 0; resourceIdIndex < validResourceIds.length; resourceIdIndex++) {
					const resourceIdToCheck = validResourceIds[resourceIdIndex];
					const resourceIdPermissions = resourceTypePermissions[resourceIdToCheck];
					if (resourceIdPermissions && checkSubResourceType(resourceIdPermissions)) return true;
				}
			}
			return false;
		}

		// *** SUB_RESOURCE_TYPE ***
		function checkSubResourceType(resourceIdPermissions) {
			if (_subResourceId === null || _subResourceType === null || _resourceType === null) {
				for (const subResourceTypeToCheck in resourceIdPermissions) {
					const subResourceTypePermissions = resourceIdPermissions[subResourceTypeToCheck];
					if (checkSubResourceId(subResourceTypeToCheck, subResourceTypePermissions)) return true;
				}
			} else {
				for (let subResourceTypeIndex = 0; subResourceTypeIndex < validSubResourceTypes[_resourceType][_subResourceType].length; subResourceTypeIndex++) {
					const subResourceTypeToCheck = validSubResourceTypes[_resourceType][_subResourceType][subResourceTypeIndex];
					const subResourceTypePermissions = resourceIdPermissions[subResourceTypeToCheck];
					if (subResourceTypePermissions && checkSubResourceId(subResourceTypeToCheck, subResourceTypePermissions)) return true;
				}
			}
			return false;
		}

		// *** SUB_RESOURCE_ID ***
		function checkSubResourceId(subResourceTypeToCheck, subResourceTypePermissions) {
			// null is for instances when any subResourceId will be accepted
			if (_subResourceId === null) {
				for (const subResourceIdToCheck in subResourceTypePermissions) {
					const subResourceIdPermissions = subResourceTypePermissions[subResourceIdToCheck];
					if (checkOperation(subResourceIdPermissions)) return true;
				}
			} else {
				const validSubResourceIds = [relevantIds[subResourceTypeToCheck], '*'];
				for (let subResourceIdIndex = 0; subResourceIdIndex < validSubResourceIds.length; subResourceIdIndex++) {
					const subResourceIdToCheck = validSubResourceIds[subResourceIdIndex];
					const subResourceIdPermissions = subResourceTypePermissions[subResourceIdToCheck];
					if (subResourceIdPermissions && checkOperation(subResourceIdPermissions)) return true;
				}
			}
			return false;
		}

		// *** OPERATION ***
		function checkOperation(subResourceIdPermissions) {
			const validOperationsForMethod = validOperations[_operation];
			for (let operationIndex = 0; operationIndex < validOperationsForMethod.length; operationIndex++) {
				const operationToCheck = validOperationsForMethod[operationIndex];
				const operationPermissions = subResourceIdPermissions[operationToCheck];
				if (operationPermissions) return true;
			}
			return false;
		}

		// Check permission, if it exists, the user has access
		return checkScopeType(_userPermissions);
	});
};

export const userHasAppPermission = (programId, permissionKey) => {
	const { OrganizationStore, UserStore } = rootStore;

	if (!UserStore.user?.permissions) return false;

	const organizationId = OrganizationStore.organizationId;

	const hasAllProgramAccess = userHasPermission({
		scopeType: 'organization',
		scopeId: organizationId,
		resourceType: 'program',
		resourceId: '*',
		subResourceType: '*',
		subResourceId: '*',
		operation: 'view',
	});

	if (hasAllProgramAccess) return true;

	// GET ALL USER PERMISSIONS
	const userPermissions = _deepCopy(UserStore.user.permissions); // convert nested observable map to object

	// Check permission, if it exists, the user has access
	const programPermissions = userPermissions
		?.organization
		?.[organizationId]
		?.program;

	if (programPermissions) {
		const permission = programPermissions
			?.[programId]
			?.['*']
			?.['*']
			?.configuration
			?.app_permissions
			?.[permissionKey];

		if (permission === false) {
			return false;
		}
	}

	return true;
};
