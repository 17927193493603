import React from 'react';

const Error = (props) => (
	<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="7.99973" cy="11.8519" r="0.888889" fill="currentColor"/>
		<path d="M7.14809 4.14738C7.12791 3.66298 7.51516 3.25926 7.99997 3.25926V3.25926C8.48479 3.25926 8.87204 3.66298 8.85186 4.14738L8.61724 9.77829C8.60345 10.1092 8.33117 10.3704 7.99997 10.3704V10.3704C7.66878 10.3704 7.3965 10.1092 7.38271 9.77829L7.14809 4.14738Z" fill="currentColor"/>
		<circle cx="8" cy="8" r="7.5" stroke="currentColor"/>
	</svg>
);

export default Error;
