import React from 'react';

const Scissor = (props) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
		<path d="M7.86129 6.99902L12.8379 2.00996C12.916 1.93184 12.8613 1.7959 12.7504 1.7959H11.3363C11.3035 1.7959 11.2707 1.8084 11.2488 1.83184L6.97848 6.11309L5.56911 4.69902C5.76442 4.35371 5.87692 3.95527 5.87692 3.53027C5.87692 2.21934 4.81286 1.15527 3.50192 1.15527C2.19098 1.15527 1.12692 2.21934 1.12692 3.53027C1.12692 4.84121 2.19098 5.90527 3.50192 5.90527C3.93473 5.90527 4.33942 5.78965 4.68786 5.58809L6.09411 6.99902L4.68629 8.40996C4.32588 8.20147 3.91673 8.09204 3.50036 8.09277C2.18942 8.09277 1.12536 9.15684 1.12536 10.4678C1.12536 11.7787 2.18942 12.8428 3.50036 12.8428C4.81129 12.8428 5.87536 11.7787 5.87536 10.4678C5.87536 10.0428 5.76286 9.64434 5.56754 9.29902L6.97692 7.88496L11.2472 12.1662C11.2707 12.1896 11.3019 12.2021 11.3347 12.2021H12.7504C12.8613 12.2021 12.9175 12.0678 12.8379 11.9881L7.86129 6.99902ZM3.50036 4.78027C2.81129 4.78027 2.25036 4.21934 2.25036 3.53027C2.25036 2.84121 2.81129 2.28027 3.50036 2.28027C4.18942 2.28027 4.75036 2.84121 4.75036 3.53027C4.75036 4.21934 4.18942 4.78027 3.50036 4.78027ZM3.50036 11.7178C2.81129 11.7178 2.25036 11.1568 2.25036 10.4678C2.25036 9.77871 2.81129 9.21777 3.50036 9.21777C4.18942 9.21777 4.75036 9.77871 4.75036 10.4678C4.75036 11.1568 4.18942 11.7178 3.50036 11.7178Z" fill="currentColor"/>
	</svg>
);

export default Scissor;

