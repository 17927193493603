import React from 'react';

const MagGlassPages = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 38 54" fill="currentColor">
		<path d="M34.8794 38.7847C34.8794 39.1298 34.5996 39.4097 34.2544 39.4097L16.8355 39.4097C16.6724 39.4097 16.5158 39.3459 16.399 39.232L6.25567 29.336C6.13511 29.2184 6.06712 29.0571 6.06712 28.8887L6.06713 1.916C6.06713 1.57083 6.34695 1.29101 6.69213 1.29101L34.2544 1.29101C34.5996 1.29101 34.8794 1.57083 34.8794 1.91601L34.8794 38.7847Z" fill="#F8F9FA" stroke="#E1E3E5" strokeWidth="1.25"/>
		<path d="M0.808594 7.17139C0.808594 6.82621 1.08842 6.54639 1.43359 6.54639L18.8525 6.54639C19.0156 6.54639 19.1722 6.61013 19.289 6.72403L29.4323 16.62C29.5529 16.7377 29.6209 16.899 29.6209 17.0674V44.0401C29.6209 44.3852 29.341 44.665 28.9959 44.665H1.43359C1.08842 44.665 0.808594 44.3852 0.808594 44.04L0.808594 7.17139Z" fill="white" stroke="#E1E3E5" strokeWidth="1.25"/>
		<path d="M17.9582 16.6285L17.9832 6.68994H19.0585L29.4822 16.7952L29.4809 17.8817L19.2082 17.8816C18.5166 17.8816 17.9564 17.3201 17.9582 16.6285Z" fill="#F0F1F2" stroke="#E1E3E5" strokeWidth="1.25"/>
		<circle cx="15.2505" cy="30.874" r="10" fill="#F8F9FA" stroke="#E1E3E5" strokeWidth="2.5"/>
		<path d="M22.75 38.374L26.4081 42.03" stroke="#E1E3E5" strokeWidth="2.5"/>
		<rect x="0.884145" width="4.09098" height="13.1926" rx="1.25" transform="matrix(0.707316 -0.706898 0.707316 0.706898 24.082 43.749)" fill="#F0F1F2" stroke="#E1E3E5" strokeWidth="1.25"/>
	</svg>
);

export default MagGlassPages;
