import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { StoreContext, rootStore } from '@stores';
import '../i18n';
import './app.less'; // AntD
import '@app/styles/app.scss'; // Custom Styles
import Spinner from '@reusableComponents/Spinner/spinner';
import LoginRequiredRoute from './components/LoginRequiredRoute/loginRequiredRoute';
import ErrorBoundary from './errorHandling/errorBoundary';
import RouteChangeTracker from './components/RouteChangeTracker';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
const Main = lazy(() => import('./components/Main/main'));
const Auth = lazy(() => import('./components/Auth/auth'));
const DisabledOrganization = lazy(() => import('./components/Auth/disabledOrganization'));
const SharedLink = lazy(() => import('./components/sharedLink/sharedLink'));
const UserSetup = lazy(() => import('./components/UserSetup/userSetup'));
const NewUserFlow = lazy(() => import('./components/NewUserFlow/newUserFlow'));

// Google Analytics
const GA_TRACKING_ID = 'G-CBGR9PG7R8'; // Tracking ID (Clozd Platform)
ReactGA.initialize(GA_TRACKING_ID);

// Google Tag Manager
const tagManagerArgs = {
	gtmId: 'GTM-K7QKRDH',
};
TagManager.initialize(tagManagerArgs);


const container = document.getElementById('app');
const root = createRoot(container);
root.render(
	<ErrorBoundary>
		<StoreContext.Provider value={rootStore}>
			<Router>
				<RouteChangeTracker />
				<Suspense fallback={<Spinner data-testid="clozd-lazy-loader" />}>
					<Switch>
						{/* NOTE ON NEW ROUTES */}
						{/* If you are adding new routes, be sure to add that path to the regex in index.js
						on the server. This will allow you to refresh and navigate directly to that new route.
						Otherwise, direct navigation will result in a 404 and won't fetch the bundle. */}
						<Route exact path="/login">
							<Redirect to="/auth/login" />
						</Route>
						<Route path="/auth" component={Auth} />
						<Route path="/disabled" component={DisabledOrganization} />
						<Route path="/share" component={SharedLink} />
						<LoginRequiredRoute path="/user-setup">
							<UserSetup />
						</LoginRequiredRoute>
						<LoginRequiredRoute path="/">
							<NewUserFlow>
								<Route path="/" component={Main} />
							</NewUserFlow>
						</LoginRequiredRoute>
					</Switch>
				</Suspense>
			</Router>
		</StoreContext.Provider>
	</ErrorBoundary>,
);
