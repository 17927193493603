import React from 'react';

const People = (props) => (
	<svg {...props} viewBox="0 0 17 10" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.5 0C9.15752 0 9.7881 0.25431 10.253 0.706984C10.718 1.15966 10.9792 1.77362 10.9792 2.41379C10.9792 3.05397 10.718 3.66793 10.253 4.1206C9.7881 4.57328 9.15752 4.82759 8.5 4.82759C7.84248 4.82759 7.2119 4.57328 6.74696 4.1206C6.28203 3.66793 6.02083 3.05397 6.02083 2.41379C6.02083 1.77362 6.28203 1.15966 6.74696 0.706984C7.2119 0.25431 7.84248 0 8.5 0ZM3.54167 1.72414C3.93833 1.72414 4.30667 1.82759 4.62542 2.01379C4.51917 3 4.81667 3.97931 5.42583 4.74483C5.07167 5.4069 4.36333 5.86207 3.54167 5.86207C2.97808 5.86207 2.43758 5.64409 2.03906 5.25608C1.64055 4.86808 1.41667 4.34183 1.41667 3.7931C1.41667 3.24438 1.64055 2.71813 2.03906 2.33012C2.43758 1.94212 2.97808 1.72414 3.54167 1.72414ZM13.4583 1.72414C14.0219 1.72414 14.5624 1.94212 14.9609 2.33012C15.3595 2.71813 15.5833 3.24438 15.5833 3.7931C15.5833 4.34183 15.3595 4.86808 14.9609 5.25608C14.5624 5.64409 14.0219 5.86207 13.4583 5.86207C12.6367 5.86207 11.9283 5.4069 11.5742 4.74483C12.1833 3.97931 12.4808 3 12.3746 2.01379C12.6933 1.82759 13.0617 1.72414 13.4583 1.72414ZM3.89583 8.7931C3.89583 7.36552 5.95708 6.2069 8.5 6.2069C11.0429 6.2069 13.1042 7.36552 13.1042 8.7931V10H3.89583V8.7931ZM0 10V8.96552C0 8.0069 1.33875 7.2 3.15208 6.96552C2.73417 7.43448 2.47917 8.08276 2.47917 8.7931V10H0ZM17 10H14.5208V8.7931C14.5208 8.08276 14.2658 7.43448 13.8479 6.96552C15.6612 7.2 17 8.0069 17 8.96552V10Z" fill="currentColor"/>
	</svg>
);

export default People;

