// STORE
// In this folder "store" is used as a model that is universally instantiated

// MODEL
// "model" is used as a reusable MST node that is instantiated a variable amount
// of times inside of a store.

import React, { useContext, createContext } from 'react';
import { withTranslation } from 'react-i18next';
import { addMiddleware, destroy } from 'mobx-state-tree';
import errorHandling from './middleware';
import _get from 'lodash/get';

// Store Imports
import { AppStoreModel } from './app.store';
import { BreadcrumbStoreModel } from './breadcrumb.store';
import { OrganizationStoreModel } from './organization.store';
import { ProgramStoreModel } from './program.store';
import { QuickFilterStoreModel } from './quickFilter.store';
import { QuotesDrawerStoreModel } from './quotesDrawer.store';
import { UserStoreModel } from './user.store';


// Instantiate stores here (so that in tests we are only instantiating once)
const OrganizationStore = OrganizationStoreModel.create({});
const ProgramStore = ProgramStoreModel.create({}, { OrganizationStore });
const UserStore = UserStoreModel.create({}, { OrganizationStore, ProgramStore });
const AppStore = AppStoreModel.create({}, { UserStore });
const BreadcrumbStore = BreadcrumbStoreModel.create({}, { ProgramStore });
const QuickFilterStore = QuickFilterStoreModel.create({}, { ProgramStore });
const QuotesDrawerStore = QuotesDrawerStoreModel.create({}, { ProgramStore });

// Add middleware to instances
addMiddleware(UserStore, errorHandling);
addMiddleware(AppStore, errorHandling);
addMiddleware(OrganizationStore, errorHandling);
addMiddleware(ProgramStore, errorHandling);
addMiddleware(QuotesDrawerStore, errorHandling);
addMiddleware(BreadcrumbStore, errorHandling);
addMiddleware(QuickFilterStore, errorHandling);

// initial values
export const rootStore = {
	AppStore,
	BreadcrumbStore,
	OrganizationStore,
	ProgramStore,
	QuotesDrawerStore,
	QuickFilterStore,
	UserStore,
};

// Context
export const StoreContext = createContext(null);

// Hook
export function useStores() {
	return useContext(StoreContext);
}

// Functional Component Model Wrapper

// This allows you to use a local MST file in the functional component only.
// How to use:
// In your functionalComponent.model.js file, export your mobx state tree as an object.
// withTranslation t is being used and is accessible in the props parameter.
// You must include any needed Stores and any initial values in the MST file export object.
// Stores are accessible by importing the rootStore.

// import { rootStore } from '@stores';
// ...
// const ComponentModel = model('ComponentModel', { ... })
// ...
// export default {
// 	model: ComponentModel,
// 	initialValues: {},
// 	stores: {
// 		userStore: rootStore.UserStore
// 	},
// };

// You can access any stores provided in a view with `getEnv(self).NeedStore`, etc...

// get NeededStore() {
// 	return getEnv(self).NeededStore;
// },

// You can access props in a view with `self.props`, etc...

// get props() {
// 	return self.props;
// },

// To use in your functional component:

// import { ModelConnector } from '@stores';
// import FunctionalComponentModel from './functionalComponent.model';
// ...
// const FunctionalComponent = observer(({ model }) => { ... }
// ...
// export default ModelConnector(FunctionalComponent, FunctionalComponentModel);

// Note: The component is being wrapped in an observer at definition instead of in export to bypass propType requirements.

export const ModelConnector = (WrappedComponent, models, isTestModel = false) => {
	return withTranslation()(class extends React.Component {
		constructor(props) {
			super(props);
			const modelProps = {};
			for (const modelName in models) {
				if (Object.hasOwnProperty.call(models, modelName)) {
					const modelObject = models[modelName];
					if (isTestModel) {
						modelProps[modelName] = modelObject;
					} else {
						modelProps[modelName] = createNewModelWithProps(modelObject, props);
					}
				}
			}
			this.modelProps = modelProps;
		}

		shouldComponentUpdate(nextProps) {
			for (const model in this.modelProps) {
				this.modelProps[model].updateProps(nextProps);
			}
			return true;
		}

		componentWillUnmount() {
			for (const model in this.modelProps) {
				// make sure the state tree is cleaned up to prevent memory leaks when component unmounts
				destroy(this.modelProps[model]);
			}
		}

		render() {
			return (
				<WrappedComponent {...this.modelProps} {...this.props} />
			);
		}
	});
};

function createNewModelWithProps(modelObject, props) { // this is necessary for the models to get updated props
	const { model, initialValues = {}, stores = rootStore } = modelObject;
	const newModel = model
		.views((self) => ({
			get t() {
				const t = _get(self, 'props.t', () => { });
				return t;
			},
		}))
		.volatile(() => ({
			props,
		}))
		.actions(self => ({
			updateProps(nextProps) {
				self.props = nextProps;
			},
		}));

	if (props.savedPropsToState) {
		for (const p in props.savedPropsToState) {
			initialValues[p] = props.savedPropsToState[p];
		}
	}

	const initiatedModel = newModel.create(initialValues, stores);
	addMiddleware(initiatedModel, errorHandling);
	return initiatedModel;
}
