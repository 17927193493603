import { types } from 'mobx-state-tree';
import i18next from 'i18next';
import { format } from 'date-fns';
import RightOperandType from './rightOperandType.baseModel';

const {
	model,
	string,
	maybe,
	maybeNull,
	optional,
} = types;

const FilterExpression = model('FilterExpressionModel', {
	name: optional(string, ''),
	type: maybe(string),
	leftOperand: maybe(string),
	operator: maybe(string),
	rightOperand: maybeNull(RightOperandType),
})
	.views(self => ({
		// return true if inputs are valid. If these fields are missing, the server will reject it
		get cleaned() {
			if (
				// leftOperand and type either need to be a support Clozd column or custom metadata
				(
					!self.leftOperand
					&& !self.type
				)
				// the operator needs to be a supported operator
				|| !self.operator
			) {
				return false;
			}

			// only valid if expression has either a rightOperand or is one of the IS NULL/IS NOT NULL/is current user operators
			if (!self.rightOperand && ![false, 0].includes(self.rightOperand) && !(['isNull', 'notNull', 'isCurrentUser'].includes(self.operator))) {
				return false;
			}
			return true;
		},
		filterDisplayName(metadataField) {
			const name = metadataField.display_name || metadataField.name;
			// if for some reason they're both null, use the translation file with the type for all clozd fields
			if (!name && metadataField.is_clozd_field) {
				return self.t(`program.filter.types.${self.type}`);
			} else if (!name) {
				// worst case scenario, use the filter type
				return self.type;
			}
			return name;
		},
		formatValue(value, sub_type) {
			switch (sub_type) {
				case 'boolean':
					return value ? self.t('global.true') : self.t('global.false');
				case 'currency':
				case 'decimal':
				case 'integer':
					return value.toLocaleString();
				case 'percent':
					return `${value}%`;
				case 'date':
				case 'datetime':
					return format(new Date(value), 'MM/dd/yyyy');
				case 'email':
				case 'id':
				case 'phone':
				case 'text':
				case 'url':
				default:
					return value;
			}
		},
		metadataField(programStore) {
			// find the correlating metadata field for the filter
			const metadataField = programStore.metadata.find(m => m.name === self.type || m.metadata_definition_id === self.type);
			return metadataField || {};
		},
		get operatorShorthand() {
			return [
				'eq',
				'equalsExactly',
				'gt',
				'gte',
				'lt',
				'lte',
				'ne',
				'notEqualsExactly',
			];
		},
		get operatorTemplates() {
			return [
				'beforePastNDays',
				'beforePastNMonths',
				'beforePastNWeeks',
				'beforePastNYears',
				'pastNDays',
				'pastNMonths',
				'pastNQuarters',
				'pastNWeeks',
				'pastNYears',
			];
		},
		get unaryOperators() {
			return [
				'isNull',
				'notNull',
				'isCurrentUser',
			];
		},
		/**
		 * Use readableFilter if you want to see the filter in it's readable form
		 */
		readableFilter(programStore) {
			// if it's not a valid filter, don't return the readable filter
			if (!self.cleaned) {
				return null;
			}
			const metadataField = self.metadataField(programStore);
			let includeRightOperandEnd = true;
			const operatorReadableDisplay = (() => {
				if (self.operatorShorthand.includes(self.operator)) {
					return self.t(`program.filter.operatorShorthand.${self.operator}`);
				} else if (self.operatorTemplates.includes(self.operator)) {
					includeRightOperandEnd = false; // because the right operand value is in the operator template, don't include it a the end of the sentence
					return self.t(`program.filter.operatorTemplates.${self.operator}`, { count: self.rightOperand });
				} else if (self.unaryOperators.includes(self.operator)) {
					includeRightOperandEnd = false;
				}
				return self.t(`program.filter.operators.${self.operator}`);
			})();

			let readableFilter = `${self.filterDisplayName(metadataField)} ${operatorReadableDisplay}`;
			if (includeRightOperandEnd) {
				readableFilter += ` ${self.rightOperandReadableDisplay(metadataField.sub_type)}`;
			}
			return readableFilter;
		},
		// This doesn't support product metadata at the moment since product metadata is handled differently than other picklist metadata
		rightOperandReadableDisplay(subType) {
			const formatValueHelper = (text) => self.formatValue(text, subType);
			const rightOperand = self.rightOperand;
			if (Array.isArray(rightOperand)) {
				const rightOperandLength = self.rightOperand.length;
				if (rightOperandLength === 0) {
					return '';
				} else if (rightOperandLength === 1) {
					return formatValueHelper(rightOperand[0]);
				} else if (rightOperandLength === 2) {
					return `${formatValueHelper(rightOperand[0])} ${self.t('program.filter.operators.and')} ${formatValueHelper(rightOperand[1])}`;
				}
				const listBeginning = rightOperand.slice(0, -1);
				const lastItem = rightOperand[rightOperandLength - 1];
				return `${listBeginning.map(text => formatValueHelper(text)).join(', ')}, ${self.t('program.filter.operators.and')} ${formatValueHelper(lastItem)}`;
			}
			return formatValueHelper(rightOperand);
		},
		get t() {
			return i18next.t;
		},
	}));

export default FilterExpression;
