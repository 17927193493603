import React from 'react';

const BoxCheck = (props) => (
	<svg {...props} viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.42286 0.0485611C1.72381 0.203465 1.10816 0.592712 0.679196 1.15275C0.405134 1.51817 0.198595 1.93124 0.0873819 2.34432C0 2.67002 0 2.67399 0 6.78094V10.8919L0.0873819 11.1977C0.401162 12.282 1.13596 13.0764 2.10511 13.3981L2.46258 13.5133H8.51974H14.5769L14.8946 13.4061C15.8638 13.0764 16.6105 12.2701 16.9084 11.2414L16.9998 10.9316V6.76108C16.9998 2.08218 17.0196 2.41582 16.7019 1.75648C16.3166 0.950184 15.6731 0.390145 14.8152 0.100196L14.5372 0.00884247L8.59918 0.000898361C3.77728 -0.00307369 2.61749 0.00487041 2.42286 0.0485611ZM14.2869 1.3434C14.7556 1.4427 15.2601 1.83989 15.4865 2.28474C15.7129 2.7296 15.7089 2.66208 15.7089 6.76108C15.7089 10.8641 15.7129 10.7926 15.4865 11.2414C15.3355 11.5393 15.1369 11.7617 14.8748 11.9404C14.414 12.2463 14.8351 12.2264 8.40058 12.2145L2.66118 12.2026L2.43081 12.1073C2.1488 11.9961 1.71189 11.5989 1.56096 11.3208C1.50138 11.2176 1.42194 11.0229 1.38222 10.8919C1.3147 10.6655 1.31073 10.4272 1.31073 6.74122V2.8289L1.40208 2.57469C1.57287 2.10204 1.91049 1.68896 2.27987 1.50228C2.67706 1.29971 2.45861 1.30368 8.4681 1.30368C12.4281 1.29971 14.14 1.31163 14.2869 1.3434Z" fill="currentColor"/>
		<path d="M9.5723 6.10571C8.32909 7.32906 7.30037 8.32998 7.28845 8.32998C7.27654 8.32998 6.87935 7.89307 6.39875 7.35686C5.92212 6.82462 5.49712 6.35594 5.4574 6.32019C5.38988 6.26061 5.35811 6.28047 4.90531 6.68561C4.64316 6.91995 4.43265 7.13046 4.43662 7.14635C4.44457 7.16621 5.07213 7.87718 5.83474 8.73114L7.2249 10.2802L7.66181 9.84724C7.9041 9.6129 9.14731 8.38955 10.4263 7.13443C11.7052 5.87931 12.7578 4.84264 12.7617 4.82278C12.7816 4.78306 11.9356 3.88144 11.88 3.88144C11.8561 3.88144 10.8195 4.88236 9.5723 6.10571Z" fill="currentColor"/>
	</svg>
);

export default BoxCheck;
