import React from 'react';
import classNames from 'classnames/bind';
import styles from './spinner.scss';
import { Loader } from '@clozd/clozd-ui';
const cx = classNames.bind(styles);

const Spinner = ({ containerClassName, ...props }) => (
	<div className={cx('spinner', containerClassName)}>
		<Loader size={32} {...props} />
	</div>
);

export default Spinner;
