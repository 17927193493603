
import React from 'react';

const Export = (props) => (
	<svg {...props} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0 17H14V15H0V17Z" fill="currentColor"/>
		<path d="M9.5 6V6.5H10H12.7929L7 12.2929L1.20711 6.5H4H4.5V6V0.5H9.5V6Z" stroke="currentColor"/>
	</svg>
);

export default Export;
