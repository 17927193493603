import React from 'react';

const Bell = (props) => (
	<svg {...props} viewBox="0 0 12 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<path d="M11.4286 12.6418H11V6.5704C11 4.05076 9.1375 1.96861 6.71429 1.62219V0.927543C6.71429 0.5329 6.39464 0.213257 6 0.213257C5.60536 0.213257 5.28571 0.5329 5.28571 0.927543V1.62219C2.8625 1.96861 1 4.05076 1 6.5704V12.6418H0.571429C0.255357 12.6418 0 12.8972 0 13.2133V13.7847C0 13.8633 0.0642857 13.9275 0.142857 13.9275C5.01037 13.9275 7.40218 13.9275 11.8571 13.9275C11.9357 13.9275 12 13.8633 12 13.7847V13.2133C12 12.8972 11.7446 12.6418 11.4286 12.6418ZM2.28571 12.6418V6.5704C2.28571 5.57754 2.67143 4.6454 3.37321 3.94361C4.075 3.24183 5.00714 2.85611 6 2.85611C6.99286 2.85611 7.925 3.24183 8.62679 3.94361C9.32857 4.6454 9.71429 5.57754 9.71429 6.5704V12.6418H2.28571Z" fill="currentColor"/>
		<path d="M6.00072 15.3726C5.1719 15.3726 4.5 14.676 4.5 13.8167C4.5 13.6007 4.55646 13.4795 4.58741 13.3726M5.99928 15.3726C6.8281 15.3726 7.5 14.676 7.5 13.8167C7.5 13.6007 7.44354 13.4795 7.41259 13.3726" stroke="currentColor" strokeWidth="1.1"/>
	</svg>
);

export default Bell;
