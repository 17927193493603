import React from 'react';

const Warning = (props) => (
	<svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.35748 8.78572C5.35748 8.95621 5.42521 9.11972 5.54577 9.24028C5.66633 9.36084 5.82984 9.42857 6.00034 9.42857C6.17084 9.42857 6.33435 9.36084 6.45491 9.24028C6.57547 9.11972 6.6432 8.95621 6.6432 8.78572C6.6432 8.61522 6.57547 8.45171 6.45491 8.33115C6.33435 8.21059 6.17084 8.14286 6.00034 8.14286C5.82984 8.14286 5.66633 8.21059 5.54577 8.33115C5.42521 8.45171 5.35748 8.61522 5.35748 8.78572ZM5.57177 4.71429V7.17857C5.57177 7.2375 5.61998 7.28572 5.67891 7.28572H6.32177C6.3807 7.28572 6.42891 7.2375 6.42891 7.17857V4.71429C6.42891 4.65536 6.3807 4.60714 6.32177 4.60714H5.67891C5.61998 4.60714 5.57177 4.65536 5.57177 4.71429ZM11.9428 10.6071L6.37132 0.964286C6.28829 0.820982 6.14498 0.75 6.00034 0.75C5.8557 0.75 5.71105 0.820982 5.62936 0.964286L0.0579287 10.6071C-0.106803 10.8938 0.0994465 11.25 0.428911 11.25H11.5718C11.9012 11.25 12.1075 10.8938 11.9428 10.6071ZM1.44945 10.2335L6.00034 2.3558L10.5512 10.2335H1.44945Z" fill="currentColor"/>
	</svg>
);

export default Warning;

