import { types } from 'mobx-state-tree';

const { model } = types;

export const BreadcrumbStoreModel = model('BreadcrumbStoreModel', {
})
	.volatile(() => ({
		crumbs: [],
	}))
	.actions((self) => ({
		addCrumb(crumb) {
			// Check if that crumb already exists
			if (self.crumbs.some(({ text, to }) => crumb.text === text && crumb.to === to)) return;
			if (!crumb.text && !crumb.component) return;
			const crumbs = [...self.crumbs, crumb];
			crumbs.sort((a, b) => {
				if (a.order === undefined && b.order === undefined) return 0;
				if (a.order === undefined) return 1;
				if (b.order === undefined) return -1;
				return a.order - b.order;
			});
			self.crumbs = crumbs;
		},
		removeCrumb({ to }) {
			self.crumbs = self.crumbs.filter(crumb => crumb.to !== to);
		},
		resetCrumbs() {
			if (self.crumbs?.length > 0) self.crumbs = [];
		},
		setInitialCrumbs(crumbs) {
			self.crumbs = crumbs;
		},
	}));
