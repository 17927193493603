import { types } from 'mobx-state-tree';
const {
	string,
	array,
	number,
	union,
	boolean,
} = types;

const RightOperandType = union(
	string,
	number,
	boolean,
	types.Date,
	array(union(
		string,
		number,
		types.Date,
		types.undefined,
		types.null,
	)),
);

export default RightOperandType;
