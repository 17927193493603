import { applySnapshot, getSnapshot, types, getParent, hasParent, getType } from 'mobx-state-tree';
import { isExpressionValid } from '@utils/filters';
import FilterExpression from './filterExpression.baseModel';
import _cloneDeep from 'lodash/cloneDeep';
import { reaction } from 'mobx';

const {
	model,
	array,
	number,
	optional,
	union,
	enumeration,
	late,
} = types;

export const DealCountsModel = model('DealCountsModel', {
	deal_count: optional(number, 0),
	deals_with_feedback_count: optional(number, 0),
	interview_count: optional(number, 0),
	survey_count: optional(number, 0),
});

const Filters = model('FiltersModel', {
	logicalOperator: optional(enumeration('LogicalOperator', ['and', 'or']), 'and'),
	expressions: array(union({
		dispatcher: (snapshot) => {
			if ('leftOperand' in snapshot) return FilterExpression;
			if ('logicalOperator' in snapshot) return Filters;
			return FilterExpression;
		},
	}, late(() => Filters), FilterExpression)),
})
	.volatile(() => ({
		disposer: null,
	}))
	.views(self => ({
		// Only return expressions with valid inputs
		get cleaned() {
			return {
				logicalOperator: self.logicalOperator,
				expressions: Array.isArray(self.expressions) ? self.expressions.filter(expression => expression.cleaned).map(e => getSnapshot(e)) : [],
			};
		},
		// valid filters have either:
		// 1) expressions with all operators and operands selected
		// 2) expressions with empty values: {"logicalOperator":"and","expressions":[{"name":"","rightOperand":null}]}
		// 3) expressions with empty array: {"logicalOperator":"and","expressions":[]}
		get hasFilters() {
			return self.expressions.length > 0;
		},
		get hasValidFilters() {
			return self.validateFilters(self) ||
				self.expressions.length === 0 ||
				(self.expressions.length === 1 &&
					Object.values(self.expressions[0]).every(value => !value));
		},
		get emptyFilter() {
			return {
				logicalOperator: 'and',
				expressions: [],
			};
		},
		get numberOfFilters() {
			return self.expressions.reduce((count, expression) => {
				if ('expressions' in expression) return count + expression.numberOfFilters;
				return count + 1;
			}, 0);
		},
		get numberOfValidFilters() {
			const cleanedFilters = self.cleaned;
			function countFilters(filters) {
				if (Array.isArray(filters)) return filters.reduce((agg, value) => agg + countFilters(value), 0);
				else if ('expressions' in filters) return countFilters(filters.expressions);
				return 1;
			}
			return countFilters(cleanedFilters);
		},
		validateFilters(expression) {
			// If the expression is a nested expression, call this function recursively
			// Expression is nested if it has both a logicalOperator and an array of expressions
			if (expression && expression.expressions && expression.expressions.length > 0 && expression.logicalOperator) {
				return expression.expressions.reduce((isValid, exp) => {
					return isValid && self.validateFilters(exp);
				}, true);
				// If the expression has a leftOperand and operator
			}
			return isExpressionValid(expression);
		},
	}))
	.actions(self => ({
		afterCreate() {
			// apply existing filters the first time you instantiate the model, if present
			if (self?.props?.defaultFilters) {
				applySnapshot(self, _cloneDeep(self?.props?.defaultFilters));
			}

			// apply any updates passed in
			const disposer = reaction( // reactions should be used sparingly and avoided if possible, they should always be cleaned up to prevent memory leaks which is being done in beforeDestroy
				() => ({
					defaultFilters: self?.props?.defaultFilters,
				}),
				() => {
					if (self?.props?.defaultFilters) {
						applySnapshot(self, _cloneDeep(self?.props?.defaultFilters));
					}
				},
			);
			self.disposer = disposer;

			self.checkEmptyFilter();
		},
		beforeDestroy() {
			if (self.disposer) self.disposer();
		},
		emitApply() {
			// if the onApply prop is passed in, call it
			if (self?.props?.onApply) self.props.onApply(getSnapshot(self));
		},
		emitChange() {
			// if the onChange prop is passed in, call it
			if (self?.props?.onChange) self.props.onChange(getSnapshot(self));

			// if the filter block has a parent filter block, emit onChange from it.
			const depth = 2; // array, then Filters model
			if (hasParent(self, depth)) {
				const parent = getParent(self, depth);
				if (getType(parent)?.name === 'FiltersModel') {
					parent.emitChange();
				}
			}
		},
		addFilter() {
			const expression = FilterExpression.create();
			self.expressions.push(expression);
			self.emitChange();
		},
		addExpressionBlock() {
			const filterBlock = Filters.create();
			self.expressions.push(filterBlock);
			self.emitChange();
		},
		changeFilters(filters) {
			applySnapshot(self, filters);
			self.checkEmptyFilter();
			self.emitChange();
		},
		checkEmptyFilter() {
			if (self.expressions.length === 0) {
				self.addFilter();
			}
		},
		removeFilter(index) {
			self.expressions.splice(index, 1);
			if (self.expressions.length === 0) {
				self.destroySelf();
			}
			self.emitChange();
		},
		destroySelf() {
			const depth = 2; // array, then Filters model
			if (hasParent(self, depth)) {
				const parent = getParent(self, depth);
				if (getType(parent)?.name === 'FiltersModel') {
					parent.removeFilter(parent.expressions.indexOf(self));
				}
			}
		},
		setFilterExpressionType(index, type, leftOperand) {
			if (self.expressions[index]) {
				self.expressions[index] = { type, leftOperand };
			}
			self.emitChange();
		},
		updateFilterExpression(index, expression) {
			self.expressions[index] = _cloneDeep(expression);
			self.emitChange();
		},
		clearFilters() {
			if (self?.props?.canClear) {
				self.changeFilters(self.emptyFilter);
				const currentFilters = getSnapshot(self);
				if (self?.props?.onClear) self.props.onClear(currentFilters);
				if (self?.props?.onApply) self.props.onApply(currentFilters);
			}
		},
		setLogicalOperator(logicalOperator) {
			self.logicalOperator = logicalOperator;
			self.emitChange();
		},
	}));

export const FiltersBaseModel = {
	model: Filters,
	stores: {},
};

export default Filters;
