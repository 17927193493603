import React from 'react';
import { types, getEnv, getSnapshot, onSnapshot } from 'mobx-state-tree';
import _isEqual from 'lodash/isEqual';
import FilterExpression from '@baseModels/filterExpression.baseModel';
import RightOperandType from '@baseModels/rightOperandType.baseModel';
import i18next from 'i18next';
import numeral from 'numeral';
import _cloneDeep from 'lodash/cloneDeep';

const { compose, model, string, number, boolean, optional, array, maybe, maybeNull, union } = types;

const AdditionalFilterOptions = model('AdditionalFilterOptions', {
	disabled: optional(boolean, false),
	selected: optional(boolean, false),
	title: optional(string, ''),
	translationKey: optional(string, ''),
	count: maybeNull(number), // Used for the clozd_amount range picker
})
	.views((self) => ({
		get t() {
			return i18next.t;
		},
		get translatedText() {
			if (self.translationKey) {
				return self.translationKey.includes(...['program', 'global'])
					? self.t(self.translationKey, { count: Number(self.rightOperand) })
					: self.t(`program.filter.quickFilters.${self.translationKey}`, { count: Number(self.rightOperand) });
			} else if (self.title) {
				return self.title;
			}
			return self.rightOperand;
		},
	}))
	.actions((self) => ({
		setDisabled(disabled) {
			self.disabled = disabled;
		},
		setRightOperand(rightOperand) {
			// This should only be used for the custom option.
			self.rightOperand = rightOperand;
		},
		setSelected(selected) {
			self.selected = selected;
		},
	}));

const QuickFilterModel = model('QuickFilterModel', {
	quickFilterName: optional(string, ''),
	visible: optional(boolean, true),
	optionsShouldBeVisible: optional(boolean, false),
	leftOperand: maybe(union(array(string), string)),
	rightOperand: maybe(RightOperandType), // This is used for certian operator types (ie. 'between')
	type: maybe(union(array(string), string)),
	selectedIndex: maybe(number), // This is the index of of the selected leftOperand and type if they are arrays.
	showSearch: maybe(boolean),
	searchText: optional(string, ''),
	operator: maybe(string),
	options: array(compose(FilterExpression, AdditionalFilterOptions)),
	dynamicOptions: optional(boolean, false),
	showCustomOption: optional(boolean, false),
	shouldBeSaved: optional(boolean, false),
	customOption: optional(compose(FilterExpression, AdditionalFilterOptions), {}),
	order: optional(number, 0),
})
	.views((self) => ({
		get t() {
			return i18next.t;
		},
		get programStore() {
			return getEnv(self).ProgramStore;
		},
		get convertedLeftOperand() {
			return Array.isArray(self.leftOperand) ? self.leftOperand[self.selectedIndex] : self.leftOperand;
		},
		get convertedType() {
			return Array.isArray(self.type) ? self.type[self.selectedIndex] : self.type;
		},
		getFilterOption: (option) => {
			if (!self.searchText || !option.title) { return true }
			if (option.title) {
				return option.title.toLowerCase().indexOf(self.searchText.toLowerCase()) >= 0;
			}
			return true;
		},
		get filteredOptions() {
			return self.options.filter((option) => self.getFilterOption(option));
		},
		get formatSelectedOptions() {
			/**
			 * This is to see if the custom option should be used.
			 * Only allowing one operator type for now. If quick filters ever needs more, that will need to be accounted for here.
			 */
			if (self.customOption?.selected && self.customOption.operator === 'beforeOrAfter') {
				if (self.customOption.rightOperand[0] && !self.customOption.rightOperand[1]) {
					return {
						rightOperand: self.customOption.rightOperand[0],
						operator: 'after',
					};
				} else if (self.customOption.rightOperand[1] && !self.customOption.rightOperand[0]) {
					return {
						rightOperand: self.customOption.rightOperand[1],
						operator: 'before',
					};
				}
				return {
					rightOperand: self.customOption.rightOperand,
					operator: 'between',
				};
			}

			if (self.selectedOptions.length === 1 && self.singleSelectOperators.includes(self.selectedOptions[0].operator)) {
				return {
					rightOperand: self.selectedOptions.map(({ rightOperand }) => rightOperand)[0],
					operator: self.selectedOptions[0]?.operator || self.operator,
				};
			}

			// Check to see if it should add the filter based on the quick filter rightOperand
			if (self.variableRightOperandOperators.includes(self.operator)) {
				return {
					rightOperand: self.rightOperand,
					operator: self.operator,
				};
			}

			return {
				rightOperand: self.selectedOptions.map(({ rightOperand }) => rightOperand),
				operator: self.selectedOptions[0]?.operator || self.operator,
			};
		},
		get hasValidDealSizeSelection() {
			return self.convertedType === 'clozd_amount'
				&& Array.isArray(self.rightOperand)
				&& self.rightOperand.length > 0
				&& self.options.length > 0
				&& (self.options[0]?.rightOperand[0] !== self.rightOperand[0] || self.options[self.options.length - 1]?.rightOperand[1] !== self.rightOperand[1]);
		},
		get isSelected() {
			return self.selectedOptions?.length || self.customOption?.selected || self.hasValidDealSizeSelection;
		},
		get selectedFiltersMap() {
			return new Map(
				self.programStore?.filters?.expressions.map((expression, index) => {
					return [expression.type, { ...expression, index }];
				}),
			);
		},
		get selectedOptions() {
			return self.options.filter((option) => option.selected);
		},
		get displayName() {
			if (Array.isArray(self.type)) {
				return self.t(`program.filter.quickFilters.${self.convertedType}`, self.convertedType);
			}
			return self.metadata?.display_name || self.t(`program.filter.quickFilters.${self.convertedType}`, self.convertedType);
		},
		get selectedText() {
			// This is only for timeframe
			if (self.quickFilterName === 'timeframe') {
				if (self.selectedOptions.length === 1) {
					return self.t('program.filter.quickFilters.selectedTimeframe', {
						type: self.t(`program.filter.types.${self.convertedType}`),
						operator: self.selectedOptions[0].translatedText,
					});
				}

				if (self.customOption?.selected) {
					return self.t('program.filter.quickFilters.selectedTimeframe', {
						type: self.t(`program.filter.types.${self.convertedType}`),
						operator: self.customOption.translatedText,
					});
				}
			}

			if (self.customOption?.selected) {
				return self.customOption.translatedText;
			}

			// this is only for deal size (amount)
			if (self.variableRightOperandOperators.includes(self.operator) && self.rightOperand?.length > 0 && self.hasValidDealSizeSelection) {
				return self.t(`program.filter.quickFilters.${self.convertedType}MinMax`, {
					min: numeral(self.rightOperand[0]).format('0,0'),
					max: numeral(self.rightOperand[1]).format('0,0'),
				});
			}

			if (self.selectedOptions.length > 1) {
				return (
					<>
						<span>({self.selectedOptions.length})</span>
						&nbsp;
						<span>{self.displayName}</span>
					</>
				);
			} else if (self.selectedOptions.length === 1) {
				return self.selectedOptions[0].translatedText;
			}
			return self.displayName;
		},
		get filterField() {
			return self.programStore.getFilterField(self.convertedType);
		},
		get singleSelectOperators() {
			return ['after', 'eq', 'pastNMonths', 'pastNYears', 'notNull'];
		},
		get variableRightOperandOperators() {
			return ['between'];
		},
		get metadata() {
			return self.programStore.getMetadataByName(self.convertedType);
		},
		getMetadataByName(name) {
			return self.programStore.getMetadataByName(name);
		},
	}))
	.volatile(() => ({
		snapshot: null,
	}))
	.actions((self) => ({
		afterCreate() {
			self.setSnapshot(getSnapshot(self));

			onSnapshot(self, (newSnapshot) => {
				if (
					!_isEqual(newSnapshot.options, self.snapshot.options) ||
					!_isEqual(newSnapshot.rightOperand, self.snapshot.rightOperand) ||
					!_isEqual(newSnapshot.selectedIndex, self.snapshot.selectedIndex) ||
					!_isEqual(newSnapshot.customOption, self.snapshot.customOption)
				) {
					self.setSnapshot(newSnapshot);
					self.setShouldBeSaved(true);
				}
			});
		},
		addFilter({ leftOperand, operator, rightOperand, type }) {
			// Default to the length so it knows when to add a new one.
			let updateIndex = self.programStore?.filters?.expressions.length || 0;

			// Check to see if the filter for that type already exists. So it can update.
			const currentFilter = self.selectedFiltersMap.get(type);
			if (currentFilter) { updateIndex = currentFilter.index }

			// Sometimes an type could be an array. So this checks to see if any of those types exist.
			// If one does, it should override that type with the new one.
			if (Array.isArray(self.type)) {
				for (const optionType of self.type) {
					if (self.selectedFiltersMap.has(optionType)) {
						updateIndex = self.selectedFiltersMap.get(optionType).index;
					}
				}
			}

			// Make sure that there is a right operand before added filter
			if (
				!rightOperand
				|| (self.convertedType === 'clozd_amount' && !self.hasValidDealSizeSelection) // No rightOperand
				|| Array.isArray(rightOperand) && rightOperand.length === 0 // No values for array rightOperand
				|| Array.isArray(rightOperand) && self.showCustomOption && !rightOperand?.every((v) => v) // If custom option all values should be truthy
			) {
				self.programStore.removeFilter(updateIndex, false);
				self.clearSelectedOptions();
			} else {
				// If the type of filter doesn't exist, it should add a new filter to the filters.
				if (self.programStore?.filters?.expressions.length === updateIndex) {
					self.programStore.addFilter(self.programStore?.filters?.expressions.length);
				}

				self.programStore.updateFilterExpression(updateIndex, {
					type,
					leftOperand,
					operator,
					rightOperand,
				});
			}

			self.programStore.applyFilters();
			self.setShouldBeSaved(false);
		},
		clearSelectedOptions() {
			for (const option of self.options) {
				option.setSelected(false);
			}
			self.showCustomOption = false;
			self.customOption.setSelected(false);
			self.customOption.setRightOperand([null, null]);
		},
		setRightOperand(rightOperand) {
			self.rightOperand = rightOperand;
		},
		setQuickFilterOptions() {
			// This function gets the options for quick filters that don't currently have any
			// Used for dynamic filters (products, competitors, region)
			if (self.dynamicOptions) {
				if (self.convertedType === 'clozd_amount') {
					// Get the ranges for the clozd amount quick filter.
					self.options = _cloneDeep(self.programStore.amountOptions.toJSON());
				} else {
					// Get options from the filter fields
					const rightOperandOptions = self.filterField?.rightOperandOptions || [];
					const options = rightOperandOptions.reduce(
						(result, option) => (option.value ? result.concat({ ...option, rightOperand: option.value }) : result),
						[],
					);
					self.options = options;
				}
			}
			self.setVisible(!!self.options.length);
		},
		setCustomOption(showCustomOption) {
			self.showCustomOption = showCustomOption;
		},
		setSearchText(searchText) {
			self.searchText = searchText;
		},
		setSelectedIndex(selectedIndex) {
			self.selectedIndex = selectedIndex;
		},
		setSnapshot(snapshot) {
			self.snapshot = snapshot;
		},
		setVisible(visible) {
			self.visible = visible;
		},
		setShouldBeSaved(shouldBeSaved) {
			self.shouldBeSaved = shouldBeSaved;
		},
		showQuickFilter(optionsShouldBeVisible = false) {
			// If the options are being closed, and shouldBeSaved, create the filter.
			if (optionsShouldBeVisible) {
				self.syncSelectedOptionsWithFilters();
			} else {
				if (self.shouldBeSaved) {
					self.addFilter({
						leftOperand: self.convertedLeftOperand,
						operator: self.formatSelectedOptions.operator || self.operator,
						rightOperand: self.formatSelectedOptions.rightOperand,
						type: self.convertedType,
					});
				}
			}

			// Reset Scroll
			const quickFilterMenus = document.querySelectorAll('.quick-filters-scrolling');
			for (const menu of quickFilterMenus) {
				menu.scrollTo(0, 0);
			}

			self.optionsShouldBeVisible = optionsShouldBeVisible;
		},
		syncSelectedOptionsWithFilters() {
			self.clearSelectedOptions();
			self.setQuickFilterOptions();

			const searchFilters = () => {
				if (Array.isArray(self.type)) {
					for (const [index, type] of self.type.entries()) {
						if (self.selectedFiltersMap.has(type)) {
							self.setSelectedIndex(index);
							return self.selectedFiltersMap.get(type);
						}
					}
				}
				return self.selectedFiltersMap.get(self.type);
			};

			const filter = searchFilters();

			for (const option of self.options) {
				if (filter) {
					let rightOperandMatch = Array.isArray(filter?.rightOperand)
						? filter?.rightOperand.includes(option.rightOperand)
						: filter?.rightOperand === option.rightOperand;

					// There's a small chance the values could be null/undefined which should count as a rightOperandMatch
					if (
						option.rightOperand === undefined
						&& Array.isArray(filter.rightOperand)
						&& filter.rightOperand?.every(v => v === null)
					) {
						rightOperandMatch = true;
					}

					const operatorMatch = filter.operator === (option?.operator || self.operator);

					if (rightOperandMatch && operatorMatch) {
						option.setSelected(true);
					}
				}
			}

			// When loading options from the filters, sort by selected.
			if (self.operator && !self.singleSelectOperators.includes(self.operator)) {
				self.options = self.options.slice().sort((a, b) => b.selected - a.selected);
			}

			// Deal amount
			if (filter && filter.operator === 'between' && self.convertedType === 'clozd_amount') {
				self.setRightOperand([...filter.rightOperand]);
			} else if (!filter && self.convertedType === 'clozd_amount' && self.options.length > 0) {
				const min = self.options[0].rightOperand[0];
				const max = self.options[self.options.length - 1].rightOperand[1];
				self.setRightOperand([min, max]);
			}

			// Custom option (timeframe only right now)
			if (
				filter
				&& (filter.operator === 'after' || filter.operator === 'before' || filter.operator === 'between')
				&& self.customOption?.operator === 'beforeOrAfter'
				&& self.selectedOptions.length === 0

			) {
				const rightOperand = {
					after: [filter.rightOperand, null],
					before: [null, filter.rightOperand],
					between: [...filter.rightOperand],
				}[filter.operator];
				self.showCustomOption = true;
				self.customOption?.setRightOperand(rightOperand);
				self.customOption?.setSelected(true); // here
			}
		},
	}));

export default QuickFilterModel;
