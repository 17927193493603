import React from 'react';

const Program = (props) => (

	<svg {...props} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1 1.5H13C13.2761 1.5 13.5 1.72386 13.5 2V15C13.5 15.2761 13.2761 15.5 13 15.5H1C0.723858 15.5 0.5 15.2761 0.5 15V2C0.5 1.72386 0.723858 1.5 1 1.5Z" fill="transparent" stroke="currentColor" />
		<path d="M2 11.5C2 11.2239 2.22386 11 2.5 11H11.5C11.7761 11 12 11.2239 12 11.5C12 11.7761 11.7761 12 11.5 12H2.5C2.22386 12 2 11.7761 2 11.5Z" fill="currentColor" />
		<path d="M2 8.5C2 8.22386 2.22386 8 2.5 8H11.5C11.7761 8 12 8.22386 12 8.5C12 8.77614 11.7761 9 11.5 9H2.5C2.22386 9 2 8.77614 2 8.5Z" fill="currentColor" />
		<path d="M2 5.5C2 5.22386 2.22386 5 2.5 5H11.5C11.7761 5 12 5.22386 12 5.5C12 5.77614 11.7761 6 11.5 6H2.5C2.22386 6 2 5.77614 2 5.5Z" fill="currentColor" />
		<path d="M2 1C2 0.447715 2.44772 0 3 0H11C11.5523 0 12 0.447715 12 1V2C12 2.55228 11.5523 3 11 3H3C2.44772 3 2 2.55228 2 2V1Z" fill="currentColor" />
	</svg>


);

export default Program;