import React from 'react';

const Filter = (props) => (
	<svg {...props} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd" d="M0.777832 10.1378C0.777832 9.83708 1.0222 9.59332 1.32364 9.59332H12.6765C12.9779 9.59332 13.2223 9.83708 13.2223 10.1378C13.2223 10.4385 12.9779 10.6822 12.6765 10.6822H1.32364C1.0222 10.6822 0.777832 10.4385 0.777832 10.1378Z" fill={'currentColor'} />
		<path fillRule="evenodd" clipRule="evenodd" d="M0.777832 5.78221C0.777832 5.48152 1.0222 5.23776 1.32364 5.23776H12.6765C12.9779 5.23776 13.2223 5.48152 13.2223 5.78221C13.2223 6.0829 12.9779 6.32665 12.6765 6.32665H1.32364C1.0222 6.32665 0.777832 6.0829 0.777832 5.78221Z" fill={'currentColor'}/>
		<path fillRule="evenodd" clipRule="evenodd" d="M0.777832 1.86221C0.777832 1.56152 1.0222 1.31776 1.32364 1.31776H12.6765C12.9779 1.31776 13.2223 1.56152 13.2223 1.86221C13.2223 2.1629 12.9779 2.40665 12.6765 2.40665H1.32364C1.0222 2.40665 0.777832 2.1629 0.777832 1.86221Z" fill={'currentColor'}/>
		<path fillRule="evenodd" clipRule="evenodd" d="M3.94352 2.29776C4.18468 2.29776 4.38017 2.10276 4.38017 1.86221C4.38017 1.62166 4.18468 1.42665 3.94352 1.42665C3.70237 1.42665 3.50688 1.62166 3.50688 1.86221C3.50688 2.10276 3.70237 2.29776 3.94352 2.29776ZM3.94352 3.16888C4.66698 3.16888 5.25347 2.58386 5.25347 1.86221C5.25347 1.14056 4.66698 0.555542 3.94352 0.555542C3.22006 0.555542 2.63358 1.14056 2.63358 1.86221C2.63358 2.58386 3.22006 3.16888 3.94352 3.16888Z" fill={'currentColor'}/>
		<path fillRule="evenodd" clipRule="evenodd" d="M10.4932 6.21776C10.7344 6.21776 10.9299 6.02276 10.9299 5.78221C10.9299 5.54166 10.7344 5.34665 10.4932 5.34665C10.2521 5.34665 10.0566 5.54166 10.0566 5.78221C10.0566 6.02276 10.2521 6.21776 10.4932 6.21776ZM10.4932 7.08888C11.2167 7.08888 11.8032 6.50386 11.8032 5.78221C11.8032 5.06056 11.2167 4.47554 10.4932 4.47554C9.76977 4.47554 9.18329 5.06056 9.18329 5.78221C9.18329 6.50386 9.76977 7.08888 10.4932 7.08888Z" fill={'currentColor'}/>
		<path fillRule="evenodd" clipRule="evenodd" d="M4.81682 10.5733C5.05797 10.5733 5.25347 10.3783 5.25347 10.1378C5.25347 9.89721 5.05797 9.70221 4.81682 9.70221C4.57566 9.70221 4.38017 9.89721 4.38017 10.1378C4.38017 10.3783 4.57566 10.5733 4.81682 10.5733ZM4.81682 11.4444C5.54028 11.4444 6.12676 10.8594 6.12676 10.1378C6.12676 9.41611 5.54028 8.8311 4.81682 8.8311C4.09336 8.8311 3.50688 9.41611 3.50688 10.1378C3.50688 10.8594 4.09336 11.4444 4.81682 11.4444Z" fill={'currentColor'}/>
	</svg>
);

export default Filter;
