import React from 'react';

const Podium = (props) => (
	<svg {...props} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="6.5" y="9.5" width="4" height="5" fill="transparent" stroke="currentColor"/>
		<rect x="0.5" y="0.5" width="4" height="14" fill="transparent" stroke="currentColor"/>
		<rect x="12.5" y="4.5" width="4" height="10" fill="transparent" stroke="currentColor"/>
	</svg>
);

export default Podium;

