// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qf1tIsviP1VCxQ3cfqQZ{min-width:248px;padding:32px 40px;background:#fff;border:1px solid #e1e3e5;border-radius:3px;box-shadow:1px 1px 2px rgba(0,0,0,.1)}`, "",{"version":3,"sources":["webpack://./frontend/js/components/reusableComponents/Card/card.scss","webpack://./node_modules/@clozd/clozd-ui/styles/_variables.scss"],"names":[],"mappings":"AAEA,sBACC,eAAA,CACA,iBAAA,CACA,eCLe,CDMf,wBAAA,CACA,iBAAA,CACA,qCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-container": `qf1tIsviP1VCxQ3cfqQZ`
};
export default ___CSS_LOADER_EXPORT___;
