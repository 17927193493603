import React from 'react';

const Gear = (props) => (
	<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.2313 8.71398L13.1963 8.9943L13.4183 9.16897L15.0351 10.441L13.5212 13.0601L11.6194 12.2887L11.3538 12.1809L11.1266 12.3557C10.7395 12.6535 10.3454 12.8933 9.88872 13.0818L9.62389 13.1911L9.58427 13.4749L9.30151 15.5H6.26542L5.98266 13.4749L5.94241 13.1866L5.67189 13.0793C5.22319 12.9012 4.83162 12.6567 4.44032 12.3557L4.21313 12.1809L3.94753 12.2887L2.04574 13.0601L0.531808 10.441L2.14862 9.16897L2.37064 8.9943L2.3356 8.71398C2.30476 8.46726 2.28347 8.23436 2.28347 8C2.28347 7.76499 2.30485 7.52351 2.33604 7.25842L2.36911 6.97734L2.14514 6.80432L0.532223 5.55832L2.04591 2.93964L3.94913 3.70398L4.2103 3.80887L4.43547 3.64C4.82995 3.34414 5.22268 3.099 5.67189 2.92075L5.94241 2.81339L5.98266 2.52514L6.26542 0.5H9.30151L9.58427 2.52514L9.62451 2.81339L9.89504 2.92075C10.3442 3.099 10.737 3.34414 11.1315 3.64L11.3566 3.80887L11.6178 3.70398L13.521 2.93964L15.0347 5.55832L13.4218 6.80432L13.1978 6.97734L13.2309 7.25842C13.2621 7.52351 13.2835 7.76499 13.2835 8C13.2835 8.23436 13.2622 8.46726 13.2313 8.71398ZM5.45001 10.3335C6.06888 10.9523 6.90825 11.3 7.78346 11.3C8.65868 11.3 9.49805 10.9523 10.1169 10.3335C10.7358 9.71458 11.0835 8.87521 11.0835 8C11.0835 7.12479 10.7358 6.28542 10.1169 5.66655C9.49805 5.04768 8.65868 4.7 7.78346 4.7C6.90825 4.7 6.06888 5.04768 5.45001 5.66655C4.83114 6.28542 4.48346 7.12479 4.48346 8C4.48346 8.87521 4.83114 9.71458 5.45001 10.3335Z" fill="transparent" stroke="currentColor"/>
	</svg>

);

export default Gear;
