import React from 'react';

const ReportScheduleSplash = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
		<path d="M38.5 37C38.5 37.2761 38.2761 37.5 38 37.5L24.3276 37.5L17 37.5C16.7239 37.5 16.5 37.2761 16.5 37L16.5 8C16.5 7.72386 16.7239 7.5 17 7.5L38 7.5C38.2761 7.5 38.5 7.72386 38.5 8L38.5 37Z" fill="#F8F9FA" stroke="#E1E3E5" />
		<path fillRule="evenodd" clipRule="evenodd" d="M22 4C22 2.89543 22.8954 2 24 2H31C32.1046 2 33 2.89543 33 4V5H35C35.5523 5 36 5.44772 36 6V8C36 8.55228 35.5523 9 35 9H20C19.4477 9 19 8.55228 19 8V6C19 5.44772 19.4477 5 20 5H22V4ZM27.5 7C28.3284 7 29 6.32843 29 5.5C29 4.67157 28.3284 4 27.5 4C26.6716 4 26 4.67157 26 5.5C26 6.32843 26.6716 7 27.5 7Z" fill="#D8DADE" />
		<rect x="8.5" y="11.5" width="24" height="14" rx="0.5" fill="white" stroke="#E1E3E5" />
		<rect x="8.5" y="28.5" width="24" height="14" rx="0.5" fill="white" stroke="#E1E3E5" />
		<rect x="12" y="31.5" width="9" height="1.5" fill="#D8DADE" />
		<rect x="12" y="34.75" width="17" height="1.5" fill="#D8DADE" />
		<rect x="12" y="38" width="9" height="1.5" fill="#D8DADE" />
		<path d="M18.3065 23.0712C18.9311 23.4244 19.6249 23.638 20.34 23.6973C21.0552 23.7565 21.7746 23.66 22.4489 23.4144C23.1231 23.1689 23.7361 22.78 24.2456 22.2747C24.7552 21.7694 25.1491 21.1597 25.4002 20.4875C25.6514 19.8153 25.7538 19.0966 25.7005 18.381C25.6472 17.6654 25.4393 16.9699 25.0913 16.3423C24.7433 15.7148 24.2634 15.1701 23.6846 14.7459C23.1058 14.3217 22.4419 14.0281 21.7387 13.8851" stroke="#D8DADE" />
		<path d="M19.7637 13.8846C18.9292 14.0538 18.1522 14.4347 17.5074 14.9909C16.8627 15.5471 16.3718 16.2597 16.082 17.0604C15.7922 17.861 15.7132 18.7228 15.8526 19.5628C15.9921 20.4027 16.3452 21.1928 16.8781 21.8569" stroke="#D8DADE" />
	</svg>
);

export default ReportScheduleSplash;
