import { types, flow, getEnv } from 'mobx-state-tree';
import { when } from 'mobx';
import request from '@app/js/api/request';
import _get from 'lodash/get';
import { add, isAfter, isBefore, min } from '@utils/dateTimeService';
import { recordTiming } from '../utils/performance';

const { optional, maybeNull, model, string, boolean } = types;

const DEFAULT_MOBILE_WIDTH = 640;

// Model
export const AppStoreModel = model('AppStore', {
	serverConfig: maybeNull(string),
	isLoadingServerConfig: optional(boolean, false),
	showBanner: optional(boolean, false),
	showMobileMenu: optional(boolean, false),
	showTopBar: optional(boolean, true),
	showSharedTopBar: optional(boolean, false),
	showConsultancySideMenu: optional(boolean, true),
	showCustomTopBar: optional(boolean, false),
	isPrintMode: optional(boolean, false),
})
	.views((self) => ({
		get userStore() {
			return getEnv(self).UserStore;
		},
		get baseURL() {
			return '/';
		},
		get isIFrame() {
			return self.userStore.isIFrame || window.location !== window.parent.location;
		},
		get parsedServerConfig() {
			return JSON.parse(self.serverConfig);
		},
		get clozdFields() {
			if (!self.parsedServerConfig) {
				return {};
			}
			return self.parsedServerConfig.fields || {};
		},
		get baseBannerDate() {
			return new Date('Tue Aug 30 2022 09:00:00 GMT-0600 (Mountain Daylight Time)');
		},
		get endBannerDate() {
			return new Date('Wed Aug 31 2022 17:00:00 GMT-0600 (Mountain Daylight Time)');
		},
		get clozdDefaultInterviewDomain() {
			return {
				domain_id: 2024743,
				domain: 'invitations.clozd.com',
			};
		},
		get clozdDefaultSurveyDomain() {
			return {
				domain_id: 1956202,
				domain: 'survey.clozd.com',
			};
		},
		isMobile(width = DEFAULT_MOBILE_WIDTH) {
			return self.screenWidth <= width;
		},
	}))
	.volatile(() => ({
		customTopBar: null,
		screenWidth: Infinity,
		mediaQueryList: null,
	}))
	.actions((self) => ({
		afterCreate() {
			when(
				() => !!self.userStore.user,
				() => self.fetchServerConfig(),
			);

			window.addEventListener('resize', self.updateScreenWidth);
			self.updateScreenWidth();

			window.addEventListener('load', self.logLoadTime);

			// listen to changes to "print" view
			self.mediaQueryList = window?.matchMedia?.('print');
			self.mediaQueryList?.addEventListener?.('change', self.handlePrintChange);
			window.addEventListener('beforeprint', self.handleBeforePrint);
			window.addEventListener('afterprint', self.handleAfterPrint);

			// close and hide the Zendesk window on app load
			window?.zE?.('messenger', 'close');
		},
		beforeDestroy() {
			window.removeEventListener('resize', self.updateScreenWidth);
			window.removeEventListener('load', self.logLoadTime);
			self.mediaQueryList?.removeEventListener?.('change', self.handlePrintChange);
			window.removeEventListener('beforeprint', self.handleBeforePrint);
			window.removeEventListener('afterprint', self.handleAfterPrint);
		},
		handlePrintChange(event) {
			self.setIsPrintMode(event.matches);
		},
		handleBeforePrint() {
			self.setIsPrintMode(true);
		},
		handleAfterPrint() {
			self.setIsPrintMode(false);
		},
		setIsPrintMode(isPrintMode) {
			self.isPrintMode = isPrintMode;
		},
		logLoadTime() {
			setTimeout(() => {
				const action = 'app_load';
				const perfEntries = performance?.getEntriesByType?.('navigation');
				if (!perfEntries) return;
				const [performanceNavigationTiming] = perfEntries;
				recordTiming(
					action,
					performanceNavigationTiming.loadEventEnd,
					{
						baseUrl: `${window.location.protocol}//${window.location.host}`,
					},
				);
			}, 5000);
		},
		updateScreenWidth() {
			self.screenWidth = window.innerWidth;
		},
		resetError() {
			self.errorCode = '';
		},
		setShowCustomTopBar(showCustomTopBar) {
			self.showCustomTopBar = showCustomTopBar;
		},
		setShowConsultancySideMenu(showConsultancySideMenu) {
			self.showConsultancySideMenu = showConsultancySideMenu;
		},
		initializeBanner() {
			const expireDate = window.localStorage.getItem('winloss-banner-expire');
			if (expireDate && isBefore(new Date(), new Date(expireDate))) return;
			window.localStorage.removeItem('winloss-banner-expire');
			if (isAfter(new Date(), self.endBannerDate)) return;

			self.showBanner = true;
		},
		toggleShowMobileMenu() {
			self.showMobileMenu = !self.showMobileMenu;
		},
		hideMobileMenu() {
			self.showMobileMenu = false;
		},
		hideBanner() {
			self.setShowBanner(false);
			const firstDate = isAfter(new Date(), self.baseBannerDate) ? add(new Date(), { hours: 12 }) : self.baseBannerDate; // This will make it show up at least on the start dated and every 12 hours after.
			const secondDate = add(new Date(), { weeks: 1 }); // Current date + 1 week

			window.localStorage.setItem('winloss-banner-expire', min([firstDate, secondDate])); // Want to use the nearest date from the above options.
		},
		setShowBanner(value) {
			self.showBanner = value;
		},
		setCustomTopBar(customTopBar) {
			self.customTopBar = customTopBar;
		},
		setErrorCode(error) {
			self.errorCode = _get(error, 'response.data.errorCode', '');
		},
		setShowTopBar(showTopBar) {
			self.showTopBar = showTopBar;
		},
		setShowSharedTopBar(showSharedTopBar) {
			self.showSharedTopBar = showSharedTopBar;
		},
		updateIsLoadingServerConfig(isLoadingServerConfig) {
			self.isLoadingServerConfig = isLoadingServerConfig;
		},
		updateServerConfig(serverConfig) {
			self.serverConfig = serverConfig;
		},
		fetchServerConfig: flow(function* fetchServerConfig() {
			try {
				self.updateIsLoadingServerConfig(true);
				const response = yield request.get('/app/server-config/');
				if (response.data.data) {
					self.updateServerConfig(JSON.stringify(response.data.data));
				}
				self.updateIsLoadingServerConfig(false);
			} catch (error) {
				self.setErrorCode(error);
			}
		}),
	}));
