import React from 'react';

const ThreeDots = ({ spacing = 5, dotDiameter = 2, ...props }) => {
	const dotRadius = dotDiameter / 2;
	return (
		<svg {...props} viewBox={`0 0 ${(3 * dotDiameter) + (2 * spacing) + 2} ${dotDiameter + 2}`} fill="none" xmlns="http://www.w3.org/2000/svg">
			{Array(3).fill(null).map((_, i) => (
				<circle key={i} cx={(1 + (i * (spacing + dotDiameter)) + (dotRadius))} cy={1 + dotRadius} r={dotRadius} fill="currentColor"/>
			))}
		</svg>
	);
};

export default ThreeDots;

