import React from 'react';

const WinRates = (props) => (
	<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask id="path-1-inside-1" fill="white">
			<path fillRule="evenodd" clipRule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z" />
		</mask>
		<path d="M15 8C15 11.866 11.866 15 8 15V17C12.9706 17 17 12.9706 17 8H15ZM8 1C11.866 1 15 4.13401 15 8H17C17 3.02944 12.9706 -1 8 -1V1ZM1 8C1 4.13401 4.13401 1 8 1V-1C3.02944 -1 -1 3.02944 -1 8H1ZM8 15C4.13401 15 1 11.866 1 8H-1C-1 12.9706 3.02944 17 8 17V15ZM11 8C11 9.65685 9.65685 11 8 11V13C10.7614 13 13 10.7614 13 8H11ZM8 5C9.65685 5 11 6.34315 11 8H13C13 5.23858 10.7614 3 8 3V5ZM5 8C5 6.34315 6.34315 5 8 5V3C5.23858 3 3 5.23858 3 8H5ZM8 11C6.34315 11 5 9.65685 5 8H3C3 10.7614 5.23858 13 8 13V11Z" fill="currentColor" mask="url(#path-1-inside-1)" />
		<line x1="8.5" y1="1" x2="8.5" y2="4" stroke="currentColor" />
		<line x1="12.1039" y1="9.7898" x2="14.6436" y2="11.3868" stroke="currentColor" />
	</svg>
);

export default WinRates;

