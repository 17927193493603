import { types } from 'mobx-state-tree';
const {
	model,
	string,
	maybeNull,
	optional,
	number,
} = types;

const DriverRatingDivisionModel = model('DriverRatingDivisionModel', {
	[-2]: optional(number, 0),
	[-1]: optional(number, 0),
	[0]: optional(number, 0),
	[1]: optional(number, 0),
	[2]: optional(number, 0),
	average: optional(number, 0),
	rating_count: optional(number, 0),
	deals_rated: optional(number, 0),
	total_count: optional(number, 0),
	weightedScore: optional(number, 0),
});

const DriverRatingModel = model('DriverRatingModel', {
	driver_id: optional(string, ''),
	name: optional(string, ''),
	parent_id: maybeNull(string),
	parentName: maybeNull(string),
	average: optional(number, 0),
	rating_count: optional(number, 0),
	deals_rated: optional(number, 0),
	total_count: optional(number, 0),
	weightedScore: optional(number, 0),
	winRate: maybeNull(number),
	win: optional(DriverRatingDivisionModel, {}),
	loss: optional(DriverRatingDivisionModel, {}),
	new_business: optional(DriverRatingDivisionModel, {}),
	existing_business: optional(DriverRatingDivisionModel, {}),
	new_win: optional(DriverRatingDivisionModel, {}),
	new_loss: optional(DriverRatingDivisionModel, {}),
	existing_win: optional(DriverRatingDivisionModel, {}),
	existing_loss: optional(DriverRatingDivisionModel, {}),
	unmapped: optional(DriverRatingDivisionModel, {}),
});

export default DriverRatingModel;
