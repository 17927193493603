import React from 'react';

const Programs = (props) => (
	<svg {...props} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1 0.5H12C12.2761 0.5 12.5 0.723858 12.5 1V14C12.5 14.2761 12.2761 14.5 12 14.5H1C0.723858 14.5 0.5 14.2761 0.5 14V1C0.5 0.723858 0.723858 0.5 1 0.5Z" fill="white" stroke="currentColor" />
		<path d="M3 1.5H14C14.2761 1.5 14.5 1.72386 14.5 2V15C14.5 15.2761 14.2761 15.5 14 15.5H3C2.72386 15.5 2.5 15.2761 2.5 15V2C2.5 1.72386 2.72386 1.5 3 1.5Z" fill="white" stroke="currentColor" />
		<path d="M5 2.5H16C16.2761 2.5 16.5 2.72386 16.5 3V16C16.5 16.2761 16.2761 16.5 16 16.5H5C4.72386 16.5 4.5 16.2761 4.5 16V3C4.5 2.72386 4.72386 2.5 5 2.5Z" fill="white" stroke="currentColor" />
		<path d="M6 12.5C6 12.2239 6.22386 12 6.5 12H14.5C14.7761 12 15 12.2239 15 12.5V12.5C15 12.7761 14.7761 13 14.5 13H6.5C6.22386 13 6 12.7761 6 12.5V12.5Z" fill="currentColor"/>
		<path d="M6 9.5C6 9.22386 6.22386 9 6.5 9H14.5C14.7761 9 15 9.22386 15 9.5V9.5C15 9.77614 14.7761 10 14.5 10H6.5C6.22386 10 6 9.77614 6 9.5V9.5Z" fill="currentColor"/>
		<path d="M6 6.5C6 6.22386 6.22386 6 6.5 6H14.5C14.7761 6 15 6.22386 15 6.5V6.5C15 6.77614 14.7761 7 14.5 7H6.5C6.22386 7 6 6.77614 6 6.5V6.5Z" fill="currentColor"/>
		<path d="M6 2C6 1.44772 6.44772 1 7 1H14C14.5523 1 15 1.44772 15 2V3C15 3.55228 14.5523 4 14 4H7C6.44772 4 6 3.55228 6 3V2Z" fill="currentColor"/>
	</svg>
);

export default Programs;