import React from 'react';

const Tag = (props) =>
	!!props.old
		? (
			<svg {...props} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M9.32403 0.791442L9.32519 0.791639L15.784 1.88957L16.8757 8.34184L16.876 8.34338C16.9469 8.7549 16.8228 9.19647 16.5108 9.50845L9.39813 16.6211C9.14892 16.8703 8.81092 17.0103 8.45848 17.0103C8.10604 17.0103 7.76803 16.8703 7.51882 16.6211L1.05276 10.1551C0.80355 9.90585 0.663544 9.56784 0.663544 9.2154C0.663544 8.86296 0.80355 8.52496 1.05276 8.27574L8.16542 1.16308C8.48107 0.847437 8.91778 0.721397 9.32403 0.791442Z" fill="transparent" stroke="currentColor"/>
				<ellipse cx="12.591" cy="5" rx="1.125" ry="1.125" transform="rotate(-45 12.591 5)" fill="transparent" stroke="currentColor"/>
			</svg>
		)
		: (
			<svg {...props} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6.90249 1.92896L2.21851 6.61294L5.38726 9.78169L10.0712 5.0977L9.79702 2.20317L6.90249 1.92896ZM7.9689 5.06255C7.40054 5.06255 6.93765 4.59966 6.93765 4.0313C6.93765 3.46294 7.40054 3.00005 7.9689 3.00005C8.53726 3.00005 9.00015 3.46294 9.00015 4.0313C9.00015 4.59966 8.53726 5.06255 7.9689 5.06255Z" fill="currentColor" fillOpacity="0.2"/>
				<path d="M7.9688 3C7.40044 3 6.93755 3.46289 6.93755 4.03125C6.93755 4.59961 7.40044 5.0625 7.9688 5.0625C8.53716 5.0625 9.00005 4.59961 9.00005 4.03125C9.00005 3.46289 8.53716 3 7.9688 3ZM7.9688 4.40625C7.76138 4.40625 7.5938 4.23867 7.5938 4.03125C7.5938 3.82383 7.76138 3.65625 7.9688 3.65625C8.17622 3.65625 8.3438 3.82383 8.3438 4.03125C8.3438 4.23867 8.17622 4.40625 7.9688 4.40625Z" fill="currentColor"/>
				<path d="M10.9922 5.37656L10.6454 1.71328C10.6278 1.52344 10.4766 1.37344 10.2868 1.35469L6.62348 1.00781H6.61879C6.58129 1.00781 6.552 1.01953 6.52973 1.0418L1.04184 6.52969C1.03098 6.54053 1.02236 6.55341 1.01648 6.56758C1.0106 6.58176 1.00757 6.59696 1.00757 6.6123C1.00757 6.62765 1.0106 6.64285 1.01648 6.65703C1.02236 6.6712 1.03098 6.68408 1.04184 6.69492L5.30512 10.9582C5.31603 10.9691 5.32901 10.9777 5.3433 10.9836C5.35758 10.9894 5.37289 10.9923 5.38832 10.9922C5.41996 10.9922 5.44926 10.9805 5.47153 10.9582L10.9594 5.47031C10.9829 5.4457 10.9946 5.41172 10.9922 5.37656ZM5.38715 9.78164L2.2184 6.61289L6.90239 1.92891L9.79692 2.20312L10.0711 5.09766L5.38715 9.78164Z" fill="currentColor"/>
			</svg>


		);

export default Tag;
