import { reaction } from 'mobx';
import { types, getEnv, flow } from 'mobx-state-tree';
import { setDayOfYear } from '@utils/dateTimeService';
import QuickFilterModel from '../baseModels/quickFilter.baseModel';

const { model, string, optional, map, boolean } = types;

export const QuickFilterStoreModel = model('QuickFilterStoreModel', {
	quickFilters: map(QuickFilterModel),
	selectedFilter: optional(string, ''),
	shouldIncludeAllFields: optional(boolean, false),
})
	.volatile(() => ({
		disposer: null,
	}))
	.views((self) => ({
		get programStore() {
			return getEnv(self).ProgramStore;
		},
		get metadataDefinitions() {
			return self.programStore.metadata.reduce((
				definitions,
				metadataField,
			) => {
				// filter out, don't include
				if (metadataField.is_clozd_field) {
					return definitions;
				}

				// alter format and add to array
				let definition;
				switch (metadataField.sub_type) {
					case 'picklist':
						if (metadataField.options.picklist_type === 'single-select') {
							definition = {
								leftOperand: metadataField.metadata_definition_id,
								type: metadataField.name,
								showSearch: true,
								operator: 'in',
								options: [],
								dynamicOptions: true,
							};
						} else {
							definition = {
								leftOperand: metadataField.metadata_definition_id,
								type: metadataField.name,
								showSearch: true,
								operator: 'any',
								options: [],
								dynamicOptions: true,
							};
						}
						break;
					case 'date':
					case 'datetime':
						definition = {
							leftOperand: metadataField.metadata_definition_id,
							type: metadataField.name,
							selectedIndex: 0,
							options: [
								{
									operator: 'pastNMonths', // maps to operator
									translationKey: 'lastNMonthsRange',
									rightOperand: 1,
								},
								{
									operator: 'pastNMonths',
									translationKey: 'lastNMonthsRange',
									rightOperand: 3,
								},
								{
									operator: 'pastNMonths',
									translationKey: 'lastNMonthsRange',
									rightOperand: 6,
								},
								{
									operator: 'pastNMonths',
									translationKey: 'lastNMonthsRange',
									rightOperand: 12,
								},
								{
									operator: 'after',
									translationKey: 'yearToDate',
									rightOperand: setDayOfYear(Date.now(), 1).toISOString(),
								},
								{
									operator: 'notNull',
									translationKey: 'allTime',
								},
							],
							customOption: {
								operator: 'beforeOrAfter',
								translationKey: 'custom',
								rightOperand: [null, null], // Need to initialize two indices. So they can be referenced.
							},
						};
						break;
					case 'integer':
					case 'decimal':
					case 'percent':
					case 'currency':
						definition = {
							leftOperand: metadataField.metadata_definition_id,
							type: metadataField.name,
							operator: 'between',
							options: [{ rightOperand: [-Infinity, Infinity] }],
							dynamicOptions: false,
							rightOperand: [0, 0],
						};
						break;
					default:
						return definitions;
				}
				const quickFilterName = `_metadata_${definition.leftOperand}`;
				definition.quickFilterName = quickFilterName;
				definitions[quickFilterName] = definition;
				return definitions;
			}, {});
		},
		get quickFiltersDefinition() {
			return {
				feedbackChannel: {
					quickFilterName: 'feedbackChannel',
					leftOperand: 'response.channel',
					type: 'clozd_channel',
					operator: 'in',
					options: [
						{
							rightOperand: 'buyer interview',
							translationKey: 'program.global.buyerInterview',
						},
						{
							rightOperand: 'buyer survey',
							translationKey: 'program.global.buyerSurvey',
						},
						{
							rightOperand: 'rep interview',
							translationKey: 'program.global.salesRepInterview',
						},
						{
							rightOperand: 'rep survey',
							translationKey: 'program.global.salesRepSurvey',
						},
					],
				},
				dealSize: {
					quickFilterName: 'dealSize',
					leftOperand: 'deal.amount',
					type: 'clozd_amount',
					operator: 'between',
					options: [],
					dynamicOptions: true,
				},
				outcome: {
					quickFilterName: 'outcome',
					leftOperand: 'deal.outcome',
					type: 'clozd_outcome',
					operator: 'eq',
					options: [
						{
							rightOperand: 'win',
							translationKey: 'program.global.win',
						},
						{
							rightOperand: 'loss',
							translationKey: 'program.global.loss',
						},
					],
				},
				outcomeType: {
					quickFilterName: 'outcomeType',
					leftOperand: 'deal.outcome_type',
					type: 'clozd_outcome_type',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				region: {
					quickFilterName: 'region',
					leftOperand: 'organization_entity.region',
					type: 'clozd_region',
					showSearch: true,
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				segment: {
					quickFilterName: 'segment',
					leftOperand: 'organization_entity.segment',
					type: 'clozd_segment',
					showSearch: true,
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				industry: {
					quickFilterName: 'industry',
					leftOperand: 'organization_entity.industry',
					type: 'clozd_industry',
					showSearch: true,
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				product: {
					quickFilterName: 'product',
					leftOperand: 'deal_product.product_id',
					type: 'clozd_products',
					showSearch: true,
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				competitor: {
					quickFilterName: 'competitor',
					leftOperand: 'response.primary_competitor',
					type: 'clozd_primary_competitor',
					showSearch: true,
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				currency: {
					quickFilterName: 'currency',
					leftOperand: 'deal.currency',
					type: 'clozd_currency',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				createdDate: {
					quickFilterName: 'createdDate',
					leftOperand: 'deal.deal_created',
					type: 'clozd_created_date',
					selectedIndex: 0,
					options: [
						{
							operator: 'pastNMonths', // maps to operator
							translationKey: 'lastNMonthsRange',
							rightOperand: 1,
						},
						{
							operator: 'pastNMonths',
							translationKey: 'lastNMonthsRange',
							rightOperand: 3,
						},
						{
							operator: 'pastNMonths',
							translationKey: 'lastNMonthsRange',
							rightOperand: 6,
						},
						{
							operator: 'pastNMonths',
							translationKey: 'lastNMonthsRange',
							rightOperand: 12,
						},
						{
							operator: 'after',
							translationKey: 'yearToDate',
							rightOperand: setDayOfYear(Date.now(), 1).toISOString(),
						},
						{
							operator: 'notNull',
							translationKey: 'allTime',
						},
					],
					customOption: {
						operator: 'beforeOrAfter',
						translationKey: 'custom',
						rightOperand: [null, null], // Need to initialize two indices. So they can be referenced.
					},
				},
				closedDate: {
					quickFilterName: 'closedDate',
					leftOperand: 'deal.closed_date',
					type: 'clozd_closed_date',
					selectedIndex: 0,
					options: [
						{
							operator: 'pastNMonths', // maps to operator
							translationKey: 'lastNMonthsRange',
							rightOperand: 1,
						},
						{
							operator: 'pastNMonths',
							translationKey: 'lastNMonthsRange',
							rightOperand: 3,
						},
						{
							operator: 'pastNMonths',
							translationKey: 'lastNMonthsRange',
							rightOperand: 6,
						},
						{
							operator: 'pastNMonths',
							translationKey: 'lastNMonthsRange',
							rightOperand: 12,
						},
						{
							operator: 'after',
							translationKey: 'yearToDate',
							rightOperand: setDayOfYear(Date.now(), 1).toISOString(),
						},
						{
							operator: 'notNull',
							translationKey: 'allTime',
						},
					],
					customOption: {
						operator: 'beforeOrAfter',
						translationKey: 'custom',
						rightOperand: [null, null], // Need to initialize two indices. So they can be referenced.
					},
				},
				leadSource: {
					quickFilterName: 'leadSource',
					leftOperand: 'deal.lead_source',
					type: 'clozd_lead_source',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				participantType: {
					quickFilterName: 'participantType',
					leftOperand: 'deal_participant.participant_type',
					type: 'clozd_participant_type',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				participantRole: {
					quickFilterName: 'participantName',
					leftOperand: 'deal_participant.participant_role',
					type: 'clozd_participant_role',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				headcount: {
					quickFilterName: 'headcount',
					leftOperand: 'organization_entity.headcount',
					type: 'clozd_headcount',
					operator: 'between',
					options: [{ rightOperand: [-Infinity, Infinity] }],
					dynamicOptions: false,
					rightOperand: [0, 0],
				},
				revenue: {
					quickFilterName: 'revenue',
					leftOperand: 'organization_entity.revenue',
					type: 'clozd_revenue',
					operator: 'between',
					options: [{ rightOperand: [-Infinity, Infinity] }],
					dynamicOptions: false,
					rightOperand: [0, 0],
				},
				publishDate: {
					quickFilterName: 'publishDate',
					leftOperand: 'response.publish_date',
					type: 'clozd_publish_date',
					selectedIndex: 0,
					options: [
						{
							operator: 'pastNMonths', // maps to operator
							translationKey: 'lastNMonthsRange',
							rightOperand: 1,
						},
						{
							operator: 'pastNMonths',
							translationKey: 'lastNMonthsRange',
							rightOperand: 3,
						},
						{
							operator: 'pastNMonths',
							translationKey: 'lastNMonthsRange',
							rightOperand: 6,
						},
						{
							operator: 'pastNMonths',
							translationKey: 'lastNMonthsRange',
							rightOperand: 12,
						},
						{
							operator: 'after',
							translationKey: 'yearToDate',
							rightOperand: setDayOfYear(Date.now(), 1).toISOString(),
						},
						{
							operator: 'notNull',
							translationKey: 'allTime',
						},
					],
					customOption: {
						operator: 'beforeOrAfter',
						translationKey: 'custom',
						rightOperand: [null, null], // Need to initialize two indices. So they can be referenced.
					},
				},
				category: {
					quickFilterName: 'category',
					leftOperand: 'response_driver.driver_id',
					type: 'clozd_category',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				driver: {
					quickFilterName: 'driver',
					leftOperand: 'response_driver.driver_id',
					type: 'clozd_driver',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				rating: {
					quickFilterName: 'rating',
					leftOperand: 'response_driver.rating',
					type: 'clozd_rating',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				tagCategory: {
					quickFilterName: 'tagCategory',
					leftOperand: 'deal_tag.tag_id',
					type: 'clozd_tag_category',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				tag: {
					quickFilterName: 'tag',
					leftOperand: 'deal_tag.tag_id',
					type: 'clozd_tag_tag',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				flag: {
					quickFilterName: 'flag',
					leftOperand: 'deal_flag.flag_id',
					type: 'clozd_flag',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				decision: {
					quickFilterName: 'decision',
					leftOperand: 'response.decision',
					type: 'clozd_decision',
					operator: 'in',
					options: [],
					dynamicOptions: true,
				},
				interviewType: {
					quickFilterName: 'interviewType',
					leftOperand: 'response.interview_type',
					type: 'clozd_interview_type',
					operator: 'in',
					options: [
						{
							rightOperand: 'premium',
							translationKey: 'program.global.premium',
						},
						{
							rightOperand: 'premium_async',
							translationKey: 'program.global.premium_async',
						},
						{
							rightOperand: 'scale',
							translationKey: 'program.global.scale',
						},
						{
							rightOperand: 'none',
							translationKey: 'program.global.none',
						},
					],
				},
				...self.metadataDefinitions,
			};
		},
		get customQuickFiltersObject() {
			const customQuickFilterFields = new Set(self.programStore.customQuickFilterOrder.map(q => q.type));

			for (const field in self.quickFiltersDefinition) {
				if (Array.isArray(self.quickFiltersDefinition[field].type)) {
					const fieldFound = new Set(self.quickFiltersDefinition[field].type.map(type => customQuickFilterFields.has(type)));
					if (fieldFound.has(false)) {
						delete self.quickFiltersDefinition[field];
					}
				} else if (!customQuickFilterFields.has(self.quickFiltersDefinition[field].type)) {
					delete self.quickFiltersDefinition[field];
				}
				if (self.quickFiltersDefinition[field]) {
					self.quickFiltersDefinition[field].order = self.programStore.customQuickFilterOrder.find(q => q.type === self.quickFiltersDefinition[field].type).order;
				}
			}

			return self.quickFiltersDefinition;
		},
		get quickFiltersArray() {
			return Array.from(self.quickFilters);
		},
		get customFilters() {
			return self.quickFiltersArray.filter(([key]) => key.startsWith('_metadata_'));
		},
		get isCustomFilterSelected() {
			return !!self.customFilters.find(([, definition]) => definition.selectedText === self.selectedFilter);
		},
	}))
	.actions((self) => ({
		afterCreate() {
			self.disposer = reaction(
				() => self.programStore.filtersString,
				() => self.resetQuickFilters(),
			);
		},
		beforeDestroy() {
			self.disposer?.();
		},
		setSelectedFilter(key) {
			self.selectedFilter = key;
		},
		setShouldIncludeAllFields(shouldIncludeAllFields) {
			self.shouldIncludeAllFields = shouldIncludeAllFields;
			self.quickFilters = self.quickFiltersDefinition;
		},
		resetQuickFilters: flow(function* resetQuickFilters() {
			if (self.programStore.loading) {
				yield self.programStore.loading; // Wait for the Program Store to finish loading new stuff before contining.
			}
			self.quickFilters = self.customQuickFiltersObject;
			for (const [, quickFilter] of self.quickFiltersArray) {
				quickFilter.syncSelectedOptionsWithFilters();
			}
		}),
	}));
