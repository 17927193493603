import { types } from 'mobx-state-tree';
const { model, string, integer, boolean, optional, maybeNull, array } = types;

const FlagBaseModel = model('flagModel', {
	flag_id: optional(string, ''),
	name: optional(string, ''),
	short_name: optional(string, ''),
	description: optional(string, ''),
	icon: optional(string, ''),
	is_system_flag: optional(boolean, false),
	attributes: optional(model({
		email_notification_metadata_field_ids: array(string),
		fields: array(model({
			name: optional(string, ''),
			type: optional(string, ''),
			maxChars: optional(integer, 0),
			required: optional(boolean, false),
			placeholder: optional(string, ''),
		})),
	}), {}),
	created_at: maybeNull(string),
	updated_at: maybeNull(string),
	deleted_at: maybeNull(string),
})
	.views(self => ({
		get hasNotificationFields() {
			return Array.isArray(self.attributes?.email_notification_metadata_field_ids) && self.attributes?.email_notification_metadata_field_ids?.length > 0;
		},
	}))
	.actions(self => ({
		changeEmailMetadataFields(fields) {
			self.attributes.email_notification_metadata_field_ids = fields;
		},
		addMetadataToFlag(field) {
			const currentFields = self?.attributes?.email_notification_metadata_field_ids ?? [];
			currentFields.push(field);
			self.changeEmailMetadataFields(currentFields);
		},
	}));

export default FlagBaseModel;
