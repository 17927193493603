
import React from 'react';

const Share = (props) => (
	<svg {...props} viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M10.3124 9.37451C9.86707 9.37451 9.45613 9.53076 9.13425 9.7917L5.89675 7.44951C5.95096 7.15196 5.95096 6.84706 5.89675 6.54951L9.13425 4.20732C9.45613 4.46826 9.86707 4.62451 10.3124 4.62451C11.3468 4.62451 12.1874 3.78389 12.1874 2.74951C12.1874 1.71514 11.3468 0.874512 10.3124 0.874512C9.278 0.874512 8.43738 1.71514 8.43738 2.74951C8.43738 2.93076 8.46238 3.1042 8.51082 3.26982L5.43582 5.49639C4.97957 4.8917 4.25457 4.49951 3.43738 4.49951C2.05613 4.49951 0.937378 5.61826 0.937378 6.99951C0.937378 8.38076 2.05613 9.49951 3.43738 9.49951C4.25457 9.49951 4.97957 9.10733 5.43582 8.50264L8.51082 10.7292C8.46238 10.8948 8.43738 11.0698 8.43738 11.2495C8.43738 12.2839 9.278 13.1245 10.3124 13.1245C11.3468 13.1245 12.1874 12.2839 12.1874 11.2495C12.1874 10.2151 11.3468 9.37451 10.3124 9.37451ZM10.3124 1.93701C10.7608 1.93701 11.1249 2.30107 11.1249 2.74951C11.1249 3.19795 10.7608 3.56201 10.3124 3.56201C9.86394 3.56201 9.49988 3.19795 9.49988 2.74951C9.49988 2.30107 9.86394 1.93701 10.3124 1.93701ZM3.43738 8.37451C2.67957 8.37451 2.06238 7.75733 2.06238 6.99951C2.06238 6.2417 2.67957 5.62451 3.43738 5.62451C4.19519 5.62451 4.81238 6.2417 4.81238 6.99951C4.81238 7.75733 4.19519 8.37451 3.43738 8.37451ZM10.3124 12.062C9.86394 12.062 9.49988 11.698 9.49988 11.2495C9.49988 10.8011 9.86394 10.437 10.3124 10.437C10.7608 10.437 11.1249 10.8011 11.1249 11.2495C11.1249 11.698 10.7608 12.062 10.3124 12.062Z" fill="currentColor"/>
	</svg>
);

export default Share;
