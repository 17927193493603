import React from 'react';

const DotsBubble = (props) => (
	<svg {...props} viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.04184 13.51C1.36495 13.51 0 12.0733 0 10.3154V3.19133C0 1.43173 1.36495 0 3.04184 0H13.9598C15.6367 0 17 1.43173 17 3.19133V10.3154C17 12.0733 15.6367 13.51 13.9598 13.51H3.04184ZM3.04184 1.31805C2.09282 1.31805 1.32039 2.15831 1.32039 3.19133V10.3154C1.32039 11.3468 2.09282 12.192 3.04184 12.192H13.9598C14.9088 12.192 15.6796 11.3468 15.6796 10.3154V3.19133C15.6796 2.15831 14.9088 1.31805 13.9598 1.31805H3.04184Z" fill="currentColor"/>
		<path d="M4.40844 8.22954C5.15317 8.22954 5.75689 7.62689 5.75689 6.88349C5.75689 6.14008 5.15317 5.53743 4.40844 5.53743C3.66372 5.53743 3.06 6.14008 3.06 6.88349C3.06 7.62689 3.66372 8.22954 4.40844 8.22954Z" fill="currentColor"/>
		<path d="M8.50163 8.22954C9.24636 8.22954 9.85008 7.62689 9.85008 6.88349C9.85008 6.14008 9.24636 5.53743 8.50163 5.53743C7.7569 5.53743 7.15318 6.14008 7.15318 6.88349C7.15318 7.62689 7.7569 8.22954 8.50163 8.22954Z" fill="currentColor"/>
		<path d="M12.5932 8.22954C13.3379 8.22954 13.9416 7.62689 13.9416 6.88349C13.9416 6.14008 13.3379 5.53743 12.5932 5.53743C11.8485 5.53743 11.2447 6.14008 11.2447 6.88349C11.2447 7.62689 11.8485 8.22954 12.5932 8.22954Z" fill="currentColor"/>
	</svg>
);

export default DotsBubble;