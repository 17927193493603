import React from 'react';

const Pencil = (props) => (
	<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="9.77783" y="2.6665" width="5.02831" height="10.0566" transform="rotate(45 9.77783 2.6665)" fill="currentColor"/>
		<path d="M1.77776 14.2222L2.66665 9.77778L6.22221 13.3333L1.77776 14.2222Z" fill="currentColor"/>
		<path d="M11.7374 0.707107C12.1279 0.316582 12.761 0.316582 13.1516 0.707107L15.2929 2.84845C15.6834 3.23897 15.6834 3.87214 15.2929 4.26266L14.2222 5.33333L10.6667 1.77778L11.7374 0.707107Z" fill="currentColor"/>
	</svg>

);

export default Pencil;

