import React from 'react';

const Bookmark = ({ type = 'solid', ...props }) => (
	<svg {...props} viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1.02946 0.777771C0.66127 0.777771 0.362793 1.0687 0.362793 1.42758V12.5711C0.362793 13.1441 1.06837 13.4366 1.49185 13.0392L4.56707 10.1532C4.82522 9.91095 5.2337 9.91095 5.49185 10.1532L8.56707 13.0392C8.99055 13.4366 9.69613 13.1441 9.69613 12.5711V1.42758C9.69613 1.0687 9.39765 0.777771 9.02946 0.777771H1.02946Z" fill={type === 'bordered' ? 'transparent' : 'currentColor'}/>
		{type === 'bordered' && (
			<path fillRule="evenodd" clipRule="evenodd" d="M1.69613 2.07739V11.0434L3.64228 9.21703C4.41676 8.49021 5.64217 8.49021 6.41664 9.21703L8.36279 11.0434V2.07739H1.69613ZM1.02946 0.777779C0.66127 0.777779 0.362793 1.06871 0.362793 1.42759V12.5711C0.362793 13.1441 1.06837 13.4366 1.49185 13.0392L4.56707 10.1532C4.82522 9.91096 5.23369 9.91096 5.49185 10.1532L8.56707 13.0392C8.99055 13.4366 9.69613 13.1441 9.69613 12.5711V1.42758C9.69613 1.06871 9.39765 0.777779 9.02946 0.777779H1.02946Z" fill="currentColor" />
		)}
	</svg>
);

export default Bookmark;
