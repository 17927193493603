import React from 'react';

const DeconstructedLogoLeft = (props) => (
	<svg {...props} width="150" height="144" viewBox="0 0 150 144" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g opacity="0.5">
			<path d="M149.852 83.7096L149.852 143.637C34.3381 130.34 -57.2231 47.9473 -71.9998 -56L-5.40369 -56C8.36086 15.2209 70.7738 71.384 149.92 83.7096H149.852Z" fill="currentColor" />
		</g>
	</svg>
);

export default DeconstructedLogoLeft;
