import React from 'react';

const Scatterplot = (props) => (
	<svg {...props} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M13.714 12.0005H1.42829V0.85767C1.42829 0.779099 1.36401 0.714813 1.28544 0.714813H0.285435C0.206864 0.714813 0.142578 0.779099 0.142578 0.85767V13.1434C0.142578 13.222 0.206864 13.2862 0.285435 13.2862H13.714C13.7926 13.2862 13.8569 13.222 13.8569 13.1434V12.1434C13.8569 12.0648 13.7926 12.0005 13.714 12.0005ZM2.99972 8.64338C2.99972 8.94649 3.12013 9.23718 3.33446 9.45151C3.54878 9.66583 3.83947 9.78624 4.14258 9.78624C4.44568 9.78624 4.73637 9.66583 4.9507 9.45151C5.16503 9.23718 5.28544 8.94649 5.28544 8.64338C5.28544 8.34028 5.16503 8.04959 4.9507 7.83526C4.73637 7.62094 4.44568 7.50053 4.14258 7.50053C3.83947 7.50053 3.54878 7.62094 3.33446 7.83526C3.12013 8.04959 2.99972 8.34028 2.99972 8.64338ZM5.10686 4.64338C5.10686 4.87071 5.19717 5.08873 5.35792 5.24948C5.51866 5.41022 5.73668 5.50053 5.96401 5.50053C6.19134 5.50053 6.40935 5.41022 6.5701 5.24948C6.73085 5.08873 6.82115 4.87071 6.82115 4.64338C6.82115 4.41606 6.73085 4.19804 6.5701 4.03729C6.40935 3.87655 6.19134 3.78624 5.96401 3.78624C5.73668 3.78624 5.51866 3.87655 5.35792 4.03729C5.19717 4.19804 5.10686 4.41606 5.10686 4.64338ZM7.92829 8.71481C7.92829 9.16947 8.10891 9.60551 8.4304 9.927C8.75189 10.2485 9.18792 10.4291 9.64258 10.4291C10.0972 10.4291 10.5333 10.2485 10.8548 9.927C11.1763 9.60551 11.3569 9.16947 11.3569 8.71481C11.3569 8.26016 11.1763 7.82412 10.8548 7.50263C10.5333 7.18114 10.0972 7.00053 9.64258 7.00053C9.18792 7.00053 8.75189 7.18114 8.4304 7.50263C8.10891 7.82412 7.92829 8.26016 7.92829 8.71481ZM10.5712 3.10767C10.5712 3.37289 10.6765 3.62724 10.864 3.81478C11.0516 4.00231 11.3059 4.10767 11.5712 4.10767C11.8364 4.10767 12.0907 4.00231 12.2783 3.81478C12.4658 3.62724 12.5712 3.37289 12.5712 3.10767C12.5712 2.84245 12.4658 2.5881 12.2783 2.40056C12.0907 2.21303 11.8364 2.10767 11.5712 2.10767C11.3059 2.10767 11.0516 2.21303 10.864 2.40056C10.6765 2.5881 10.5712 2.84245 10.5712 3.10767Z" fill="currentColor"/>
	</svg>
);

export default Scatterplot;

