import React from 'react';

const CheckBubble = (props) => (
	<svg {...props} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.41153 0.0563173C1.44535 0.270144 0.586078 1.0225 0.22178 1.97284C-0.00788657 2.57473 3.29637e-05 2.40842 3.29637e-05 6.76812V10.7873L0.0871478 11.1239C0.328693 12.0386 0.898899 12.7711 1.69085 13.1829C2.20562 13.4522 2.47093 13.4997 3.46879 13.4997H4.33202L5.13189 14.4501C5.56746 14.9727 5.93968 15.4004 5.95552 15.3964C5.9674 15.3964 6.33566 14.9688 6.76727 14.4461L7.55526 13.4997H10.9646C14.77 13.4997 14.6195 13.5076 15.2134 13.2225C16.0569 12.8147 16.639 12.0821 16.8963 11.112C16.9835 10.7912 16.9835 10.7358 16.9953 7.0453C17.0072 4.64568 16.9953 3.16077 16.9716 2.89943C16.8251 1.46203 15.8589 0.329541 14.5442 0.060277C14.271 0.0048399 13.5899 -0.00307941 8.44621 0.000880241C3.74597 0.0048399 2.60556 0.0167198 2.41153 0.0563173ZM14.2314 1.34324C14.8689 1.46203 15.4194 2.00452 15.6015 2.69352C15.6926 3.02218 15.6886 10.4863 15.6015 10.8269C15.4629 11.3535 15.1145 11.7891 14.6393 12.0227L14.3383 12.1732L10.628 12.1851L6.91378 12.193L6.43465 12.7672C6.16935 13.0839 5.93968 13.3334 5.92384 13.3255C5.91196 13.3136 5.69417 13.0602 5.44471 12.7553L4.98934 12.2009L3.82121 12.1851L2.65307 12.1732L2.38381 12.0465C1.90468 11.8168 1.5483 11.3931 1.38991 10.8506C1.33447 10.6606 1.32655 10.1894 1.32655 6.74436C1.32655 2.97862 1.33051 2.84795 1.40575 2.60245C1.59978 1.96888 2.13039 1.46203 2.71247 1.3472C2.9659 1.29968 13.974 1.29176 14.2314 1.34324Z" fill="currentColor"/>
		<path d="M9.56682 6.0791C8.31554 7.30663 7.27808 8.30845 7.2662 8.30449C7.25036 8.29657 6.83458 7.83724 6.33961 7.28287C5.84068 6.7285 5.42095 6.26917 5.40511 6.26125C5.37343 6.24937 4.43497 7.08092 4.43497 7.12448C4.43497 7.14032 5.02101 7.80556 5.73377 8.60543C6.45049 9.4053 7.07613 10.1062 7.12365 10.1616L7.21472 10.2646L9.68561 7.83724C11.0438 6.5028 12.2991 5.27131 12.4733 5.10104L12.7901 4.78822L12.3149 4.31701L11.8397 3.84184L9.56682 6.0791Z" fill="currentColor"/>
	</svg>
);

export default CheckBubble;
