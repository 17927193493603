import React from 'react';

const Logo = (props) => (
	<svg {...props} viewBox="0 0 1257.88 310.05">
		<path strokeWidth={0} fill="#0d1d26" d="M652.18,205.87V36.51h-37.59v171.77c0,34.37,27.86,62.23,62.23,62.23h16.57v-37.59h-14.16c-14.94,0-27.05-12.11-27.05-27.05Z"/>
		<path strokeWidth={0} fill="#0d1d26" d="M1220.29,36.51v68.24c-15.65-11.72-35.08-18.67-56.14-18.67-51.77,0-93.73,41.96-93.73,93.73s41.96,93.73,93.73,93.73,93.73-41.96,93.73-93.73V36.51s-37.59,0-37.59,0ZM1164.15,235.96c-31.01,0-56.15-25.14-56.15-56.14s25.14-56.15,56.15-56.15,56.04,25.04,56.14,55.97v.18h0c0,31.01-25.14,56.14-56.15,56.14Z"/>
		<path strokeWidth={0} fill="#0d1d26" d="M797.89,86.08c-51.77,0-93.73,41.96-93.73,93.73s41.96,93.73,93.73,93.73,93.73-41.96,93.73-93.73-41.96-93.73-93.73-93.73ZM797.89,235.96c-31.01,0-56.15-25.14-56.15-56.14s25.14-56.15,56.15-56.15,56.15,25.14,56.15,56.15-25.14,56.14-56.15,56.14Z"/>
		<path strokeWidth={0} fill="#0d1d26" d="M518.44,123.67c17.61,0,33.31,8.11,43.61,20.79l26.65-26.65c-17.17-19.44-42.28-31.72-70.26-31.72-51.77,0-93.73,41.96-93.73,93.73s41.96,93.73,93.73,93.73c27.98,0,53.08-12.28,70.26-31.72l-26.65-26.65c-10.29,12.68-26,20.79-43.61,20.79-31.01,0-56.15-25.14-56.15-56.15s25.14-56.15,56.15-56.15Z"/>
		<path strokeWidth={0} fill="#0d1d26" d="M1054.08,123.29c3.4-3.95,5.47-9.09,5.47-14.71,0-12.47-10.11-22.58-22.58-22.58h-127.36v37.59h90.15c1.02,0,1.57,1.2.9,1.97,0,0-92.58,107.61-92.62,107.66-3.4,3.95-5.47,9.09-5.47,14.71,0,12.47,10.11,22.58,22.58,22.58.02,0,.04,0,.06,0h0s127.3,0,127.3,0v-37.59h-90.01c-1.02,0-1.57-1.19-.9-1.97,0,0,92.44-107.61,92.48-107.66Z"/>
		<path strokeWidth={0} fill="#09d2c6" d="M176.66,82.44c12.78-24.69,20.45-50.46,24.27-71.77.37-2.09-.89-4.1-2.92-4.69-25.69-7.54-55.38-7.95-81.51-1.23-3.29.85-4.17,5.08-1.52,7.2,21.48,17.13,41.68,42.88,54.3,70.36,1.43,3.11,5.81,3.16,7.38.13Z"/>
		<path strokeWidth={0} fill="#09d2c6" d="M133.39,227.62c-12.78,24.69-20.45,50.46-24.27,71.77-.37,2.09.89,4.1,2.92,4.69,25.69,7.54,55.38,7.95,81.51,1.23,3.29-.85,4.17-5.08,1.52-7.2-21.48-17.13-41.68-42.88-54.3-70.36-1.43-3.11-5.81-3.16-7.38-.13Z"/>
		<path strokeWidth={0} fill="#1e62e5" d="M82.44,133.39c-24.69-12.78-50.46-20.45-71.77-24.27-2.09-.37-4.1.89-4.69,2.92-7.54,25.69-7.95,55.38-1.23,81.51.85,3.29,5.08,4.17,7.2,1.52,17.13-21.48,42.88-41.68,70.36-54.3,3.11-1.43,3.16-5.81.13-7.38Z"/>
		<path strokeWidth={0} fill="#1e62e5" d="M227.62,176.66c24.69,12.78,50.46,20.45,71.77,24.27,2.09.37,4.1-.89,4.69-2.92,7.54-25.69,7.95-55.38,1.23-81.51-.85-3.29-5.08-4.17-7.2-1.52-17.13,21.48-42.88,41.68-70.36,54.3-3.11,1.43-3.16,5.81-.13,7.38Z"/>
		<path strokeWidth={0} fill="#14a4f6" d="M119,88.4c-8.43-26.49-21.22-50.14-33.59-67.91-1.21-1.74-3.53-2.27-5.38-1.25-23.5,12.84-44.78,33.54-58.5,56.77-1.73,2.92.64,6.54,4.02,6.16,27.3-3.07,59.8.85,88.15,11.36,3.21,1.19,6.34-1.87,5.31-5.13Z"/>
		<path strokeWidth={0} fill="#14a4f6" d="M191.06,221.65c8.43,26.49,21.22,50.14,33.59,67.91,1.21,1.74,3.53,2.27,5.38,1.25,23.5-12.84,44.78-33.54,58.5-56.77,1.73-2.92-.64-6.54-4.02-6.16-27.3,3.07-59.8-.85-88.15-11.36-3.21-1.19-6.34,1.87-5.31,5.13Z"/>
		<path strokeWidth={0} fill="#14a4f6" d="M88.4,191.06c-26.49,8.43-50.14,21.22-67.91,33.59-1.74,1.21-2.27,3.53-1.25,5.38,12.84,23.5,33.54,44.78,56.77,58.5,2.92,1.73,6.54-.64,6.16-4.02-3.07-27.3.85-59.8,11.36-88.15,1.19-3.21-1.87-6.34-5.13-5.31Z"/>
		<path strokeWidth={0} fill="#14a4f6" d="M221.65,119c26.49-8.43,50.14-21.22,67.91-33.59,1.74-1.21,2.27-3.53,1.25-5.38-12.84-23.5-33.54-44.78-56.77-58.5-2.92-1.73-6.54.64-6.16,4.02,3.07,27.3-.85,59.8-11.36,88.15-1.19,3.21,1.87,6.34,5.13,5.31Z"/>
	</svg>
);

export default Logo;
