import { types, getParent } from 'mobx-state-tree';
const { model, string, number, boolean, optional, maybeNull, array, compose, enumeration } = types;

const TagBaseModel = model('TagBaseModel', {
	tag_id: optional(string, ''),
	name: optional(string, ''),
	is_system_tag: optional(boolean, false),
	tag_type: maybeNull(enumeration('tag_type', ['manual', 'category', 'auto'])),
	autotag_keywords: maybeNull(array(string)),
	competitor_id: maybeNull(string),
	created_at: optional(string, ''),
	updated_at: optional(string, ''),
	deleted_at: maybeNull(string),
});

export const TagModel = compose(
	TagBaseModel,
	model('TagBaseModel', {
		parent_id: maybeNull(string),
		tag_count: optional(number, 0),
		deal_count: optional(number, 0),
		subscriber_count: optional(number, 0),
	})
		.views(self => ({
			get parent() { // This is the tag category to which the content tag belongs
				const programStore = getParent(self, 2);
				const parent = programStore?.contentTagCategories?.get?.(self.parent_id);
				return parent || {};
			},
		})),
);

export const CategoryModel = compose(
	TagBaseModel,
	model('CategoryModel', {
		color: optional(string, ''),
		tags: array(TagModel),
	})
		.views(self => ({
			get tag_count() {
				return self.tags.reduce((sum, tag) => sum + tag.tag_count, 0);
			},
			get deal_count() {
				return self.tags.reduce((sum, tag) => sum + tag.deal_count, 0);
			},
			get subscriber_count() {
				return self.tags.reduce((sum, tag) => sum + tag.subscriber_count, 0);
			},
		}))
		.volatile(() => ({
			sortField: null,
			sortOrder: null,
		}))
		.actions(self => ({
			handleSingleTagSort(sortField, sortOrder) {
				self.sortField = sortField;
				self.sortOrder = sortOrder;
			},
		})),
);
