import React from 'react';

const CircleClose = (props) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
		<path d="M8 1C4.13437 1 1 4.13437 1 8C1 11.8656 4.13437 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13437 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125Z" fill="currentColor"/>
		<path fillRule="evenodd" clipRule="evenodd" d="M8.762 7.99987L10.5366 6.22527L9.77472 5.46338L8.00011 7.23798L6.22551 5.46338L5.46362 6.22527L7.23823 7.99987L5.46362 9.77447L6.22551 10.5364L8.00011 8.76176L9.77472 10.5364L10.5366 9.77447L8.762 7.99987ZM6.22551 5.77765L8.00011 7.55225L9.77472 5.77765L10.2223 6.22527L10.2223 6.22528L9.77471 5.77767L8.00011 7.55227L6.22551 5.77767L5.7779 6.22527L5.77789 6.22527L6.22551 5.77765ZM7.55248 7.99988L5.77789 9.77447L5.7779 9.77448L7.55249 7.99989L7.55248 7.99988ZM10.2223 9.77449L10.2223 9.77447L8.44774 7.99988L8.44773 7.99989L10.2223 9.77449Z" fill="currentColor"/>
	</svg>
);

export default CircleClose;
