import React from 'react';

const DotsPartialCircle = (props) => (
	<svg {...props} viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3.65439 4.07954C2.64231 5.35898 2.26411 6.94941 2.26411 8.12395C2.26411 8.54584 1.9221 8.88784 1.50022 8.88784C1.07833 8.88784 0.736328 8.54584 0.736328 8.12395C0.736328 6.67438 1.19146 4.73053 2.45617 3.13172C3.74584 1.50135 5.83077 0.291504 8.94069 0.291504C12.0431 0.291504 14.1512 1.47115 15.471 3.04541C16.7672 4.59144 17.2641 6.47005 17.2641 7.85208C17.2641 8.85516 17.128 10.5552 16.0412 12.0129C14.934 13.4978 12.9628 14.575 9.63869 14.5967C9.61132 14.5987 9.58367 14.5998 9.55577 14.5998H6.778C6.16435 14.5998 5.66688 14.1023 5.66688 13.4887C5.66688 12.875 6.16435 12.3776 6.778 12.3776H9.55577C10.0056 12.3776 10.393 12.6449 10.5678 13.0294C12.9243 12.8386 14.1509 11.9922 14.8164 11.0997C15.6045 10.0426 15.7363 8.75208 15.7363 7.85208C15.7363 6.78731 15.3404 5.26757 14.3003 4.02696C13.2838 2.81458 11.6121 1.81928 8.94069 1.81928C6.27681 1.81928 4.6415 2.83166 3.65439 4.07954Z" fill="currentColor" />
		<path d="M6.16551 7.44428C6.16551 8.05793 5.66805 8.55539 5.0544 8.55539C4.44075 8.55539 3.94328 8.05793 3.94328 7.44428C3.94328 6.83063 4.44075 6.33317 5.0544 6.33317C5.66805 6.33317 6.16551 6.83063 6.16551 7.44428Z" fill="currentColor" />
		<path d="M10.0544 7.44428C10.0544 8.05793 9.55694 8.55539 8.94328 8.55539C8.32964 8.55539 7.83217 8.05793 7.83217 7.44428C7.83217 6.83063 8.32964 6.33317 8.94328 6.33317C9.55694 6.33317 10.0544 6.83063 10.0544 7.44428Z" fill="currentColor" />
		<path d="M12.8322 8.55539C13.4458 8.55539 13.9433 8.05793 13.9433 7.44428C13.9433 6.83063 13.4458 6.33317 12.8322 6.33317C12.2185 6.33317 11.7211 6.83063 11.7211 7.44428C11.7211 8.05793 12.2185 8.55539 12.8322 8.55539Z" fill="currentColor" />
	</svg>
);

export default DotsPartialCircle;