import React from 'react';

const Flag = (props) => (
	<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15.1875 3.95703H10.1875V1.75C10.1875 1.4043 9.9082 1.125 9.5625 1.125H1.59375V0.34375C1.59375 0.257812 1.52344 0.1875 1.4375 0.1875H0.34375C0.257812 0.1875 0.1875 0.257812 0.1875 0.34375V15.6562C0.1875 15.7422 0.257812 15.8125 0.34375 15.8125H1.4375C1.52344 15.8125 1.59375 15.7422 1.59375 15.6562V10.5H6.4375V12.707C6.4375 13.0527 6.7168 13.332 7.0625 13.332H15.1875C15.5332 13.332 15.8125 13.0527 15.8125 12.707V4.58203C15.8125 4.23633 15.5332 3.95703 15.1875 3.95703ZM1.59375 9.09375V2.53125H8.78125V9.09375H1.59375ZM14.4062 11.9258H7.84375V10.5H10.0312C10.1172 10.5 10.1875 10.4297 10.1875 10.3438V5.36328H14.4062V11.9258Z"
			fill="currentColor"
		/>
	</svg>
);

export default Flag;
