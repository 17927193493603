import React from 'react';

const ExclamationCircle = (props) => (
	<svg {...props} viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1698_12905)">
			<path d="M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z" fill="currentColor" />
			<path d="M9.82106 15.3214C9.82106 15.634 9.94523 15.9338 10.1663 16.1548C10.3873 16.3758 10.6871 16.5 10.9996 16.5C11.3122 16.5 11.612 16.3758 11.833 16.1548C12.054 15.9338 12.1782 15.634 12.1782 15.3214C12.1782 15.0089 12.054 14.7091 11.833 14.4881C11.612 14.267 11.3122 14.1429 10.9996 14.1429C10.6871 14.1429 10.3873 14.267 10.1663 14.4881C9.94523 14.7091 9.82106 15.0089 9.82106 15.3214V15.3214ZM10.4103 12.5714H11.5889C11.697 12.5714 11.7853 12.483 11.7853 12.375V5.69643C11.7853 5.58839 11.697 5.5 11.5889 5.5H10.4103C10.3023 5.5 10.2139 5.58839 10.2139 5.69643V12.375C10.2139 12.483 10.3023 12.5714 10.4103 12.5714Z" fill="currentColor" />
		</g>
	</svg>
);

export default ExclamationCircle;

