import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Card from '@reusableComponents/Card/card';
import RollbarErrorTracking from './rollbarErrorTracking';
import styles from './errorBoundary.scss';

const cx = classNames.bind(styles);

// React current doesn't allow for errorBoundary components to be functional components
// Error boundaries also cannot catch compile errors so it will still show a white screen.
// It also can't catch event handlers, asynchronous code, and server side rendering - it's not a wizard unfortunately
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className={cx('container')}>
					<Card className={cx('message-container')}>
						<div className={cx('oops')}>{this.props.t('errorBoundary.oops')}</div>
						<div className={cx('message')}>{this.props.t('errorBoundary.message')}</div>
						<div className={cx('go-back')} onClick={() => this.setState({ hasError: false })}>
							{this.props.t('errorBoundary.goBack')}
						</div>
					</Card>
				</div>
			);
		}

		return this.props.children;
	}

	componentDidCatch(error) {
		RollbarErrorTracking.logErrorInfo(error);
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node,
	t: PropTypes.func,
	location: PropTypes.object,
};

// withRouter allows us to access the previous location path
export default withTranslation()(ErrorBoundary);
