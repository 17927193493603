import React from 'react';

const CloudUpload = (props) => (
	<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_3458_165724)">
			<path d="M20.2818 17.637C20.2484 17.5943 20.2057 17.5598 20.157 17.5361C20.1083 17.5123 20.0548 17.5 20.0006 17.5C19.9464 17.5 19.8929 17.5123 19.8441 17.5361C19.7954 17.5598 19.7527 17.5943 19.7193 17.637L14.7193 23.9629C14.6781 24.0156 14.6525 24.0787 14.6455 24.1452C14.6385 24.2117 14.6504 24.2788 14.6797 24.3389C14.709 24.399 14.7547 24.4496 14.8114 24.4849C14.8682 24.5203 14.9337 24.539 15.0006 24.5388H18.2997V35.3602C18.2997 35.5567 18.4604 35.7174 18.6568 35.7174H21.3354C21.5318 35.7174 21.6925 35.5567 21.6925 35.3602V24.5433H25.0006C25.2997 24.5433 25.4649 24.1995 25.2818 23.9674L20.2818 17.637Z" fill="currentColor" />
			<path d="M33.3661 13.5053C31.3214 8.11241 26.1116 4.27759 20.0089 4.27759C13.9063 4.27759 8.69643 8.10795 6.65179 13.5008C2.82589 14.5053 0 17.9919 0 22.1347C0 27.0678 3.99554 31.0633 8.92411 31.0633H10.7143C10.9107 31.0633 11.0714 30.9026 11.0714 30.7062V28.0276C11.0714 27.8312 10.9107 27.6704 10.7143 27.6704H8.92411C7.41964 27.6704 6.00446 27.0722 4.95089 25.9874C3.90179 24.9071 3.34375 23.4517 3.39286 21.9428C3.43304 20.7642 3.83482 19.6571 4.5625 18.724C5.30804 17.7731 6.35268 17.0812 7.51339 16.7731L9.20536 16.3312L9.82589 14.6972C10.2098 13.6794 10.7455 12.7285 11.4196 11.8669C12.0851 11.0129 12.8735 10.2622 13.7589 9.6392C15.5938 8.34902 17.7545 7.66598 20.0089 7.66598C22.2634 7.66598 24.4241 8.34902 26.2589 9.6392C27.1473 10.2642 27.933 11.0142 28.5982 11.8669C29.2723 12.7285 29.808 13.6838 30.192 14.6972L30.808 16.3267L32.4955 16.7731C34.9152 17.4249 36.6071 19.6258 36.6071 22.1347C36.6071 23.6124 36.0312 25.0053 34.9866 26.0499C34.4743 26.5652 33.8649 26.9738 33.1936 27.2519C32.5223 27.5301 31.8025 27.6724 31.0759 27.6704H29.2857C29.0893 27.6704 28.9286 27.8312 28.9286 28.0276V30.7062C28.9286 30.9026 29.0893 31.0633 29.2857 31.0633H31.0759C36.0045 31.0633 40 27.0678 40 22.1347C40 17.9963 37.183 14.5142 33.3661 13.5053Z" fill="currentColor" />
		</g>
		<defs>
			<clipPath id="clip0_3458_165724">
				<rect width="40" height="40" fill="currentColor" />
			</clipPath>
		</defs>
	</svg>
);

export default CloudUpload;
