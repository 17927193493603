// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary1": `#e6f7ff`,
	"primary2": `#b9e9ff`,
	"primary3": `#7dc9ff`,
	"primary4": `#14a6f8`,
	"primary5": `#0f81f7`,
	"primary6": `#274fdb`,
	"primary7": `#0b2a9a`,
	"primary8": `#000d51`,
	"blue1": `#e6f7ff`,
	"blue2": `#b9e9ff`,
	"blue3": `#7dc9ff`,
	"blue4": `#14a6f8`,
	"blue5": `#0f81f7`,
	"blue6": `#274fdb`,
	"blue7": `#0b2a9a`,
	"blue8": `#000d51`,
	"neutralWhite": `#fff`,
	"neutralBlack": `#000`,
	"divider": `#dde6e9`,
	"grey1": `#f8f9fa`,
	"grey2": `#f4f5f6`,
	"grey3": `#f0f1f2`,
	"grey4": `#e1e3e5`,
	"grey5": `#d8dade`,
	"grey6": `#c0c3c8`,
	"grey7": `#acb0b6`,
	"grey8": `#90959b`,
	"grey9": `#798087`,
	"grey10": `#565f66`,
	"grey11": `#363f44`,
	"grey12": `#1f2528`,
	"grey13": `#101516`,
	"pink1": `#feecfa`,
	"pink2": `#fed0f4`,
	"pink3": `#fd9fe8`,
	"pink4": `#e55ec8`,
	"pink5": `#c50099`,
	"pink6": `#95007d`,
	"pink7": `#710066`,
	"pink8": `#4d034f`,
	"violet1": `#ebe7ff`,
	"violet2": `#dad1ff`,
	"violet3": `#baa2ff`,
	"violet4": `#865cff`,
	"violet5": `#7031f5`,
	"violet6": `#471fba`,
	"violet7": `#2e1280`,
	"violet8": `#170047`,
	"teal1": `#e6fdf9`,
	"teal2": `#b4fff3`,
	"teal3": `#5deedf`,
	"teal4": `#09d4cb`,
	"teal5": `#0ab2ac`,
	"teal6": `#007e88`,
	"teal7": `#005b6f`,
	"teal8": `#00323d`,
	"green1": `#ddffe9`,
	"green2": `#b8ffd0`,
	"green3": `#73e099`,
	"green4": `#00c371`,
	"green5": `#00aa63`,
	"green6": `#038251`,
	"green7": `#04624b`,
	"green8": `#003f30`,
	"yellow1": `#fff7df`,
	"yellow2": `#ffefbc`,
	"yellow3": `#fee28c`,
	"yellow4": `#f8c220`,
	"yellow5": `#cf9500`,
	"yellow6": `#996504`,
	"yellow7": `#6a4600`,
	"yellow8": `#3f2900`,
	"orange1": `#ffe9d6`,
	"orange2": `#ffcc9d`,
	"orange3": `#fead62`,
	"orange4": `#eb7319`,
	"orange5": `#c94c00`,
	"orange6": `#963f00`,
	"orange7": `#692c00`,
	"orange8": `#441d00`,
	"red1": `#ffeae5`,
	"red2": `#ffb9aa`,
	"red3": `#ff8e75`,
	"red4": `#f14035`,
	"red5": `#d90a0a`,
	"red6": `#af0015`,
	"red7": `#72000d`,
	"red8": `#45040c`
};
export default ___CSS_LOADER_EXPORT___;
