import { types } from 'mobx-state-tree';
const {
	model,
	maybeNull,
	number,
} = types;

const DealAmount = model('DealAmountStore', {
	deals: maybeNull(number),
	amount: maybeNull(number),
});

export default DealAmount;
