import React from 'react';

const Thumb = (props) => (
	<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M2.85717 15.4283C2.85722 15.5034 2.84246 15.5778 2.81375 15.6471C2.78505 15.7165 2.74294 15.7795 2.68985 15.8326C2.63676 15.8857 2.57373 15.9278 2.50435 15.9566C2.43497 15.9853 2.36061 16 2.28553 16H0.571452C0.496377 16 0.422034 15.9853 0.352673 15.9565C0.283311 15.9278 0.220293 15.8857 0.16722 15.8326C0.114147 15.7795 0.0720613 15.7165 0.0433685 15.6471C0.0146756 15.5777 -6.14486e-05 15.5034 1.92574e-07 15.4283V6.28572C-4.91896e-05 6.21069 0.0147002 6.13638 0.0434043 6.06705C0.0721084 5.99772 0.114204 5.93473 0.167282 5.88169C0.220359 5.82864 0.283378 5.78658 0.35273 5.75792C0.422082 5.72925 0.496407 5.71454 0.571452 5.71463H2.28553C2.36058 5.71453 2.43492 5.72923 2.50429 5.75789C2.57366 5.78655 2.6367 5.82861 2.68979 5.88165C2.74289 5.9347 2.785 5.99769 2.81372 6.06702C2.84244 6.13636 2.8572 6.21068 2.85717 6.28572V15.4283ZM15.9874 7.16296C15.7491 12.9058 13.8941 15.501 13.8941 15.501C13.7212 15.8122 13.4431 15.9999 12.854 15.9999H9.73082C8.16092 15.9999 6.60444 15.6437 6.56438 15.6349C4.1903 15.0881 4.06536 15.0458 3.9164 15.0032L4.00007 6.60752C4.00007 5.73892 4.01967 5.95839 4.00007 5.96918C6.61063 4.88832 7.40533 2.51765 7.42878 0.571378C7.43206 0.298164 7.64303 0 8.00033 0C8.60461 0 9.67191 1.21282 9.67191 2.71311C9.67191 4.06802 9.61816 4.30231 9.14314 5.71453C14.8573 5.71453 14.8171 5.79604 15.3216 5.92857C15.9468 6.10677 16 6.62488 16 6.80345C15.9999 6.9992 15.9946 6.97097 15.9874 7.16296Z" fill="currentColor"/>
	</svg>
);

export default Thumb;

