import React from 'react';

const SideBySide = (props) => (
	<svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.5 0.5H8V11.5H0.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M15.5 15.5L8 15.5L8 4.5L15.5 4.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M5.70717 5.76574C5.8573 5.88584 5.8573 6.11416 5.70717 6.23426L1.69401 9.44479C1.46978 9.62418 1.14591 9.41635 1.21556 9.13777L1.98181 6.07276C1.99375 6.02499 1.99375 5.97501 1.98181 5.92724L1.21556 2.86223C1.14591 2.58365 1.46978 2.37582 1.69401 2.55521L5.70717 5.76574Z" fill="currentColor"/>
		<path d="M10.2928 10.2343C10.1427 10.1142 10.1427 9.88584 10.2928 9.76574L14.306 6.55521C14.5302 6.37582 14.8541 6.58365 14.7844 6.86223L14.0182 9.92724C14.0062 9.97501 14.0062 10.025 14.0182 10.0728L14.7844 13.1378C14.8541 13.4164 14.5302 13.6242 14.306 13.4448L10.2928 10.2343Z" fill="currentColor"/>
	</svg>
);

export default SideBySide;
