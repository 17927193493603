// for a guide, see here: https://react.i18next.com/legacy-v9/step-by-step-guide

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import detector from 'i18next-browser-languagedetector';

import translationEN from './en/translation.json';
// import translationES from '../i18n/es/translation.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		translation: translationEN,
	},
	// es: {
	//	 translation: translationES,
	// },
};

i18n
	// .use(detector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'en',
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
